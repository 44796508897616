import { useEffect, useState } from "react";
import Dropdowns from "../Dropdowns";
import TextInput from "../TextInput";
import { AUDITED_OPTIONS } from "../../constants";
import { LANGUAGE_OPTIONS } from "../../constants/options";
import { API_GET_FOREX_RATE } from "../../constants/url";
import useAuthenticatedFetch from "../../hooks/useAuthenticatedFetch";

const FIELDS = {
  group_name: "group_name",
  company_name: "company_name",
  // audited: "audited",
  doc_currency: "doc_currency",
  out_currency: "out_currency",
  exchange_rate: "exchange_rate",
  language: "language",
};

const REQUIRED_FIELDS = Object.values(FIELDS);

export default function FinancialSpreadingForm({
  currencyOptions = [],
  onFormChange,
  form,
  initialValue,
  companies,
  groups,
  isSubmit,
  onFormValidChange,
  hasSearchedGroupName,
  onFormCompanyExists
}) {
  const handleChange = (key, value) => {
    const updatedFormData = {
      ...form,
      [key]: value,
    };

    onFormChange?.(updatedFormData);
  };

  const isCompanyExists = companies.includes(form[FIELDS.company_name]);

  const isFormInvalid =
    REQUIRED_FIELDS.some((fieldName) => !form[fieldName]) ||
    (!initialValue && isCompanyExists);
  
  useEffect(() => {
    onFormValidChange?.(!isFormInvalid);
  }, [isFormInvalid]);
  
  useEffect(() => {
    onFormCompanyExists?.(isCompanyExists);
  }, [isCompanyExists]);
  

  const handleInputChange = (e) => {
    handleChange(e.target.name, e.target.value);
  };

  const handleDropdownChange = (value, name) => {
    handleChange(name, value);
  };

  const isSameCurrency =
    form[FIELDS.doc_currency] &&
    form[FIELDS.out_currency] &&
    form[FIELDS.doc_currency] === form[FIELDS.out_currency];

  const [isLoading, setIsLoading] = useState(false);
  const authenticatedFetch = useAuthenticatedFetch()

  const fetchExchangeRate = async () => {
    if (isSameCurrency) {
      handleChange(FIELDS.exchange_rate, 1);
      return;
    }
  
    if (form[FIELDS.doc_currency] && form[FIELDS.out_currency]) {
      const exchangeRateApi = API_GET_FOREX_RATE
        .replace(":base", form[FIELDS.out_currency])
        .replace(":target", form[FIELDS.doc_currency]);
  
      setIsLoading(true);
  
      try {
        const response = await authenticatedFetch(exchangeRateApi);
        const price = response?.data?.price;
  
        if (price !== undefined) {
          handleChange(FIELDS.exchange_rate, price);
        } else {
          console.warn("Exchange rate not found in response", response.data);
          handleChange(FIELDS.exchange_rate, "");
        }
        
      } catch (err) {
        if (!err.message || ["AbortError", "CanceledError"].includes(err.name)) {
          return;
        }
        console.error("Failed to fetch exchange rate", err);
        handleChange(FIELDS.exchange_rate, "");
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchExchangeRate();
  }, [form[FIELDS.doc_currency], form[FIELDS.out_currency]]);


  const handleMouseOverCompanyName = () => {
    const requiredTooltip = document.querySelector("#requiredTooltip");
    if (!requiredTooltip || form[FIELDS.group_name]) {
      return;
    }
    requiredTooltip.style.display = "flex";
  };
  

  const handleMouseLeave = () => {
    const requiredTooltip = document.querySelector("#requiredTooltip");

    if (!requiredTooltip) {
      return;
    }

    requiredTooltip.style.display = "none";
  };

  return (
    <div className="max-w-[1186px] w-full flex flex-col gap-[2rem]">
      <div className="flex items-center justify-between gap-[1.5rem] p-[2rem] bg-white rounded-[20px]">
        <Dropdowns
          id={FIELDS.group_name}
          label="Group Name"
          placeholder="Enter Group Name"
          data={groups}
          className="flex-1"
          required
          onChange={handleDropdownChange}
          defaultValue={form[FIELDS.group_name]}
          allowCustomText
          disabled={initialValue?.[FIELDS.group_name]}
          value={form[FIELDS.group_name]}
          isSubmit={isSubmit}
          showOptionsList={hasSearchedGroupName}
          hideNotFound
        />
       
          <div
             onMouseOver={handleMouseOverCompanyName}
             onMouseLeave={handleMouseLeave}
             className="flex-1 relative"
          >
            <TextInput
              id={FIELDS.company_name}
              label="Company Name"
              placeholder="Enter Company Name"
              className="flex-1"
              required
              value={form[FIELDS.company_name]}
              onChange={handleInputChange}
              referralOptions={companies.filter((name) =>
                name
                  .toLowerCase()
                  .includes(form[FIELDS.company_name]?.toLowerCase())
              )}
              disabled={
                initialValue?.[FIELDS.company_name] || !form[FIELDS.group_name]
              }
              isSubmit={isSubmit}
            />

            {!form[FIELDS.group_name] && (
              <div
                id="requiredTooltip"
                className="absolute hidden z-50 right-0 -top-[0rem] max-w-[200px] whitespace-nowrap px-2 justify-center items-center rounded-[6px]"
              >
                <span className="text-[#1E478A] text-[0.75rem] font-[500] leading-[1.8rem] tracking-[-0.28px]">
                  Please fill the Group Name first
                </span>
              </div>
            )}
          </div>
            <Dropdowns
            id={FIELDS.language}
            label="Document Language"
            placeholder="Select Document Language"
            data={LANGUAGE_OPTIONS}
            className="flex-1"
            required
            onChange={handleDropdownChange}
            defaultValue={form[FIELDS.language]}
            value={form[FIELDS.language]}
            isSubmit={isSubmit}
          />
      </div>
      <div className="flex items-center justify-between gap-[1.5rem] p-[2rem] bg-white rounded-[20px]">
        <Dropdowns
          id={FIELDS.doc_currency}
          label="Document Currency"
          placeholder="Select Document Currency"
          data={currencyOptions}
          className="flex-1"
          required
          onChange={handleDropdownChange}
          defaultValue={form[FIELDS.doc_currency]}
          value={form[FIELDS.doc_currency]}
          isSubmit={isSubmit}
        />
        <Dropdowns
          id={FIELDS.out_currency}
          label="Output Currency"
          placeholder="Select Output Currency"
          data={currencyOptions}
          className="flex-1"
          required
          onChange={handleDropdownChange}
          defaultValue={form[FIELDS.out_currency]}
          value={form[FIELDS.out_currency]}
          isSubmit={isSubmit}
        />
        <TextInput
          id={FIELDS.exchange_rate}
          label={
            form[FIELDS.doc_currency] && form[FIELDS.out_currency]
                ? `Forex Rate (1 ${form[FIELDS.out_currency]} to ${form[FIELDS.doc_currency]})`
                : "Forex Rate"
        }    
          className="flex-1"
          placeholder={
              isLoading
                  ? "Loading exchange rate..."
                  : form[FIELDS.doc_currency] && form[FIELDS.out_currency]
                  ? `1 ${form[FIELDS.out_currency]} to ${form[FIELDS.doc_currency]}`
                  : ""
          }
          required
          value={isLoading ? "" : form[FIELDS.exchange_rate]}
          onChange={handleInputChange}
          isSubmit={isSubmit}
          disabled={
              isLoading
          //     !form[FIELDS.doc_currency] || 
          //     !form[FIELDS.out_currency]
          }
      />
      </div>
    </div>
  );
}
