import Tooltip from "../../components/Tooltip";
import iconReadMore from "../../assets/icons/icon_read_more.svg";
import TableInput from "../../components/TableElements/Input";
import iconSave from "../../assets/icons/icon_table_save.svg";
import iconCancel from "../../assets/icons/icon_table_cancel.svg";
import { useEffect, useState } from "react";
import iconTrash from "../../assets/icons/icon_table_trash.svg";

export default function FinancialSpreadingValidateTableRowChildTableName({
  isEditing,
  colSpan,
  tableName,
  goToPage,
  onRowClick,
  onSave,
  onCancelEdit,
  onDeleteTable,
}) {
  const [formTableName, setFormTableName] = useState(tableName)


  
    useEffect(() => {
      setFormTableName((currentTableName) => {
        let usedTableName = tableName

        if (isEditing) {
          usedTableName = currentTableName
        }
  
        return usedTableName
      });
    }, [tableName, isEditing]);

  if (!isEditing) {
    return (
      <tr>
        <td
          colSpan={colSpan}
          className="pl-8 font-bold bg-gray-200 sticky left-0 !h-[35px] !py-0 !text-[0.8125rem] !leading-[1rem] xl:w-[120px] 2xl:w-[200px]"
          onClick={onRowClick}
        >
          {tableName}
        </td>
        <td className="bg-gray-200 sticky right-0 gap-[0.625rem] !h-[35px] !py-0 !px-[0.75rem] flex justify-center items-center">
          <Tooltip
            tooltip="Cycle Through Pages"
            topTooltip
            tooltipClassname="flex justify-center items-center max-w-[240px] p-1.5 px-2.5 py-1 bg-white bg-opacity-80 rounded !max-w-[240px] !w-[127px] !right-[48px] top-[-24px] !my-0 !h-[20px]"
          >
            <button onClick={goToPage}>
              <img
                src={iconReadMore}
                className="w-[20px] cursor-pointer"
                title="Cycle Through Pages"
                alt="Go to Page"
              />
            </button>
            
          </Tooltip>
          <Tooltip
            tooltip="Delete Table"
            topTooltip
            tooltipClassname="flex justify-center items-center max-w-[240px] p-1.5 px-2.5 py-1 bg-white bg-opacity-80 rounded !max-w-[240px] !w-[127px] !right-[48px] top-[-24px] !my-0 !h-[20px]"
          >
            <button onClick={onDeleteTable}>
            <img
                src={iconTrash}
                className="w-[20px] cursor-pointer"
                title="Delete Table"
                alt="Delete Table"
              />
            </button>
          </Tooltip>
        </td>
      </tr>
    );
  }

  return (
    <tr>
      <td
        colSpan={colSpan}
        className="px-8 font-bold bg-gray-200 sticky left-0 !h-[35px] p-0 px-[0.75rem] !text-[0.8125rem] !leading-[1rem] "
      >
        <TableInput
          // id={FIELDS.client_account_name}
          defaultValue={tableName}
          onChange={(e) => setFormTableName(e.target.value)}
          value={formTableName}
        />
      </td>
      <td className="bg-gray-200 sticky right-0 !h-[35px] !py-0 !px-[0.75rem] flex justify-center items-center">
        <div className="flex justify-center gap-[0.625rem] items-center">
          {" "}
          <button onClick={() => onSave(formTableName)}>
            <img
              src={iconSave}
              className="w-[20px] cursor-pointer"
              title="Save"
              alt="Save"
            />
          </button>
          <img
            onClick={() => {
              onCancelEdit()
              setFormTableName(tableName)
            }}
            src={iconCancel}
            className="w-[20px] cursor-pointer"
            alt="Cancel"
            title="Cancel"
          />
        </div>
      </td>
    </tr>
  );
}
