import { AUDITED_OPTIONS, UNAUDITED_VALUE } from "../../constants";
import { SORT_TYPES } from "../../constants/options";
import extractPeriodAndDate from "../string/extractPeriodAndDate";

export default function sortPeriodString(fiscalPeriodOrder, order = SORT_TYPES.ASC){
  let orderModifier = order === SORT_TYPES.ASC ? 1 : -1
  return fiscalPeriodOrder.sort ((firstPeriodString, secondPeriodString) => {
    const firstPeriodStringData = extractPeriodAndDate(firstPeriodString)
    const secondPeriodStringData = extractPeriodAndDate(secondPeriodString)

    if (firstPeriodStringData.period !== secondPeriodStringData.period) {
      return (firstPeriodStringData.period - secondPeriodStringData.period) * -1;
    }

    if (firstPeriodStringData.year !== secondPeriodStringData.year) {
      return (firstPeriodStringData.year - secondPeriodStringData.year) * orderModifier;
    }

    if (firstPeriodStringData.monthInt !== secondPeriodStringData.monthInt) {
      return (firstPeriodStringData.monthInt - secondPeriodStringData.monthInt) * orderModifier
    }

    if (firstPeriodStringData.audited !== secondPeriodStringData.audited) {
      if (firstPeriodStringData.audited === UNAUDITED_VALUE) {
        return 1
      }

      const firstPeriodStringOptionIndex = AUDITED_OPTIONS.findIndex(({ value }) => value === firstPeriodStringData.audited)
      const secondPeriodStringOptionIndex = AUDITED_OPTIONS.findIndex(({ value }) => value === secondPeriodStringData.audited)

      return firstPeriodStringOptionIndex - secondPeriodStringOptionIndex
    }

    return 0
  });
};
