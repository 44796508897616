import { Fragment, useEffect, useRef, useState } from "react";
import { ReactComponent as ArrowDropdownIcon } from "../../../assets/icons/arrow_drop_down.svg";
import { Popover } from "react-tiny-popover";

export default function TableSelect({
  data,
  className = "",
  inputClassName = "",
  placeholder = "",
  defaultValue = "",
  allowCustomText = false,
  onChange,
  readOnly = false,
  setIsOpenTableSelect,
  popoverClassname = "",
  dropdownContainerClassname = "",
  dropdownDataClassname = "",
  ...props
}) {
  const { id, name = id } = props;
  const [keyword, setKeyword] = useState("");
  const [open, setOpen] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (!setIsOpenTableSelect) return;
    setIsOpenTableSelect?.(open);
  }, [open]);

  const dropDownData = data.filter((item) =>
    item.label.toLowerCase().includes(keyword.toLowerCase())
  );

  const groupedDropdownData = dropDownData.reduce(
    (groupedDropdown, currentDropdown) => {
      const { groupName = "Others" } = currentDropdown;

      const currentGroupDropdown = groupedDropdown[groupName] || [];
      currentGroupDropdown.push(currentDropdown);

      return {
        ...groupedDropdown,
        [groupName]: currentGroupDropdown,
      };
    },
    {}
  );

  const groupNames = Object.keys(groupedDropdownData);


  let shownDefaultValue = defaultValue;

  if (!allowCustomText && typeof defaultValue !== "undefined" && defaultValue !== null) {
    const defaultData = dropDownData.find(
      ({ value }) => value.toString() === defaultValue.toString()
    );
    shownDefaultValue = defaultData?.label;
  }

  useEffect(() => {
      inputRef.current.value = shownDefaultValue ?? ''
  }, [shownDefaultValue])

  const shownFilteredData = dropDownData.filter(({ hideAsOption }) => !hideAsOption);

  return (
    <div
      className={`relative inline-block text-left ${className} `}
      onClick={(event) => {
        setOpen((prev) => !prev);
      }}
    >
      <Popover
        isOpen={open}
        positions={["left", "bottom", "top","right"]} // preferred positions by priority
        onClickOutside={() => setOpen(false)}
        content={
          <div
            className={`absolute z-50 mt-5 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none min-w-[230px] ${popoverClassname}`}
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
            tabIndex="-1"
          >
            <div
              className={`p-[8px] max-h-[200px] min-w-[140px] overflow-y-auto ${dropdownContainerClassname}`}
              role="none"
            >
              {shownFilteredData.length === 0 && (
                <span
                  className={`block px-[0.75rem] py-[0.5rem] text-[0.8125rem] font-[500] leading-[1rem] tracking-[-0.28px] ${dropdownDataClassname}`}
                  role="menuitem"
                  tabIndex="-1"
                  id="menu-item-0"
                >
                  Not found
                </span>
              )}
              {groupNames.length > 0 &&
                groupNames.map((groupName) => {
                  const dropdownGroup = groupedDropdownData[groupName];

                  const dropdownList = dropdownGroup.map((item) => {
                    return (
                      <span
                        className={`block px-[0.75rem] py-[0.5rem] text-[0.8125rem] font-[500] leading-[1rem] tracking-[-0.28px] hover:bg-neutral-flat-hover cursor-pointer ${dropdownDataClassname}`}
                        role="menuitem"
                        tabIndex="-1"
                        id="menu-item-0"
                        key={item.value}
                        onClick={(event) => {
                          event.stopPropagation();
                          setOpen(false);
                          onChange?.(item.value, name);
                          inputRef.current.value = item.label;
                        }}
                      >
                        {item.label}
                      </span>
                    );
                  });

                  if (groupNames.length === 1) {
                    return dropdownList;
                  }

                  return (
                    <Fragment key={groupName}>
                      {groupName && (
                        <span
                          className={`block px-[0.75rem] py-[0.5rem] text-[0.8125rem] font-[500] leading-[1rem] tracking-[-0.28px] ${dropdownDataClassname}`}
                          role="menuitem"
                          tabIndex="-1"
                          id="menu-item-0"
                        >
                          --- {groupName || "Others"} ---
                        </span>
                      )}
                      {dropdownList}
                    </Fragment>
                  );
                })}
            </div>
          </div>
        }
      >
        <div className="relative flex cursor-pointer">
          <input
            ref={inputRef}
            id={id}
            name={id}
            defaultValue={defaultValue}
            value={shownDefaultValue}
            aria-expanded={open}
            aria-haspopup="true"
            placeholder={placeholder}
            onChange={(e) => {
              if (allowCustomText) {
                onChange?.(e.target.value, name);
              }
              setKeyword(e.target.value);
            }}
            autoComplete={"off"}
            className={`!px-[1rem] !py-[6px] text-neutral-strong font-[600] text-[0.8125rem] leading-[1rem] tracking-[-0.26px] placeholder:font-[400] ${inputClassName} overflow-ellipsis`}
            readOnly={readOnly}
          />

          <div className={` ${open ? "rotate-180" : ""}`}>
            <ArrowDropdownIcon color="#121212" />
          </div>
        </div>
      </Popover>
    </div>
  );
}
