import Tooltip from "../../components/Tooltip";
import TableInput from "../../components/TableElements/Input";
import iconSave from "../../assets/icons/icon_table_save.svg";
import iconCancel from "../../assets/icons/icon_table_cancel.svg";
import { useState } from "react";
import formatNumber from "../../utils/number/formatNumber";
import iconTrash from "../../assets/icons/icon_table_trash.svg";
import iconAdditionOrSubtraction from "../../assets/icons/icon_table_addition_or_subtraction.svg";
import iconPlusGreen from "../../assets/icons/icon_table_green_plus.svg";

const FIELDS = {
  name: 'name',
  amount: 'amount',
}

export default function FinancialSpreadingValidateTableRowChildContentRow({
  isEditing,
  onSave,
  onCancelEdit,
  data,
  fiscalPeriods,
  onDelete,
  onStartEdit,
  onAddRow,
}) {
  const [formData, setFormData] = useState(data);

  const setName = (newName) => {
    setFormData((currentFormData) => ({
      ...currentFormData,
      [FIELDS.name]: newName
    }))
  }

  const setAmount = (periodString, inputtedAmount) => {
    setFormData((currentFormData) => {
      const { amount = {} } = currentFormData
      const newAmount = { ...amount, [periodString]: inputtedAmount }

      return {
        ...currentFormData,
        amount: newAmount,
      }
    })
  }

  const onInvertAmount = () => {
    setFormData((currentFormData) => {
      const { amount = {} } = currentFormData

      const newAmount = Object.keys(amount).reduce((finalAmount, periodString) => {
        const currentPeriodStringAmount = amount[periodString]
        return {
          ...finalAmount,
          [periodString]: currentPeriodStringAmount * -1
        }
      }, {})

      const newFormData = {
        ...currentFormData,
        amount: newAmount,
      }

      onSave(newFormData)
      return newFormData
    })
  }

  const { name, amount } = data;

  if (!isEditing) {
    return (
      <tr>
        <td
          colSpan={2}
          className="pl-16 bg-gray-200 sticky left-0 !h-[35px] !py-0 !text-[0.8125rem] !leading-[1rem] xl:w-[120px] 2xl:w-[200px]"
          onClick={onStartEdit}
        >
          {name}
        </td>
        {fiscalPeriods.map((fiscalPeriod) => (
          <td
            className="bg-gray-200 !h-[35px] !p-0 w-[6.25rem] max-w-[6.25rem] !h-[35px]"
            key={fiscalPeriod}
            onClick={onStartEdit}
          >
            <div
              className={`!pl-[0.75rem] !pr-[26px] !text-[0.8125rem] flex justify-end text-neutral-strong leading-[1.25rem] tracking-[-0.28px] font-[400]`}
            >
              {formatNumber(amount[fiscalPeriod]) || "-"}
            </div>
          </td>
        ))}
        <td
          className="sticky right-0 w-[6.25rem] bg-gray-200 !py-0 !px-[0.75rem] !hover:bg-[#f1f1f1] !h-[35px]"
        >
          <div className="flex justify-center gap-[0.625rem] items-center">
          <Tooltip
            tooltip="Invert number sign (+/-)"
            tooltipClassname="flex justify-center items-center max-w-[240px] p-1.5 px-2.5 py-1 bg-white bg-opacity-80 rounded !max-w-[240px] !w-[83px] !right-0 !-translate-x-[80%]"
            topTooltip
          >
            <button onClick={onInvertAmount}>
              <img
                src={iconAdditionOrSubtraction}
                className="w-[20px] cursor-pointer"
                alt="Invert value"
              />
            </button>
          </Tooltip>
          <Tooltip
            tooltip="Add row below"
            tooltipClassname="flex justify-center items-center max-w-[240px] p-1.5 px-2.5 py-1 bg-white bg-opacity-80 rounded !max-w-[240px] !w-[83px] !right-0 !-translate-x-[80%]"
            topTooltip
          >
            <button onClick={onAddRow}>
              <img
                src={iconPlusGreen}
                className="w-[20px] cursor-pointer"
                alt="Add New Row"
              />
            </button>
          </Tooltip>
          <Tooltip
            tooltip="Delete this row"
            tooltipClassname="flex justify-center items-center max-w-[240px] p-1.5 px-2.5 py-1 bg-white bg-opacity-80 rounded !max-w-[240px] !w-[85px] !right-0 !-translate-x-[80%]"
            topTooltip
          >
            <button onClick={onDelete}>
              <img
                src={iconTrash}
                className="w-[20px] cursor-pointer"
                alt="Delete row"
              />
            </button>
          </Tooltip>
          </div>
        </td>
      </tr>
    );
  }

  return (
    <tr className="bg-gray-200 hover:bg-[#f1f1f1]">
      <td
        colSpan={2}
        className="pl-8 font-bold bg-gray-200 sticky left-0 !h-[35px] p-0 px-[0.75rem] !text-[0.8125rem] !leading-[1rem] "
      >
        <TableInput
          // id={FIELDS.client_account_name}
          className="w-full"
          onChange={(e) => setName(e.target.value)}
          value={formData[FIELDS.name]}
        />
      </td>
      {fiscalPeriods.map((fiscalPeriod) => (
        <td
          className="bg-gray-200 !h-[35px] !p-0 w-[6.25rem]"
          key={fiscalPeriod}
        >
          <div className="!pl-[0.75rem] !pr-[26px] !text-[0.8125rem]">
            <TableInput
              defaultValue={amount[fiscalPeriod] || ""}
              className="w-full"
              onChange={(e) => setAmount(fiscalPeriod, parseFloat(e.target.value))}
            />
          </div>
        </td>
      ))}
      <td className="bg-gray-200 sticky right-0 !h-[35px] !py-0 !px-[0.75rem] flex justify-center items-center !h-[35px]">
        <div className="flex justify-center gap-[0.625rem] items-center">
          <button onClick={() => onSave(formData)}>
            <img
              src={iconSave}
              className="w-[20px] cursor-pointer"
              title="Save"
              alt="Save"
            />
          </button>
          <img
            onClick={() => {
              onCancelEdit()
              setFormData(data)
            }}
            src={iconCancel}
            className="w-[20px] cursor-pointer"
            alt="Cancel"
            title="Cancel"
          />
        </div>
      </td>
    </tr>
  );
}
