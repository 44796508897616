import { createContext, useContext, useEffect, useRef, useState } from "react";
import { API_LOGIN, API_LOGOUT, API_USER_ME } from "../../constants/url";
import useAuthenticatedFetch from "../../hooks/useAuthenticatedFetch";
import TokenExpiryModal from "../../components/TokenExpiryModal";
import { useTokenExpiry } from "../TokenExpiryContext";

const AuthContext = createContext({
    handleLogin: async (_username, _password) => { },
    isAuthenticated: false,
    handleLogout: async () => {},
    user: null,
    isLoading: false,
    setTokenExpiryTime: () => {},
})

export const useAuthContext = () => useContext(AuthContext)

export default function AuthProvider({ children }) {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null)
    const [isLoading, setLoading] = useState(true)
    const { tokenExpiryTime, setTokenExpiryTime } = useTokenExpiry(); 
    const [showExpiryModal, setShowExpiryModal] = useState(false);
    const [remainingTime, setRemainingTime] = useState(null);
    const WARNING_THRESHOLD = 30;
    const intervalId = useRef(null)
    const authenticatedFetch = useAuthenticatedFetch()

    const clearUserData = () => {
        setUser(null)
        setIsAuthenticated(false)
    }

    const fetchUserData = async () => {
        return authenticatedFetch(API_USER_ME, {
            withCredentials: true,
        }).then(({ data: userData }) => {
            setUser(userData)
            setIsAuthenticated(true)
        }).catch(() => {
            clearUserData()
        })
    }

    useEffect(() => {
        setLoading(true);

        fetchUserData().finally(() => {
            setLoading(false)
        })
    }, [])


    const updateRemainingTime = () => {
        const now = Math.floor(Date.now() / 1000);
        const remaining = tokenExpiryTime - now;
        setRemainingTime(remaining);

        setShowExpiryModal(remaining <= WARNING_THRESHOLD);

        if (remaining <= 0) {
            clearUserData();
            setShowExpiryModal(false);
        }
    };



    useEffect(() => {

        if(intervalId.current){
            clearInterval(intervalId.current);
            intervalId.current = null
        }

        if (!tokenExpiryTime) {
            setShowExpiryModal(false);
            setRemainingTime(null);
            return;
        }

        updateRemainingTime();
        intervalId.current = setInterval(updateRemainingTime, 1000);
        return () => clearInterval(intervalId.current);

    }, [tokenExpiryTime]);
    
    const handleLogin = async (username, password) => {
        setLoading(true)

        const formData = new FormData()
        formData.append('username', username)
        formData.append('password', password)

        return authenticatedFetch(API_LOGIN, {
            data: formData,
            method: 'POST',
        }).then(fetchUserData).finally(() => {
            setLoading(false)
        });
    }

    const handleLogout = async () => {
        setLoading(true)
        return authenticatedFetch(API_LOGOUT, {
            method: 'POST',
        }).catch(() => {
            // ignore error
        }).finally(() => {
            clearUserData()
            setLoading(false)
            setShowExpiryModal(false);
            setRemainingTime(null);
            setTokenExpiryTime(null);
        })
    }

    return (
        <AuthContext.Provider
          value={{
            handleLogin,
            isAuthenticated,
            handleLogout,
            user,
            isLoading,
            tokenExpiryTime,
            showExpiryModal,
            remainingTime,
          }}
        >
          {children}
          <TokenExpiryModal showExpiryModal={showExpiryModal} remainingTime={remainingTime}/>
        </AuthContext.Provider>
      );
}