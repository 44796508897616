import PropTypes from "prop-types";

Modal.propTypes = {
  img: PropTypes.string,
  title: PropTypes.element,
  confirmText: PropTypes.string,
  titleClassname: PropTypes.string,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  additionalContent: PropTypes.element,
  cancelText: PropTypes.string,
};

export default function Modal({
  title,
  img,
  imgProps = {},
  confirmText,
  buttonTextClassName = '',
  titleClassname = '',
  onConfirm,
  onClose,
  additionalContent,
  cancelText,
  className = '',
  containerClassName = '',
  buttonClassName = '',
}) {
  return (
    <div className={`fixed inset-0 w-full h-full bg-white bg-opacity-50 backdrop-blur-md flex flex-col justify-center items-center z-50 text-[rgb(32,58,164)] gap-[2.5rem] ${containerClassName}`}>
      <div className={`w-[429px] p-[1.5rem] !pt-0 flex flex-col align-justify gap-2 rounded-[17.481px] bg-white bg-opacity-60 shadow-[0px_4px_84px_0px_rgba(0,14,28,0.2)] backdrop-blur-[10px] ${className}`}>
        <div className="flex flex-col justify-center align-center gap-[0.5rem]">
          {img && <div className="flex align-center justify-center">
            <img src={img} alt={title} width={160} height={160} {...imgProps} />
          </div>}
          <div
            className={`text-[#20481F] text-center text-2xl font-bold leading-[2rem] tracking-[-0.72px] ${titleClassname}`}
          >
            {title}
          </div>
          {additionalContent}
          <button
            className={`mt-2 flex h-[3rem] px-[1.25rem] py-[0.75rem] flex-col justify-center align-center rounded-[8px] bg-gradient-to-r from-[#023972] to-[#356CA5] ${buttonClassName}`}
            onClick={onConfirm}
          >
            <div className={`w-full text-[#fff] text-base font-semibold leading-[1.5rem] tracking-[-0.48px] ${buttonTextClassName}`}>
              {confirmText}
            </div>
          </button>
          {onClose && <button
            className={`mt-1 flex flex-col align-center justify-center px-[20px] py-[0.75rem] gap-[0.5rem] rounded-[8px] ${buttonClassName}`}
            onClick={onClose}
          >
            <div className={`w-full text-[#121212] text-base font-semibold leading-[1.5rem] tracking-[-0.48px] ${buttonTextClassName}`}>
              {cancelText || "Close"}
            </div>
          </button>}
        </div>
      </div>
    </div>
  );
}
