import { ALL_DOCUMENT_TYPES, DOCUMENT_TYPE_GENERATED } from "../../constants";
import { FORMULAS_MAP } from "../../constants/formula";
import calculateFormulaFromData from "./calculate_formula_from_data";

export default function calculateGeneratedDocuments(data, modifier = 1, fiscalPeriods) {
  const uniqueDocumentTypes = [...new Set(data.map(item => item.document_type))];

  const baseCalculateMetadata = uniqueDocumentTypes.reduce(
    (existingMetadata, documentType) => {
      return {
        ...existingMetadata,
        [documentType]: true,
      };
    },
    {
      isOldestYear: false, 
    }
  );
  
  const calculatedFormulas = DOCUMENT_TYPE_GENERATED.reduce(
    (fin, { key, formula_type = key }) => {
      const currFormula = FORMULAS_MAP[formula_type].map((data) => ({
        ...data,
        document_type: formula_type,
      }));
      return [...fin, ...currFormula];
    },
    []
  );

  const calculatedGeneratedData = calculateFormulaFromData(
    data,
    calculatedFormulas,
    baseCalculateMetadata,
    {},
    {
      useFullPeriodString: true,
      modifier,
      fiscalPeriods,
    },
  );

  return calculatedGeneratedData;
}
