import { useEffect, useState } from "react";
import { ReactComponent as NotFoundIcon } from "../../assets/icons/icon_not_found.svg";
import notFoundIcon from "../../assets/icons/icon_not_found.svg";
import Modal from "../../components/Modal";
import TextInput from "../../components/TextInput";
import LoadingLogo from "../../components/LoadingLogo";
import { APPLICATION_VALIDATION_TAB_STATUSES } from "./statuses";

const STEP_TYPES = {
  STARTED: "STARTED",
  IN_PROGRESS: "IN_PROGRESS",
  LOADING: "LOADING",
  HIDDEN: "HIDDEN",
};

export default function FinancialSpreadingValidateTableModal({
  tabStatus = {},
  onReprocess,
  onOpenReprocessForm,
  onCancelReprocessForm,
}) {
  const { status, text } = tabStatus;
  const [formValue, setFormValue] = useState({});

  let step;

  switch (status) {
    case undefined:
    case null:
    case APPLICATION_VALIDATION_TAB_STATUSES.CONFIRMED:
    case APPLICATION_VALIDATION_TAB_STATUSES.FAILED:
      step = STEP_TYPES.HIDDEN;
      break;
  
    case APPLICATION_VALIDATION_TAB_STATUSES.LOADING:
      step = STEP_TYPES.LOADING;
      break;
  
    case APPLICATION_VALIDATION_TAB_STATUSES.APPLYING_REPROCESSING:
      step = STEP_TYPES.IN_PROGRESS;
      break;
  
    default:
      step = STEP_TYPES.STARTED;
  }

  if (step === STEP_TYPES.HIDDEN) {
    return null;
  }

  let bodyContent = (
    <Modal
      title={`${text} Not Found`}
      titleClassname="text-base"
      img={notFoundIcon}
      imgProps={{ width: 100, height: 100 }}
      additionalContent={
        <div className="text-black text-[0.8125rem] text-center">
          If you believe this is a mistake, click the button below. Otherwise,
          please proceed with the other statements and click “Next” when you’re
          done!
        </div>
      }
      confirmText="Reprocess Mapping"
      buttonTextClassName="!text-sm"
      onConfirm={onOpenReprocessForm}
      containerClassName="!justify-start !top-0 pt-24 !z-0 !bg-opacity-5"
      className="!w-96 sticky top-24"
      buttonClassName="!h-[2rem]"
    />
  );

  if (step === STEP_TYPES.IN_PROGRESS) {
    bodyContent = (
      <Modal
        title={`Reprocess ${text}`}
        titleClassname="text-base pt-4"
        additionalContent={
          <div className="text-black text-[0.8125rem] text-center">
            <div className="mb-2">
              Please key in the page numbers for the {text}, and we will process it again.
            </div>
            <TextInput
              id="page_numbers"
              required
              onChange={(e) => setFormValue((previousForm) => ({ ...previousForm, page_numbers: e.target.value }))}
              placeholder="Enter page numbers (e.g. 1, 2, 3-5)"
            />
          </div>
        }
        confirmText="Submit"
        buttonTextClassName="!text-sm"
        onConfirm={() => onReprocess(formValue)}
        onClose={onCancelReprocessForm}
        cancelText="Cancel"
        containerClassName="!justify-start pt-24 !z-0 text-[#121212] text-base !bg-opacity-5"
        className="!w-96 sticky top-24"
        buttonClassName="!h-[2rem]"
      />
    );
  }

  if (step === STEP_TYPES.LOADING) {
    bodyContent = (
      <div className="fixed inset-0 flex flex-col justify-start items-center ">
        <div className="pt-48 !sticky top-24">
          <LoadingLogo width={280} />
        </div>
      </div>
    )
  }

  return (
    <div className="absolute inset-0 w-full h-full bg-white bg-opacity-5 backdrop-blur-md z-[11] rounded-[20px]">
      <div className="relative">
      {bodyContent}
      </div>
    </div>
  );
}
