const activeClass =
  "border-b-[2px] text-primary-2 font-[700] leading-[1.5rem] tracking-[-0.32px] underline decoration-primary-2 decoration-[2px] underline-offset-[19px]";
const defaultTabClass = "cursor-pointer text-[1rem] pb-[12px]";
const disabledClass = "text-Text-disabled cursor-not-allowed";

export default function Tabs({
  activeTab,
  setActiveTab,
  tabsLabel = [],
  wrapperClassname,
}) {
  const handleChangeActiveTabs = (tabKey) => {
    setActiveTab(tabKey);
  };
  return (
    <div
      class={`w-full flex gap-[1.5rem] border-b-[1px] border-solid border-card-on-surface ${wrapperClassname}`}
    >
      {tabsLabel.map(({ key, text, icon }, index) => (
        <div className="flex gap-[0.5rem]">
          <span
            className={`${
              activeTab === key ? activeClass : ""
            } ${defaultTabClass}`}
            onClick={() => handleChangeActiveTabs(key)}
            key={index}
          >
            {text}
          </span>
          {icon}
        </div>
      ))}
    </div>
  );
}
