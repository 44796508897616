export default function generatePeriodString(monthNo, year, period, audited = '', monthName = '') {
  const date = new Date(year, monthNo - 1); 


  let finalMonthName = monthName

  if (!finalMonthName) {
    finalMonthName = date.toLocaleString('en-US', { month: 'short' });
  }

  let basePeriodString = `${finalMonthName} ${year}`

  if (period) {
    basePeriodString = `${period}M ${basePeriodString}`
  }

  if (audited) {
    basePeriodString = `${basePeriodString} ${audited}`
  }

  return basePeriodString;
}