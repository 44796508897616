import React, { Component } from "react";
import trustplusLogo from "../../assets/images/trustplus_logo.svg";
import error500 from "../../assets/images/error-500.png";
import { URL_CONTACT_US_ERROR, URL_LOGIN } from "../../constants/url";

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  // Update state so the next render shows the fallback UI
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  // You can log the error to an error reporting service
  componentDidCatch(error, errorInfo) {
    console.error("Error caught in Error Boundary: ", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="w-full max-w-default 2xl:px-16 sm:px-[48px] relative min-h-[100vh] flex flex-col">
          <a href={URL_LOGIN} className="z-10">
            <img
              src={trustplusLogo}
              className="w-[215px] h-[32px] absolute top-[52px] left-[64px] cursor-pointer"
              alt="Trustplus"
            />
          </a>
          <div className="w-full absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] p-[2rem] flex flex-col justify-center items-center">
            <img
              src={error500}
              className="w-[440px] h-[440px]"
              alt="Internal Error"
            />
            <span className="text-[2.75rem] font-[700] leading-[3rem] tracking-[-1.76px] text-primary-strong mb-[20px]">
              We’re Experiencing Some Technical Difficulties
            </span>
            <div>
              <span class="block text-neutral-medium text-[1.5rem] font-[500] leading-[2rem] tracking-[-0.72px] text-center">
                We’re experiencing technical difficulties and are working to fix
                the issue. We apologize for
                <br /> the inconvenience and appreciate your patience. Please
                try refreshing the page or come
                <br /> back later. If the issue persists, please contact our
                support team.
              </span>
            </div>
            <a href={URL_CONTACT_US_ERROR}>
              <button className="primary w-[278px] mt-[44px]">
                Contact Us
              </button>
            </a>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
