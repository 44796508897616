import TableInput from "../../components/TableElements/Input";
import Tooltip from "../../components/Tooltip";
import iconSave from "../../assets/icons/icon_table_save.svg";
import iconTrash from "../../assets/icons/icon_table_trash.svg";
import iconPlusGreen from "../../assets/icons/icon_table_green_plus.svg";
import iconCancel from "../../assets/icons/icon_table_cancel.svg";
import extractPeriodAndDate from "../../utils/string/extractPeriodAndDate";
import TableSelect from "../../components/TableElements/Select";
import formatNumber from "../../utils/number/formatNumber";
import {
  FORMAT_NUMBER_FORMULA_MAP,
  FORMAT_NUMBER_TYPE,
} from "../../constants/number";
import { formatMultiple, applyModifier } from "../../utils/formula/format_amount";
import { DEVICE_WIDTH } from "../../constants/dimension";
import { ReactComponent as TooltipIcon } from "../../assets/icons/icon_info_tooltip.svg";
import { Fragment } from "react";

export const financialSpreadingResultColumns = (
  fiscal_period_order = [],
  width,
  showMonth
) => ({
  minWidth:
    width >= DEVICE_WIDTH.DESKTOP
      ? 350 + fiscal_period_order.length * 120
      : 300 + fiscal_period_order.length * 100,
  renderChild: ({
    showChild,
    client_account_name,
    amount,
    exchange_rate,
    modifier,
    decimalPlace,
    child,
  }) => {
    return (
      <Fragment>
        <tr className="!border-b !border-gray-300">
          <td className="!bg-gray-200"></td>
          <td
            className={`bg-white sticky left-0 !h-[35px] !p-0 2xl:w-[350px] lg:w-[300px] !bg-gray-200`}
            style={{ width: width >= DEVICE_WIDTH.DESKTOP ? "350px" : "300px" }}
          >
            <div
              className={`px-[0.75rem] !pl-6 h-full align-middle !text-[0.8125rem] items-center flex justify-start font-bold leading-[1.25rem] tracking-[-0.28px] font-[400] w-full border-r-[1px] border-r-card-on-surface`}
              style={{
                width: width >= DEVICE_WIDTH.DESKTOP ? "350px" : "300px",
              }}
            >
              {client_account_name}
            </div>
          </td>
          {fiscal_period_order.map((periodString, index) => {
            return (
              <td
                className={`bg-white !h-[35px] !p-0 min-w-[100px] 2xl:max-w-[120px] lg:max-w-[100px] !bg-gray-200`}
              >
                <div
                  className={`!px-[9.5px] !text-[0.8125rem] flex justify-end 2xl:max-w-[120px] lg:max-w-[100px] font-bold leading-[1.25rem] tracking-[-0.28px] font-[400] w-full`}
                  style={{
                    width:
                      index < fiscal_period_order.length - 1
                        ? width >= DEVICE_WIDTH.DESKTOP
                          ? "120px"
                          : "100px"
                        : "auto",
                  }}
                >
                  {amount[periodString]
                    ? formatNumber(
                        (amount[periodString] / (exchange_rate || 1)) *
                          modifier,
                        decimalPlace
                      )
                    : "-"}
                </div>
              </td>
            );
          })}
        </tr>
        {!!child?.length &&
          child.map(({ table_name, content }) => {
            return (
              <Fragment>
                <tr className="border-b !border-gray-300">
                  <td className="cursor-pointer w-12 flex items-center justify-center !bg-gray-200"></td>
                  <td
                    className={`bg-white sticky left-0 !h-[35px] !p-0 2xl:w-[350px] lg:w-[300px] !bg-gray-200`}
                    style={{
                      width: width >= DEVICE_WIDTH.DESKTOP ? "350px" : "300px",
                    }}
                  >
                    <div
                      className={`px-[0.75rem] !pl-12 h-full align-middle !text-[0.8125rem] items-center flex justify-start italic leading-[1.25rem] tracking-[-0.28px] font-[400] w-full border-r-[1px] border-r-card-on-surface`}
                      style={{
                        width:
                          width >= DEVICE_WIDTH.DESKTOP ? "350px" : "300px",
                      }}
                    >
                      {table_name}
                    </div>
                  </td>
                  <td
                    colSpan={fiscal_period_order.length}
                    className="bg-gray-200"
                  ></td>
                </tr>
                {!!content?.length &&
                  content.map(({ name, amount }) => {
                    return (
                      <tr className="border-b !border-gray-300">
                        <td className="cursor-pointer w-12 flex items-center justify-center !bg-gray-200"></td>
                        <td
                          className={`bg-white sticky left-0 !h-[35px] !p-0 2xl:w-[350px] lg:w-[300px] !bg-gray-200`}
                          style={{
                            width:
                              width >= DEVICE_WIDTH.DESKTOP ? "350px" : "300px",
                          }}
                        >
                          <div
                            className={`px-[0.75rem] !pl-20 h-full align-middle !text-[0.8125rem] items-center flex justify-start leading-[1.25rem] tracking-[-0.28px] font-[400] w-full border-r-[1px] border-r-card-on-surface`}
                            style={{
                              width:
                                width >= DEVICE_WIDTH.DESKTOP
                                  ? "350px"
                                  : "300px",
                            }}
                          >
                            {name}
                          </div>
                        </td>
                        {fiscal_period_order.map((periodString, index) => {
                          return (
                            <td
                              className={`bg-white !h-[35px] !p-0 min-w-[100px] 2xl:max-w-[120px] lg:max-w-[100px] !bg-gray-200`}
                            >
                              <div
                                className={`!px-[9.5px] !text-[0.8125rem] flex justify-end 2xl:max-w-[120px] lg:max-w-[100px] font-[400] leading-[1.25rem] tracking-[-0.28px] font-[400] w-full`}
                                style={{
                                  width:
                                    index < fiscal_period_order.length - 1
                                      ? width >= DEVICE_WIDTH.DESKTOP
                                        ? "120px"
                                        : "100px"
                                      : "auto",
                                }}
                              >
                                {amount[periodString]
                                  ? formatNumber(
                                      (amount[periodString] /
                                        (exchange_rate || 1)) *
                                        modifier,
                                      decimalPlace
                                    )
                                  : "-"}
                              </div>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
              </Fragment>
            );
          })}
      </Fragment>
    );
  },
  columns: [
    {
      key: "expand",
      renderHeader: ({ isAllExpanded, onExpandAll }) => {
        return (
          <th onClick={onExpandAll} className="rounded-tl-[8px] px-2 w-12 cursor-pointer ">
            <div className="border border-gray-200 max-w-8 min-w-8 text-center rounded-lg">
                <span className="text-xl">{isAllExpanded ? "-" : "+"}</span>
              </div>
          </th>
        );
      },
      renderRow: ({ showChild, children, onExpand }) => {
        return (
          <td
            onClick={onExpand}
            className={`cursor-pointer w-12 flex items-center justify-center ${
              children?.length ? "" : "bg-primary-50"
            } `}
          >
            {!!children?.length && (
              <div className="border border-gray-200 px-2 max-w-8 min-w-8 text-center rounded-lg">
                <span className="text-xl">{showChild ? "-" : "+"}</span>
              </div>
            )}
          </td>
        );
      },
    },
    {
      key: "standardAccountName",
      renderHeader: () => (
        <th
          className="!p-[0] !h-[51px] bg-primary-50 2xl:max-w-[350px] lg:max-w-[300px] sticky left-0"
          style={{
            width: width >= DEVICE_WIDTH.DESKTOP ? "350px" : "300px",
          }}
        >
          <div
            className="text-Gray-500 h-full !px-[0.75rem] text-[0.8125rem] font-[600] leading-[1rem] flex items-center w-full border-r-[1px] border-r-card-on-surface"
            style={{ width: width >= DEVICE_WIDTH.DESKTOP ? "350px" : "300px" }}
          >
            Standard Account Name
          </div>
        </th>
      ),
      renderRow: ({ tp_standard_account_name, bold }) => (
        <td
          className={`${
            bold ? "bg-primary-50" : "bg-white"
          } sticky left-0 !h-[35px] !p-0 2xl:w-[350px] lg:w-[300px]`}
          style={{ width: width >= DEVICE_WIDTH.DESKTOP ? "350px" : "300px" }}
        >
          <div
            className={`!px-[0.75rem] h-full align-middle !text-[0.8125rem] items-center flex justify-start ${
              bold ? "font-bold" : "text-neutral-strong"
            } leading-[1.25rem] tracking-[-0.28px] font-[400] w-full border-r-[1px] border-r-card-on-surface`}
            style={{ width: width >= DEVICE_WIDTH.DESKTOP ? "350px" : "300px" }}
          >
            {tp_standard_account_name}
          </div>
        </td>
      ),
    },
    ...fiscal_period_order.map((periodString, index) => ({
      key: periodString,
      renderHeader: (dataProps) => {

        const periodStringMetadata = dataProps.periodStringMetadata?.[periodString];
        const {auditor_name} = periodStringMetadata || {};
        const {
          month,
          year,
          period = 12,
          audited,
        } = extractPeriodAndDate(periodString);

        let displayedPeriod = year;

        if (month) {
          displayedPeriod = `${month} ${displayedPeriod}`;
        }
        return (
          <th
            className={`!p-[0] !h-[51px] bg-primary-50 text-Gray-500 2xl:max-w-[120px] lg:max-w-[100px] ${
              index === fiscal_period_order.length - 1 && "rounded-tr-[8px]"
            }`}
            style={{
              width:
                index < fiscal_period_order.length - 1
                  ? width >= DEVICE_WIDTH.DESKTOP
                    ? "120px"
                    : "100px"
                  : "auto",
            }}
          >
            <div className="min-w-[100px] !pr-[9.5px] text-end flex justify-end 2xl:max-w-[120px] lg:max-w-[100px]">
              <div className="flex flex-col items-center justify-end">
                <span className="font-[600] text-[0.8125rem] leading-[1.25rem] self-end">
                  {displayedPeriod}
                </span>
                {showMonth && (
                  <span className="font-[500] text-[0.75rem] leading-[1rem] text-end w-full">
                    {period} months
                  </span>
                )}
                <span className="font-[500] text-[0.7rem] leading-[1rem] text-end w-full">
                  {audited}
                </span>
                <span className="font-[500] text-[0.7rem] leading-[1rem] text-end w-full break-all">
                  {auditor_name}
                </span>
              </div>
            </div>
          </th>
        );
      },
      renderRow: ({
        amount,
        bold,
        exchange_rate = 1,
        modifier = 1,
        decimalPlace,
      }) => (
        <td
          className={`${
            bold ? "bg-primary-50" : "bg-white"
          } !h-[35px] !p-0 min-w-[100px] 2xl:max-w-[120px] lg:max-w-[100px]`}
        >
          <div
            className={`!px-[9.5px] !text-[0.8125rem] flex justify-end 2xl:max-w-[120px] lg:max-w-[100px] ${
              bold ? "font-bold" : "text-neutral-strong"
            } leading-[1.25rem] tracking-[-0.28px] font-[400] w-full`}
            style={{
              width:
                index < fiscal_period_order.length - 1
                  ? width >= DEVICE_WIDTH.DESKTOP
                    ? "120px"
                    : "100px"
                  : "auto",
            }}
          >
            {amount[periodString]
              ? formatNumber(
                  (amount[periodString] / (exchange_rate || 1)) * modifier,
                  decimalPlace
                )
              : "-"}
          </div>
        </td>
      ),
    })),
  ],
});

export const financialSpreadingResultColumnsCompany = ({
  fiscalPeriods,
  isEdit = false,
  standardAccountOptions = [],
}) => {
  return {
    minWidth: isEdit
      ? 248 + 248 + 112 * fiscalPeriods.length
      : 320 + 112 * fiscalPeriods.length,
    laptopMinWidth: isEdit
      ? 140 + 140 + 112 * fiscalPeriods.length
      : 140 + 112 * fiscalPeriods.length,
    columns: [
      isEdit && {
        key: "client_account_name",
        renderHeader: () => (
          <th className="!p-[0] !h-[51px] bg-primary-50 rounded-tl-[8px] 2xl:w-[248px] xl:w-[140px] sticky left-0 ">
            <div className="text-Gray-500 h-full !px-[0.75rem] text-[0.8125rem] font-[600] leading-[1rem] flex items-center w-full border-r-[1px] border-r-card-on-surface">
              Client Account Name
            </div>
          </th>
        ),
        renderRow: ({ client_account_name }) => (
          <td className="bg-white sticky left-0 !h-[35px] !p-0 2xl:w-[248px] xl:w-[140px] ">
            <div className="!px-[0.75rem] h-full align-middle !text-[0.8125rem] items-center flex justify-start text-neutral-strong leading-[1.25rem] tracking-[-0.28px] font-[400] w-full border-r-[1px] border-r-card-on-surface">
              {client_account_name}
            </div>
          </td>
        ),
        renderEditRow: ({
          editingRows,
          id,
          client_account_name,
          handleEditRecordValue,
        }) =>
          editingRows[id] ? (
            <td className="bg-primary-50 sticky left-0 !h-[35px] !p-0 !px-[0.75rem] !text-[0.8125rem] !leading-[1rem] ">
              <div className="2xl:max-w-[248px] xl:max-w-[140px]!px-[0.75rem]">
                <TableInput
                  className="w-full"
                  name="client_account_name"
                  handleChange={handleEditRecordValue}
                />
              </div>
            </td>
          ) : (
            <td className="bg-white sticky left-0 !h-[35px] !p-0 !text-[0.8125rem] !leading-[1rem]">
              <div className="flex justify-start text-neutral-strong leading-[1.25rem] tracking-[-0.28px] font-[400] xl:w-[140px] 2xl:w-[248px] !px-[0.75rem]">
                {client_account_name}
              </div>
            </td>
          ),
      },
      {
        key: "tp_standard_account_name",
        renderHeader: () => (
          <th
            className={`!p-[0] !h-[51px] bg-primary-50 sticky left-0 min-w-[140px] ${
              !isEdit
                ? "rounded-tl-[8px] min-w-[140px]"
                : "2xl:w-[248px] xl:w-[140px]"
            }`}
          >
            <div className="text-Gray-500 h-full min:w-[248px] !px-[0.75rem] text-[0.8125rem] font-[600] leading-[1rem] flex justify-start items-center w-full border-r-[1px] border-r-card-on-surface">
              Standard Account Name
            </div>
          </th>
        ),
        renderRow: ({ tp_standard_account_name, handleSetEditingRows, id }) => (
          <td
            className={`bg-white sticky !h-[35px] !p-0 ${
              isEdit
                ? "xl:left-[140px] 2xl:left-[248px] 2xl:w-[248px] xl:w-[140px]"
                : "min:w-[248px]"
            }`}
          >
            <div
              className="!px-[0.75rem] h-full !text-[0.8125rem] items-center flex justify-start text-neutral-strong leading-[1.25rem] tracking-[-0.28px] font-[400] w-full border-r-[1px] border-r-card-on-surface"
              onClick={() =>
                isEdit ? handleSetEditingRows(id, false) : () => {}
              }
            >
              {tp_standard_account_name}
            </div>
          </td>
        ),
        renderEditRow: ({
          account_code,
          handleEditRecordValue,
        }) => (
          <td className="bg-primary-50 sticky xl:left-[140px] 2xl:left-[248px] !h-[35px] !p-0 z-10 min:w-[248px]">
            <div className="w-full !px-[0.75rem] !text-[0.8125rem]">
              <TableSelect
                defaultValue={account_code}
                data={standardAccountOptions}
                className="w-full"
                name="tp_standard_account_name"
                handleChange={handleEditRecordValue}
                readOnly
              />
            </div>
          </td>
        ),
      },
      ...fiscalPeriods.map((item, index) => ({
        key: item,
        renderHeader: () => {
          const header = extractPeriodAndDate(item);
          return (
            <th
              className={`!p-[0] !h-[51px] bg-primary-50 text-Gray-500 xl:left-[140px] 2xl:left-[248px] ${
                index === fiscalPeriods.length - 1 && !isEdit
                  ? "rounded-tr-[8px]"
                  : ""
              }`}
            >
              <div className="2xl:max-w-[120px] lg:max-w-[100px] !pr-[9.5px] text-end flex justify-end">
                <div className="flex flex-col items-center justify-end">
                  <span className="font-[600] text-[0.8125rem] leading-[1.25rem]">
                    {`${header.month} ${header.year}`}
                  </span>
                  <span className="font-[500] text-[0.75rem] leading-[1rem] text-end w-full">
                    {header.audited}
                  </span>
                </div>
              </div>
            </th>
          );
        },
        renderRow: (_) => (
          <td className="bg-white !h-[35px] !p-0">
            <div
              className="!pl-[0.75rem] !pr-[9.5px] !text-[0.8125rem] flex justify-end text-neutral-strong leading-[1.25rem] tracking-[-0.28px] font-[400] min-w-[112px]"
              onClick={
                isEdit ? () => _.handleSetEditingRows(_.id, false) : () => {}
              }
            >
              {_[item]?.toLocaleString()}
            </div>
          </td>
        ),
        renderEditRow: (_) => (
          <td className="bg-primary-50 !h-[35px] !p-0 ">
            <div className="min-w-[112px] !pl-[0.75rem] !pr-[26px] !text-[0.8125rem]">
              <TableInput
                defaultValue={_[item]}
                className="w-full"
                name={item}
                handleChange={_.handleEditRecordValue}
                type="number"
              />
            </div>
          </td>
        ),
      })),
      isEdit && {
        key: "action",
        renderHeader: () => (
          <th className="!p-[0] !h-[56px] 2xl:max-w-[120px] lg:max-w-[100px] text-Gray-500 text-[0.75rem] font-[600] leading-[1rem] bg-primary-50 rounded-tr-[8px] w-[112px] sticky right-0">
            <div className="flex justify-center">Actions</div>
          </th>
        ),
        renderRow: ({ index, handleAddMoreColumn, handleRemoveRw, id }) => (
          <td className="bg-white sticky right-0 w-[112px] !h-[35px] !py-0 !px-[0.75rem]">
            <div className="flex justify-center gap-[0.625rem] items-center">
              <Tooltip
                tooltip="Add row below"
                tooltipClassname="flex justify-center items-center p-1.5 px-2.5 py-1 bg-white bg-opacity-80 rounded !max-w-[240px] !w-[83px]"
              >
                <img
                  src={iconPlusGreen}
                  onClick={() => handleAddMoreColumn(index + 1)}
                  className="w-[20px] cursor-pointer"
                  alt=""
                />
              </Tooltip>
              <Tooltip
                tooltip="Delete this row"
                tooltipClassname="flex justify-center items-center p-1.5 px-2.5 py-1 bg-white bg-opacity-80 rounded !max-w-[240px] !w-[85px]"
              >
                <img
                  src={iconTrash}
                  onClick={() => {
                    handleRemoveRw(id);
                  }}
                  className="w-[20px] cursor-pointer"
                  alt=""
                />
              </Tooltip>
            </div>
          </td>
        ),
        renderEditRow: ({
          handleRemoveRw,
          handleCancelEditingRow,
          editingRows,
          handleEditRecord,
          id,
          index,
        }) => (
          <td className="bg-primary-50 sticky right-0 w-[112px] !h-[35px] !py-0 !px-[0.75rem]">
            <div className="flex justify-center gap-[0.625rem] items-center">
              <Tooltip
                tooltip="Save"
                tooltipClassname="flex justify-center items-center p-1.5 px-2.5 py-1 bg-white bg-opacity-80 rounded !max-w-[39px] !w-[85px]"
              >
                <img
                  src={iconSave}
                  className="w-[20px] cursor-pointer"
                  alt=""
                  onClick={handleEditRecord}
                />
              </Tooltip>
              <Tooltip
                tooltip="Cancel"
                tooltipClassname="flex justify-center items-center p-1.5 px-2.5 py-1 bg-white bg-opacity-80 rounded !max-w-[39px] !w-[85px]"
              >
                <img
                  onClick={() => {
                    if (editingRows?.[id]) {
                      handleRemoveRw(index);
                      return;
                    }
                    handleCancelEditingRow(id);
                  }}
                  src={iconCancel}
                  className="w-[20px] cursor-pointer"
                  alt=""
                />
              </Tooltip>
            </div>
          </td>
        ),
      },
    ].filter((item) => !!item),
  };
};

export const generatedColumns = (fiscal_period_order = [], width, additionalFiscalPeriodOrders = [], period_string_metadata = {}) => ({
  minWidth:
    width >= DEVICE_WIDTH.DESKTOP
      ? 350 + (fiscal_period_order.length + additionalFiscalPeriodOrders.length) * 120
      : 300 + (fiscal_period_order.length + additionalFiscalPeriodOrders.length) * 100,
  renderHeader: () => (
    <>
      <th className="!p-[0] !h-[35px] bg-primary-50 rounded-tl-[8px] 2xl:w-[350px] lg:w-[300px] sticky left-0 ">
        <div className="text-Gray-500 h-full !px-[0.75rem] text-[0.8125rem] font-[600] leading-[1rem] flex items-center w-full border-r-[1px] border-r-card-on-surface">
          Key Ratio
        </div>
      </th>
      {fiscal_period_order.map((periodString, index) => {
        const {
          month,
          year,
          period = 12,
          audited,
        } = extractPeriodAndDate(periodString);

        let displayedPeriod = year;

        if (month) {
          displayedPeriod = `${month} ${displayedPeriod}`;
        }

        const isLast = index === fiscal_period_order.length - 1 && !additionalFiscalPeriodOrders?.length

        return (
          <th
            className={`!p-[0] !h-[35px] bg-primary-50 2xl:max-w-[120px] lg:max-w-[100px] ${isLast && "rounded-tr-[8px]"}`}
            key={periodString}
            style={{
              width:
                !isLast
                  ? width >= DEVICE_WIDTH.DESKTOP
                    ? "120px"
                    : "100px"
                  : "auto",
            }}
          >
            <div className="min-w-[100px] !pr-[9.5px] text-end flex justify-end 2xl:max-w-[120px] lg:max-w-[100px]">
              <div className="flex flex-col items-center justify-end text-gray-500">
                <span className="font-[600] text-[0.8125rem] leading-[1.25rem] self-end">
                  {displayedPeriod}
                </span>
                {period && (
                  <span className="font-[500] text-[0.75rem] leading-[1rem] text-end w-full"> 
                    {period} months
                  </span>
                )}
                <span className="font-[500] text-[0.7rem] leading-[1rem] text-end w-full">
                  {audited}
                </span>
                <span className="font-[500] text-[0.7rem] leading-[1rem] text-end w-full break-all">
                  {period_string_metadata?.[periodString]?.auditor_name}
                </span>
              </div>
            </div>
          </th>
        );
      })}
      {additionalFiscalPeriodOrders.map((periodString, index) => {
        const {
          month,
          year,
          audited,
        } = extractPeriodAndDate(periodString);

        let displayedPeriod = year;

        if (month) {
          displayedPeriod = `${month} ${displayedPeriod}`;
        }

        const isLast = index === additionalFiscalPeriodOrders.length - 1 

        return (
          <th
            className={`!p-[0] !h-[35px] bg-primary-50 2xl:max-w-[120px] lg:max-w-[100px] ${isLast && "rounded-tr-[8px]"}`}
            key={periodString}
            style={{
              width:
                !isLast
                  ? width >= DEVICE_WIDTH.DESKTOP
                    ? "120px"
                    : "100px"
                  : "auto",
            }}
          >
            <div className="text-end flex justify-end 2xl:max-w-[120px] lg:max-w-[100px]">
              <div className="flex flex-col py-[4px] items-end justify-end">
                <span className="text-Gray-500 h-full !px-[0.75rem] text-[0.8125rem] font-[600] leading-[1rem]">
                  {displayedPeriod}
                </span>
                <span className="text-Gray-500 h-full !px-[0.75rem] text-[0.7rem] font-[500] leading-[1rem] text-end w-full">
                  {audited}
                </span>
                <span className="text-Gray-500 h-full !px-[0.75rem] text-[0.7rem] font-[500] leading-[1rem] text-end w-full break-all">
                  {period_string_metadata?.[periodString]?.auditor_name}
                </span>
              </div>
            </div>
          </th>
        );
      })}
    </>
  ),
  renderTitle: (title, index) => (
    <>
      <td className="bg-white sticky left-0 !h-[35px] !p-0 2xl:w-[350px] lg:w-[300px]">
        {index !== 0 && <div className="h-[20px]" />}
        <div className="h-[35px] !px-[0.75rem] align-middle !text-[0.8125rem] items-center flex justify-start text-neutral-strong leading-[1.25rem] tracking-[-0.28px] font-[400] w-full border-r-[1px] border-r-card-on-surface">
          {/* <Checkbox title={title} /> */}
          {title}
        </div>
      </td>
    </>
  ),
  renderRow: ({
    tp_standard_account_name,
    description,
    amount,
    formatTypes = [FORMAT_NUMBER_TYPE.FORMAT_NUMBER],
    modifier,
  }) => (
    <> 
      <td className="bg-white !h-[35px] !p-0 2xl:w-[350px] lg:w-[300px] sticky left-0 ">
        <div className="!px-[32px] h-full align-middle !text-[0.8125rem] items-center flex justify-start gap-[0.75rem] text-neutral-strong leading-[1.25rem] tracking-[-0.28px] font-[400] w-full border-r-[1px] border-r-card-on-surface">
          {/* <Checkbox title={row["label"]} /> */}
          {tp_standard_account_name}
          {description && (
            <Tooltip
              tooltip={description}
              tooltipClassname={`!right-0 !-translate-x-0`}
              // topTooltip
              customStyle={{
                maxWidth: `${description.length * 5 + 16}px`,
              }}
              tooltipArrowClassname="!left-[4px]"
            >
              <TooltipIcon />
            </Tooltip>
          )}
        </div>
      </td>
      {fiscal_period_order.map((periodString) => {
        const periodAmount = amount[periodString];

        const formats = formatTypes.map((formatTypeWithParameters) => {
          const [formatType, parameters = []] =
            formatTypeWithParameters.split(":");
          let formatFunction = FORMAT_NUMBER_FORMULA_MAP[formatType];

          if (formatFunction) {
            formatFunction = formatFunction.bind(null, ...parameters);
          }

          return formatFunction;
        });
        const appliedModifierAmount = periodAmount ? applyModifier(periodAmount, modifier) : periodAmount;
        const formattedAmount = appliedModifierAmount ? formatMultiple(formats, appliedModifierAmount) || appliedModifierAmount : '-';

        return (
          <td
            className="bg-white !p-0 2xl:max-w-[120px] lg:max-w-[100px] break-words"
            key={periodString}
          >
            <div className="!px-[0.75rem] h-full align-middle !text-[0.8125rem] items-center flex justify-end text-neutral-strong leading-[1.25rem] tracking-[-0.28px] font-[400] w-full 2xl:max-w-[120px] lg:max-w-[100px] text-right">
              {formattedAmount || "-"}
            </div>
          </td>
        );
      })}
      {additionalFiscalPeriodOrders.map((periodString) => {
        const periodAmount = amount[periodString];

        const formats = formatTypes.map((formatTypeWithParameters) => {
          const [formatType, parameters = []] =
            formatTypeWithParameters.split(":");
          let formatFunction = FORMAT_NUMBER_FORMULA_MAP[formatType];

          if (formatFunction) {
            formatFunction = formatFunction.bind(null, ...parameters);
          }

          return formatFunction;
        });
        const appliedModifierAmount = periodAmount ? applyModifier(periodAmount, modifier) : periodAmount;
        const formattedAmount = appliedModifierAmount ? formatMultiple(formats, appliedModifierAmount) || appliedModifierAmount : '-';

        return (
          <td
            className="bg-white !p-0 2xl:max-w-[120px] lg:max-w-[100px] break-words"
            key={periodString}
          >
            <div className="!px-[0.75rem] h-full align-middle !text-[0.8125rem] items-center flex justify-end text-neutral-strong leading-[1.25rem] tracking-[-0.28px] font-[400] w-full 2xl:max-w-[120px] lg:max-w-[100px] text-right">
              {formattedAmount || "-"}
            </div>
          </td>
        );
      })}
    </>
  ),
});
