import React from "react";
import PropTypes from "prop-types";

import Modal from "../Modal";
import { API_USER_ME } from "../../constants/url";
import useAuthenticatedFetch from "../../hooks/useAuthenticatedFetch";


TokenExpiryModal.propTypes = {
  showExpiryModal: PropTypes.bool,
  remainingTime: PropTypes.element,
};

export default function TokenExpiryModal({ showExpiryModal, remainingTime }) {
  const authenticatedFetch = useAuthenticatedFetch()
  const fetchUserData = async () => {
    return authenticatedFetch(API_USER_ME, {
      withCredentials: true,
    })
  }

  if (!showExpiryModal) {
    return null;
  }

  return (
    <Modal
      confirmText="I'm still here!"
      className="!pt-5"
      onConfirm={fetchUserData}
      additionalContent={
        <div className="text-[#525252] text-[1.125rem] leading-[1.75rem] text-center">
          You will be logged out in {remainingTime} seconds due to inactivity. <br />
          Click the button below to remain logged in.
        </div>
      }
    />
  );
};

