import { Viewer, Worker } from "@react-pdf-viewer/core";
import {
  SelectionMode,
  selectionModePlugin,
} from "@react-pdf-viewer/selection-mode";
import { zoomPlugin } from "@react-pdf-viewer/zoom";

// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import { GlobalWorkerOptions } from "pdfjs-dist";
import PropTypes from "prop-types";
import dragIcon from "../../assets/icons/icon_drag.svg";
import zoomInIcon from "../../assets/icons/icon_pdf_preview_zoom_in.svg";
import zoomOutIcon from "../../assets/icons/icon_pdf_preview_zoom_out.svg";
import { Fragment, useEffect, useImperativeHandle, useRef, useState } from "react";
import withForwardedRef from "../../hoc/withForwardedRef";
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { ReactComponent as ArrowDropdownIcon } from "../../assets/icons/arrow_drop_down.svg";
import scrollToPosition from "../../utils/scroll/scrollToPosition";
import DefaultPdfPreviewErrorView from "./error";

GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js";

const PdfPreview = withForwardedRef(({
  fileUrl,
  onPageLoadSuccess = ({ items: _items, styles: _styles }) => {},
  onPageLoad,
  forwardedRef: ref,
  minPage = 1,
  maximumPage = undefined,
  initialPage = 1,
  buttons = [],
  onPageLoadError,
  onZoom,
  defaultHeight,
}) => {
  const pdfRef = useRef();
  const [maxPage, setMaxPage] = useState(maximumPage)
  const [isFileLoaded, setIsFileLoaded] = useState(false)
  const [page, setPage] = useState(initialPage)
  const [height, setHeight] = useState()
  const isScrollingRef = useRef(false)
  const [pendingScrollToPage, setPendingScrollToPage] = useState()

  const handlePageLoadSuccess =  (...params) => {
    onPageLoadSuccess?.(...params)
    onPageLoad?.()
  }

  const getPages = (pageNumber = -1) => {
    const pages = pdfRef.current.querySelectorAll(
      ".rpv-core__inner-page"
    )

    if (pageNumber < 0) {
      return pages
    }

    return pages[pageNumber]
  }

  useEffect(() => {
    if (!maxPage) {
      const pages = Array.from(getPages())
      setMaxPage(pages.length)
    }
  },[isFileLoaded, maxPage])

  const handlePageInputChange = (event) => {
    let inputValue = parseInt(event.target.value, 10)

    if (!inputValue) {
      inputValue = ''
    }
    const limitedValue = Math.min(maxPage, inputValue);
    setPage(limitedValue);
  }

  const scrollToPage = (pageNumber, offset = 0) => {
    const initialPageDiv = pdfRef.current.querySelectorAll(
      ".rpv-core__inner-page"
    )[pageNumber - 1];

    if (initialPageDiv) {
      isScrollingRef.current = true
      const fileRect = pdfRef.current.getBoundingClientRect();
      const elementRect = initialPageDiv.getBoundingClientRect();

      const scrollOffset =
        elementRect.top - fileRect.top + pdfRef.current.scrollTop;

      scrollToPosition(pdfRef, scrollOffset + offset).then(() => {
        isScrollingRef.current = false;
      })
    }
  };

  const setAndScrollToPage = (pageNumber) => {
    setPage(pageNumber);
    scrollToPage(pageNumber)
  }

  const zoomPluginInstance = zoomPlugin({
    enableShortcuts: true,
  });

  const selectionModePluginInstance = selectionModePlugin();
  const { SwitchSelectionMode } = selectionModePluginInstance;
  const { ZoomIn, ZoomOut } = zoomPluginInstance;


  const handlePageChange = ({ currentPage: currentPageIndex, doc }) => 
    doc.getPage(currentPageIndex + 1).then((pdfPage) => {
      return pdfPage.getTextContent().then((textContent) => {
        // need to set timeout, otherwise query selector all will return empty for some reason
        const isScrolling = isScrollingRef.current
         
        if (isScrolling && (currentPageIndex + 1) !== page) {
          return
        }
        handlePageLoadSuccess(textContent, pdfPage.view, currentPageIndex);
        const [, , , height] = pdfPage.view
        setHeight(height)
        setIsFileLoaded(true)

        // if not scrolling
        if (!isScrolling){
          setPage(currentPageIndex + 1)
        }
      });
    });

  useImperativeHandle(ref, () => ({
    scrollToPage: setAndScrollToPage,
    ref: pdfRef,
    getPages,
  }))

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      const newPage = parseInt(e.target.value, 10); // Get the value directly from the input
      scrollToPage(newPage);
    }
  };

  const goToPreviousPage = () => {
    setPage(page - 1)
    scrollToPage(page - 1)
  }

  const goToNextPage = () => {
    setPage(page + 1)
    scrollToPage(page + 1)
  }

  useEffect(() => {
    if (pendingScrollToPage) {
      setAndScrollToPage(pendingScrollToPage)
      setPendingScrollToPage()
    }
  },[pendingScrollToPage])

  return (
    <Fragment>
      <div className="sticky flex flex-col top-6 justify-center relative items-center h-1 text-sm pb-3">
        <div className="flex flex-row items-center">
          {page !== minPage && (
            <button
              className="rotate-90 cursor-pointer"
              onClick={goToPreviousPage}
            >
              <ArrowDropdownIcon color="#121212" />
            </button>
          )}
          <input
            type="text"
            max={maxPage}
            value={page}
            onChange={handlePageInputChange}
            onBlur={(e) => scrollToPage(parseInt(e.target.value, 10))}
            onKeyDown={handleKeyDown}
            className="w-10 mr-2 h-1 py-2 px-2"
          />
          <div className="py-2 px-0">
            / {maxPage}
          </div>
          {page !== maxPage && (
            <button
              className="-rotate-90 cursor-pointer"
              onClick={goToNextPage}
            >
              <ArrowDropdownIcon color="#121212" />
            </button>
          )}
        </div>
      </div>
      <div
        className="relative transition-all duration-500 overflow-y-scroll"
        ref={pdfRef}
        style={{
          height: defaultHeight || height + 50 || undefined,
        }}
      >
        <Worker workerUrl="/pdf.worker.min.js">
          <div className="sticky flex flex-col top-0 right-0 z-10 h-[2.25rem]">
            <div className="flex flex-col gap-[1rem] self-end pr-[1rem]">
              <ZoomIn>
                {(props) => (
                  <button onClick={(event) => {
                    props.onClick(event)
                    setPendingScrollToPage(page)
                    onZoom?.()
                  }}>
                    <img
                      alt="zoom in"
                      className="w-[2.75rem] h-[2.75rem] p-[0.75rem] rounded-[50%] border-[1px] border-primary-200 bg-handle-pdf-bg cursor-pointer"
                      src={zoomInIcon}
                    />
                  </button>
                )}
              </ZoomIn>
              <ZoomOut>
                {(props) => (
                  <button onClick={(event) => {
                    props.onClick(event)
                    setPendingScrollToPage(page)
                    onZoom?.()
                  }}>
                    <img
                      alt="zoom out"
                      className="w-[2.75rem] h-[2.75rem] p-[0.75rem] rounded-[50%] border-[1px] border-primary-200 bg-handle-pdf-bg cursor-pointer"
                      src={zoomOutIcon}
                    />
                  </button>
                )}
              </ZoomOut>
              <SwitchSelectionMode mode={SelectionMode.Hand}>
                {(props) => (
                  <button onClick={props.onClick}>
                    <img
                      alt="drag"
                      className="w-[2.75rem] h-[2.75rem] p-[0.75rem] rounded-[50%] border-[1px] border-primary-200 bg-handle-pdf-bg cursor-pointer"
                      src={dragIcon}
                    />
                  </button>
                )}
              </SwitchSelectionMode>
              {
                buttons
              }
            </div>
          </div>
          <div
            style={{
              flex: 1,
              overflow: "hidden",
            }}
          >
            <Viewer
              characterMap={
                {
                  isCompressed: true,
                  url: "/cmaps/"
                }
              }
              fileUrl={fileUrl}
              plugins={[
                zoomPluginInstance,
                selectionModePluginInstance,
              ]}
              onPageChange={handlePageChange}
              initialPage={initialPage - 1}
              renderError={(error) => <DefaultPdfPreviewErrorView error={error} onPageLoadError={onPageLoadError} onPageLoad={onPageLoad} />}
            />
          </div>
        </Worker>
      </div>
    </Fragment>
  );
})

PdfPreview.propTypes = {
  fileUrl: PropTypes.string,
  onPageLoad: PropTypes.func,
};

export default PdfPreview
