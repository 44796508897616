import { useEffect, useState } from "react";
import iconManageSearch from "../../assets/icons/icon_manage_search.svg";
import TableInput from "../../components/TableElements/Input";
import iconCancel from "../../assets/icons/icon_table_cancel.svg";
import { PROCESS_STATUSES } from "../../constants/statuses";
import FinancialSpreadingValidateTableRowChildTableName from "./FinancialSpreadingValidateTableRowChildTableName";
import FinancialSpreadingValidateTableRowChildContentRow from "./FinancialSpreadingValidateTableRowChildContentRow";

const FIELDS = {
  table_name: 'table_name',
  content: 'content'
}

export default function FinancialSpreadingValidateTableRowChild({
  fiscalPeriods = [],
  data,
  onCancel,
  onSearch,
  onSave,
  goToPage,
}) {
  const [pageNumbers, setPageNumbers] = useState("");
  const [selectedPageNumberIndex, setSelectedPageNumberIndex] = useState(-1);
  const [isEditing, setIsEditing] = useState({})
  const [rowData, setRowData] = useState({});


  useEffect(() => {
    setRowData((currentRowData) => {
      const { content: dataContent, ...otherData } = data

      const { content } = currentRowData

      const rowContent = content || []

      const newRowData = Object.keys(otherData).reduce((finalRowData, currentKey) => {
        let usedData = otherData[currentKey]

        if(isEditing[currentKey]) {
          usedData = currentRowData[currentKey]
        }

        return { ...finalRowData, [currentKey]: usedData }
      }, {})

      const contentIsEditing = isEditing[FIELDS.content] || {}

      const isEditingContent = Object.keys(contentIsEditing).some((index) => contentIsEditing[index])

      let usedContent = dataContent

      if (isEditingContent) {
        usedContent = rowContent
      }

      newRowData.content = usedContent

      return newRowData
    });
  }, [data, isEditing]);

  const onAddContentRow = (contentIndex) => {
    setRowData((currentData) => {
      const { content = [] } = currentData
      const newContent = [...(content || [])]
      const emptyRow = {
        name: "",
        amount: fiscalPeriods.reduce(
          (acc, period) => ({ ...acc, [period]: 0 }),
          {}
        ),
        add_time: Date.now(),
      };
      newContent.splice(contentIndex + 1, 0, emptyRow);
      const newData = { ...currentData, content: newContent };

      return newData;
    });

    setIsEditing((currentIsEditing) => {
      const currentContentIsEditing = currentIsEditing[FIELDS.content] || {}

      const newEditingRows = Object.keys(currentContentIsEditing).reduce(
        (newEditingRows, currentIndex) => {
          const currentEditRow = currentContentIsEditing[currentIndex];

          const newIndex =
            currentIndex > contentIndex ? parseInt(currentIndex) + 1 : currentIndex;

          return {
            ...newEditingRows,
            [newIndex]: currentEditRow,
          };
        },
        {}
      );

      newEditingRows[contentIndex + 1] = true

      return {
        ...currentIsEditing,
        [FIELDS.content]: newEditingRows,
      }
    })
  }


  const setTableNameEdited = (isEditing) => {
    setIsEditing((currentIsEditing) => ({
      ...currentIsEditing,
      [FIELDS.table_name]: isEditing,
    }))
  }

  const setContentsEdited = (index, isEditing) => {
    setIsEditing((currentIsEditing) => {
      const currentContentIsEditing = currentIsEditing[FIELDS.content] || {}
      currentContentIsEditing[index] = isEditing

      return {
        ...currentIsEditing,
        [FIELDS.content]: currentContentIsEditing,
      }
    })
  }

  const onTableNameChange = (tableName) => {
    setRowData((currentRowData) => {
      const newData = {
        ...currentRowData,
        table_name: tableName
      }
      onSave(newData)

      return newData
    })
   

  }

  const onContentsChange = (contentIndex, newContentDatum) => {
    setRowData((currentRowData) => {
      const newData = {
        ...currentRowData,
      }
  
      const { content = [] } = newData
      content[contentIndex] = newContentDatum
  
      newData.content = content
      onSave(newData)

      return newData
  
    })
    
  }

  const onDelete = (contentIndex, needSave = true) => {
    setRowData((currentRowData) => {
      const newData = {
        ...currentRowData
      }
  
      const { content = [] } = newData
  
  
      const newContent = content.filter((_, i) => i !== contentIndex)
      newData.content = newContent
  
      if (needSave)
        onSave(newData)

      return newData
    })
   
    setIsEditing((currentIsEditing) => {
      const currentContentIsEditing = currentIsEditing[FIELDS.content] || {}

      const newEditingRows = Object.keys(currentContentIsEditing).reduce(
        (newEditingRows, currentIndex) => {
          if (parseInt(currentIndex) === contentIndex) {
            return newEditingRows
          }
          const currentEditRow = currentContentIsEditing[currentIndex];

          const newIndex =
            currentIndex > contentIndex ? parseInt(currentIndex) - 1 : currentIndex;

          return {
            ...newEditingRows,
            [newIndex]: currentEditRow,
          };
        },
        {
            [contentIndex]: currentIsEditing[contentIndex + 1]
        }
      );

      return {
        ...currentIsEditing,
        [FIELDS.content]: newEditingRows,
      }
    })
  }

  const { table_name, content: rowDataContent, page_idxs } = rowData

  const content = rowDataContent || []
  const { status, metadata: { page_numbers } = {} } = rowData

  const handleGoToPage = () => {
    if (!page_idxs?.length) {
      return
    }

    const nextIndex = (selectedPageNumberIndex + 1) % page_idxs.length
    const nextPageNumber = page_idxs[nextIndex] + 1

    setSelectedPageNumberIndex(nextIndex)

    goToPage?.(nextPageNumber)
  }

  if (!table_name) {
    // only for notes in progress
    return (
      <tr>
        <td
          colSpan={2 + fiscalPeriods.length}
          className="bg-primary-50 sticky left-0 !h-[35px] py-0 !px-[0.75rem] !pr-8 !text-[0.8125rem] !leading-[1rem] "
        >
          {status === PROCESS_STATUSES.STARTED ? (
            <div className="text-[0.75rem] italic">
              Please wait while we extract the relevant numbers from the pages {page_numbers}.
            </div>
          ) : (
            <TableInput
              onChange={(e) => {
                const filteredValue = e.target.value.replace(/[^0-9,-]/g, "");
                setPageNumbers(filteredValue)
              }}
              placeholder="Enter top page number of the relevant notes (not on document), e.g., 1, 2, or 3-5."
              defaultValue={page_numbers}
              disabled={!!page_numbers}
            />
          )}
        </td>
        <td className="bg-primary-50 sticky right-0 w-[6.25rem] !h-[35px] !py-0 !px-[0.75rem]">
          {status === PROCESS_STATUSES.STARTED ? (
            <div className="mx-auto animate-spin rounded-full h-[21.6px] w-[21.6px] border-[3px] border-primary-1 border-t-white" />
          ) : (
            <div className="flex justify-center gap-[0.625rem] items-center">
              <button onClick={() => onSearch?.(pageNumbers)}>
                <img
                  src={iconManageSearch}
                  className="w-[20px] cursor-pointer"
                  title="Search"
                  alt="Search"
                />
              </button>
              <img
                onClick={onCancel}
                src={iconCancel}
                className="w-[20px] cursor-pointer"
                alt="Cancel"
                title="Cancel"
              />
            </div>
          )}
        </td>
      </tr>
    );
  }

  return (
    <>
      <FinancialSpreadingValidateTableRowChildTableName 
        isEditing={isEditing[FIELDS.table_name] !== undefined}
        colSpan={2 + fiscalPeriods.length}
        tableName={table_name}
        goToPage={handleGoToPage}
        onRowClick={() => setTableNameEdited(true)}
        onSave={(newTableName) => {
          onTableNameChange(newTableName)
          setTableNameEdited(undefined)
        }}
        onCancelEdit={() => setTableNameEdited(undefined)}
        onDeleteTable={onCancel}
      />
      {content.map((data, index) => {
        return (
          <FinancialSpreadingValidateTableRowChildContentRow
            isEditing={isEditing[FIELDS.content]?.[index] !== undefined}
            onStartEdit={() => setContentsEdited(index, false)}
            onSave={(newContentsDatum) => {
              onContentsChange(index, newContentsDatum)
              setContentsEdited(index, undefined)
            }}
            data={data}
            onCancelEdit={() => {
              const { content = {}} = isEditing

              if (content[index]) {
                onDelete(index, false)
                return
              }

              setContentsEdited(index, undefined)
            }}
            fiscalPeriods={fiscalPeriods}
            onDelete={() => onDelete(index)}
            onAddRow={() => onAddContentRow(index)}
            key={`${data.add_time || data.name}_${index}`}
          />
        );
      })}
    </>
  );
}