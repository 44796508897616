import TableInput from "../../components/TableElements/Input";
import TableSelect from "../../components/TableElements/Select";
import { VALIDATE_PAGE_FIELDS } from "../../constants";
import iconSave from "../../assets/icons/icon_table_save.svg";
import iconTrash from "../../assets/icons/icon_table_trash.svg";
import iconAdditionOrSubtraction from "../../assets/icons/icon_table_addition_or_subtraction.svg";
import iconCancel from "../../assets/icons/icon_table_cancel.svg";
import formatNumber from "../../utils/number/formatNumber";
import Tooltip from "../../components/Tooltip";

export default function FinancialSpreadingValidateTableRow({
  isEditing,
  child,
  fiscalPeriods,
  onChange,
  data,
  standardAccountOptions,
  isLastTable,
  onAmountChange,
  onEditCancel,
  onSaveEdit,
  onStartEdit,
  actions = [],
  index,
}) {
  if (isEditing) {
    return (
      <>
        <tr>
          <td className="bg-primary-50 sticky left-0 !h-[35px] !p-0 !px-[0.75rem] !text-[0.8125rem] !leading-[1rem] ">
            <div className="max-w-[200px]">
              <TableInput
                id={VALIDATE_PAGE_FIELDS.client_account_name}
                className="w-full"
                value={data[VALIDATE_PAGE_FIELDS.client_account_name] || ""}
                onChange={(e) =>
                  onChange(
                    VALIDATE_PAGE_FIELDS.client_account_name,
                    e.target.value
                  )
                }
              />
            </div>
          </td>
          <td className="sticky bg-primary-50 xl:left-[120px] 2xl:left-[200px] !h-[35px] !p-0">
            <div className="w-full !px-[0.75rem] !text-[0.8125rem]">
              <TableSelect
                id={VALIDATE_PAGE_FIELDS.account_code}
                defaultValue={data[VALIDATE_PAGE_FIELDS.account_code]}
                data={standardAccountOptions}
                className="w-full"
                onChange={(newAccountCode, key) => {
                  onChange(key, newAccountCode);
                  const newTpStandardAccountName = standardAccountOptions.find(
                    ({ value }) => value === newAccountCode
                  )?.label;

                  onChange(
                    VALIDATE_PAGE_FIELDS.tp_standard_account_name,
                    newTpStandardAccountName
                  );
                }}
                readOnly
                popoverClassname={`!min-w-[230px] ${
                  isLastTable ? "bottom-[18.5px]" : ""
                }`}
                dropdownDataClassname="!px-[0.25rem] !py-[0.15625rem] !leading-[0.75rem] !text-[0.75rem]"
                inputClassName="cursor-pointer"
              />
            </div>
          </td>
          {fiscalPeriods.map((fiscalPeriod) => (
            <td
              className="bg-primary-50 !h-[35px] !p-0 w-[6.25rem]"
              key={fiscalPeriod}
            >
              <div className="!pl-[0.75rem] !pr-[26px] !text-[0.8125rem]">
                <TableInput
                  className="w-full"
                  onChange={(event) =>
                    onAmountChange(fiscalPeriod, event.target.value)
                  }
                  value={data.amount?.[fiscalPeriod] || ""}
                />
              </div>
            </td>
          ))}
          <td className="bg-primary-50 sticky right-0 w-[6.25rem] !h-[35px] !py-0 !px-[0.75rem]">
            <div className="flex justify-center gap-[0.625rem] items-center">
              <button onClick={onSaveEdit}>
                <img
                  src={iconSave}
                  className="w-[20px] cursor-pointer"
                  title="Save"
                  alt="Save"
                />
              </button>
              <img
                onClick={onEditCancel}
                src={iconCancel}
                className="w-[20px] cursor-pointer"
                alt="Cancel"
                title="Cancel"
              />
            </div>
          </td>
        </tr>
        {child}
      </>
    );
  }

  return (
    <>
      <tr className="transition-all duration-200 ease-in-out">
        <td
          className="bg-white sticky left-0 !h-[35px] !py-0 !px-[0.75rem] !text-[0.8125rem] !leading-[1rem] xl:w-[120px] 2xl:w-[200px]"
          onClick={onStartEdit}
        >
          <div className="flex justify-start text-neutral-strong leading-[1.25rem] tracking-[-0.28px] font-[400] w-full">
            {data[VALIDATE_PAGE_FIELDS.client_account_name]}
          </div>
        </td>
        <td
          className="bg-white sticky xl:left-[120px] 2xl:left-[200px] !h-[35px] !p-0 xl:w-[120px] 2xl:w-[200px]"
          onClick={onStartEdit}
        >
          <div className="flex justify-start text-neutral-strong !px-[0.75rem] !text-[0.8125rem] leading-[1.25rem] tracking-[-0.28px] font-[400] w-full">
            {data[VALIDATE_PAGE_FIELDS.tp_standard_account_name]}
          </div>
        </td>
        {fiscalPeriods.map((fiscalPeriod) => (
          <td
            className="bg-white !h-[35px] !p-0 w-[6.25rem] max-w-[6.25rem]"
            onClick={onStartEdit}
            key={fiscalPeriod}
          >
            <div
              className={`!pl-[0.75rem] !pr-[26px] !text-[0.8125rem] flex justify-end text-neutral-strong leading-[1.25rem] tracking-[-0.28px] font-[400]`}
            >
              {formatNumber(
                data[VALIDATE_PAGE_FIELDS.amount]?.[fiscalPeriod]
              ) || "-"}
            </div>
          </td>
        ))}
        {actions.length > 0 && (
          <td
            id="action"
            className="bg-white sticky right-0 w-[6.25rem] !h-[35px] !py-0 !px-[0.75rem]"
          >
            <div className="flex justify-center gap-[0.625rem] items-center">
              {actions.map(({ tooltip, className, Component }) => (
                <Tooltip
                  tooltip={tooltip}
                  tooltipClassname={className}
                  topTooltip
                >
                  <Component index={index} />{" "}
                </Tooltip>
              ))}
            </div>
          </td>
        )}
      </tr>
      {child}
    </>
  );
}
