import { useState } from "react";
import { VALIDATE_PAGE_FIELDS } from "../../constants";
import iconTrash from "../../assets/icons/icon_table_trash.svg";
import iconAdditionOrSubtraction from "../../assets/icons/icon_table_addition_or_subtraction.svg";
import { Popover } from "react-tiny-popover";
import iconPlusGreen from "../../assets/icons/icon_table_green_plus.svg";
import FinancialSpreadingValidateTableRow from "./FinancialSpreadingValidateTableRow";

const defaultData = {
  [VALIDATE_PAGE_FIELDS.client_account_name]: '',
  [VALIDATE_PAGE_FIELDS.tp_standard_account_name]: '',
  [VALIDATE_PAGE_FIELDS.amount]: {},
  [VALIDATE_PAGE_FIELDS.account_code]: '',
}

export default function FinancialSpreadingValidateTableEmptySection({
  standardAccountOptions,
  isLastTable,
  fiscalPeriods,
  onSaveRow,
  baseRowData = {},
}) {
  const [isEditing, setIsEditing] = useState([])
  const [data, setData] = useState([defaultData])
  const [isAddingRowIndex, setIsAddingRowIndex] = useState(-1)

  const handleAddRow = (index) => {
    setData((currentData) => {
      const newData = [...currentData];
      const emptyRow = {
        ...baseRowData,
        client_account_name: "",
        tp_standard_account_name: "",
        account_code: "",
        add_time: Date.now(),
        amount: {},
      }
      newData.splice(index + 1, 0, emptyRow);

      return newData;
    });
  }

  const handleSetEdit = (index, isEditing) => {
    setIsEditing((currentIsEditing) => {
      const newIsEditing = [...currentIsEditing]
      newIsEditing[index] = isEditing
      return newIsEditing
    })
  }

  const handleDeleteRow = (index) => {
    setData((currentData) => {
      if (currentData.length === 1) {
        return [defaultData]
      }

      const newData = [...currentData].filter((_, i) => i !== index);
      
      return newData;
    });
  }

  const handleAddClick = (index) => {
    setIsAddingRowIndex(index)
  }


  const addRowOptions = [
    {
      label: "Add Row",
      onClick: handleAddRow,
    },
  ]

  const actions = [
    {
      tooltip: "Invert number sign (+/-)",
      className:
        "flex justify-center items-center max-w-[240px] p-1.5 px-2.5 py-1 bg-white bg-opacity-80 rounded !max-w-[240px] !w-[6.25rem] !right-0 !-translate-x-[80%]",
      Component: () => (
        <button>
          <img
            src={iconAdditionOrSubtraction}
            className="w-[20px] cursor-pointer"
            alt="Invert value"
          />
        </button>
      ),
    },
      {
        tooltip: "Add row below",
        className:
          "flex justify-center items-center max-w-[240px] p-1.5 px-2.5 py-1 bg-white bg-opacity-80 rounded !max-w-[240px] !w-[83px] !right-0 !-translate-x-[80%]",
        Component: ({ index }) => (
          <Popover
            isOpen={index === isAddingRowIndex}
            positions={["bottom", "top", "left", "right"]} // preferred positions by priority
            onClickOutside={() => {
              setIsAddingRowIndex()
            }}
            content={
              <div
                className={`absolute z-[22] left-[-38px] rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none min-w-[40px]`}
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
                tabIndex="-1"
              >
                <div
                  className={`py-[8px] max-h-[200px] min-w-[80px] overflow-y-auto`}
                  role="none"
                >
                  {
                    addRowOptions.map(({ label, onClick }) => (
                      <span
                        className={`block px-[0.75rem] py-[0.5rem] text-[0.8125rem] font-[500] leading-[1rem] tracking-[-0.28px] hover:bg-neutral-flat-hover cursor-pointer`}
                        role="menuitem"
                        tabIndex="-1"
                        id="menu-item-0"
                        onClick={(event) => {
                          event.stopPropagation();
                          onClick(index);
                          setIsAddingRowIndex();
                        }}
                      >
                        {label}
                      </span>
                    ))
                  }
                </div>
              </div>
            }
          >
            <button onClick={(event) => {
              event.stopPropagation()
              handleAddClick(index)
            }}>
              <img
                src={iconPlusGreen}
                className="w-[20px] cursor-pointer"
                alt="Add New Row"
              />
            </button>
          </Popover>
        ),
      },
    {
      tooltip: "Delete this row",
      className:
        "flex justify-center items-center max-w-[240px] p-1.5 px-2.5 py-1 bg-white bg-opacity-80 rounded !max-w-[240px] !w-[85px] !right-0 !-translate-x-[80%]",
      Component: ({ index }) => (
        <button onClick={handleDeleteRow.bind(null, index)}>
          <img
            src={iconTrash}
            className="w-[20px] cursor-pointer"
            alt="Delete row"
          />
        </button>
      ),
    },
  ].filter(Boolean);


  const handleSaveRow = () => {
    onSaveRow(data)
    setData([defaultData])
    setIsEditing([])
    setIsAddingRowIndex(-1)
  }

  const onChange = (index, fieldName, value) => {
    setData((currentData) => {
      const currentRowData = currentData[index] || {};

      const newRowData = {
        ...currentRowData,
        [fieldName]: value,
      };

      const newData = [...currentData];
      newData[index] = newRowData;
      return newData;
    })
  }

  const onAmountChange = (index, periodString, value) => {
    setData((currentData) => {
      const currentRowData = currentData[index] || {};
      const currentAmountData = currentRowData[VALIDATE_PAGE_FIELDS.amount] || {};

      const newRowData = {
        ...currentRowData,
        [VALIDATE_PAGE_FIELDS.amount]: {
          ...currentAmountData,
          [periodString]: parseFloat(value),
        },
      };

      const newData = [...currentData];
      newData[index] = newRowData;
      return newData;
    })
  }

  return (
    data.map((datum, index) => {
      return (
        <FinancialSpreadingValidateTableRow
          fiscalPeriods={fiscalPeriods}
          data={datum}
          onEditCancel={() => handleSetEdit(index, false)}
          isEditing={isEditing[index]}
          onChange={onChange.bind(null, index)}
          standardAccountOptions={standardAccountOptions}
          isLastTable={isLastTable}
          onAmountChange={onAmountChange.bind(null, index)}
          onStartEdit={() => handleSetEdit(index, true)}
          onSaveEdit={() => handleSaveRow()}
          index={index}
          actions={actions}
        />
      )
    })
  )
}
