export default function generateHighlightDiv ({ width, height, left = 0, top = 0, id, heightBuffer = 0.3 }) {
  const paddedHeight = height * heightBuffer; 

  const highlightDiv = document.createElement("span");
  highlightDiv.style.position = "absolute";
  highlightDiv.id = id
  highlightDiv.className =
    "border-2 border-solid border-blue-secondary bg-blue-secondary";
  highlightDiv.style.padding = `${paddedHeight}px`;
  highlightDiv.style.left = `${left - paddedHeight}px`;
  highlightDiv.style.top = `${top - paddedHeight}px`;
  highlightDiv.style.width = `${width + paddedHeight * 3}px`;

  highlightDiv.style.height = `${height + paddedHeight}px`;
  highlightDiv.style.display = "none";

  return highlightDiv;
};