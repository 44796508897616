import { createContext, useContext, useState } from "react";

const TokenExpiryContext = createContext({
  tokenExpiryTime: null,
  setTokenExpiryTime: () => {},
});

export const useTokenExpiry = () => useContext(TokenExpiryContext);

export default function TokenExpiryProvider({ children }) {
  const [tokenExpiryTime, setTokenExpiryTime] = useState(null);

  return (
    <TokenExpiryContext.Provider value={{ tokenExpiryTime, setTokenExpiryTime }}>
      {children}
    </TokenExpiryContext.Provider>
  );
}
