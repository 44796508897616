import ExcelJS from 'exceljs'
import { ALL_DOCUMENT_TYPES, DOCUMENT_TYPE_GENERATED, DOCUMENT_TYPE_UPLOADED, UNAUDITED_VALUE } from '../../constants'
import extractPeriodAndDate from '../string/extractPeriodAndDate'
import groupDataByDocumentType from '../formula/groupDataByDocumentType'
import mergeNumberFormat from '../excel/mergeNumberFormat'
import { applyModifier } from '../formula/format_amount'

export default function generateWorkbook({
  group_name,
  company_name,
  doc_currency,
  out_currency,
  exchange_rate,
  unit,
  data,
  fiscalPeriodOrderMap,
  decimalPlace,
  periodStringMetadata,
}) {
  const companyInformationData = [
    [
      'Group Name', group_name,
    ],
    [
      'Company Name', company_name,
    ],
    [
      'Document Currency', doc_currency,
    ],
    [
      'Output Currency', out_currency,
    ],
    [
      'Exchange Rate', exchange_rate.toString(),
    ],
    [
      'Unit', unit,
    ]
  ]

  const DOCUMENT_DATA_MAP = data.reduce((documentDataMap, currentData) => {
    const { document_type } = currentData
    const currentDocumentData = documentDataMap[document_type] || []
    currentDocumentData.push(currentData)

    return {
      ...documentDataMap,
      [document_type]: currentDocumentData
    }

  }, {})

  const workbook = new ExcelJS.Workbook();
  ALL_DOCUMENT_TYPES.forEach(({ key, formula_type = key, text, hidePeriod }) => {
    const worksheet = workbook.addWorksheet(text, {
      views:[ {showGridLines:false}]
    });

    companyInformationData.forEach((companyInfo) => {
      const sheetRow = worksheet.addRow(companyInfo).getCell(1)
      sheetRow.font = { bold: true }
      sheetRow.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E8F4FF' }, 
      }
    })
    worksheet.addRow([])
    worksheet.addRow([])
  
    const { fiscalPeriodOrder, additionalFiscalPeriodOrders } = fiscalPeriodOrderMap[formula_type] || {}

    const extractedFiscalPeriods = fiscalPeriodOrder.map((periodString) => extractPeriodAndDate(periodString))
    const extractedAdditionalFiscalPeriods = additionalFiscalPeriodOrders.map((periodString) => extractPeriodAndDate(periodString))

    const sheetDateRow = worksheet.addRow([
      text,
      ...extractedFiscalPeriods.map(
        ({ month, year }) => `${month} ${year}`
      ),
      ...extractedAdditionalFiscalPeriods.map(
        ({ month, year }) => `${month} ${year}`
      ),
    ]);

    sheetDateRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'E8F4FF' },
    }

    sheetDateRow.font = {
      bold: true,
    }

    sheetDateRow.eachCell({}, (cell, colNo) => {
      if (colNo > 1) {
        cell.alignment = {
          horizontal: 'right',
        };
      }
    })

    if (!hidePeriod) {
      const sheetPeriodRow = worksheet.addRow([
        '',
        ...extractedFiscalPeriods.map(
          ({ period }) => `${period} months`
        ),
        ...extractedAdditionalFiscalPeriods.map(() => '')
      ])

      sheetPeriodRow.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E8F4FF' },
      }

      sheetPeriodRow.font = {
        bold: true,
      }

      sheetPeriodRow.eachCell({}, (cell, colNo) => {
        if (colNo > 1) {
          cell.alignment = {
            horizontal: 'right',
          };
        }
      })
    }
   
    const sheetAuditedRow = worksheet.addRow([
      "Standard Account Name",
      ...extractedFiscalPeriods.map(
        ({ audited = UNAUDITED_VALUE }) => audited
      ),
      ...extractedAdditionalFiscalPeriods.map(
        ({ audited = UNAUDITED_VALUE }) => audited
      ),
    ]);

    sheetAuditedRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'E8F4FF' },
    }

    sheetAuditedRow.font = {
      bold: true,
      size: 10,
    }

    sheetAuditedRow.eachCell({}, (cell, colNo) => {
      if (colNo > 1) {
        cell.alignment = {
          horizontal: 'right',
        };
      }
    })

    const sheetAuditorNameRow = worksheet.addRow([
      "",
      ...fiscalPeriodOrder.map(
        (periodString) => periodStringMetadata?.[periodString]?.auditor_name
      ),
    ]);

    sheetAuditorNameRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'E8F4FF' },
    }

    sheetAuditorNameRow.font = {
      bold: true,
      size: 10,
    }

    sheetAuditorNameRow.eachCell({}, (cell, colNo) => {
      if (colNo > 1) {
        cell.alignment = {
          horizontal: 'right',
        };
      }
    })
    
    
  });

  DOCUMENT_TYPE_UPLOADED.forEach(({ key, formula_type = key, text }) => {
    const worksheet = workbook.getWorksheet(text);

    const documentData = DOCUMENT_DATA_MAP[formula_type] || []
    const { fiscalPeriodOrder, additionalFiscalPeriodOrders } = fiscalPeriodOrderMap[formula_type] || {}
    const displayedFiscalPeriodOrder = [...fiscalPeriodOrder, ...additionalFiscalPeriodOrders]

    documentData.forEach(({ tp_standard_account_name, amount, bold, children }) => {
      const sheetRow = worksheet.addRow([
        tp_standard_account_name,
        ...displayedFiscalPeriodOrder.map((periodString) => (amount[periodString] || 0) / exchange_rate)
      ])

      sheetRow.eachCell({ includeEmpty: true }, (cell, colNumber) => {
        if (colNumber >= 2) {  
          let numberFormat = '#,##0'

          if (decimalPlace > 0) {
            numberFormat += `.${'0'.repeat(decimalPlace)}`
          }

          cell.numFmt = `${numberFormat};[Red](${numberFormat});"-"`;
        }

        if (bold) {
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },  // Black thin top border
          }
        }

        if (colNumber > 1) {
          cell.alignment = {
            horizontal: 'right',
          };
        }
      });

      if (children?.length) {
        children.forEach(({ client_account_name, amount, child }) => {
          const sheetRow = worksheet.addRow([
            client_account_name,
            ...displayedFiscalPeriodOrder.map((periodString) => (amount[periodString] || 0) / exchange_rate)
          ])
    
          sheetRow.eachCell({ includeEmpty: true }, (cell, colNumber) => {
            if (colNumber >= 2) {  
              let numberFormat = '#,##0'
    
              if (decimalPlace > 0) {
                numberFormat += `.${'0'.repeat(decimalPlace)}`
              }
    
              cell.numFmt = `${numberFormat};[Red](${numberFormat});"-"`;
            }
    
            if (colNumber > 1) {
              cell.alignment = {
                horizontal: 'right',
              };
            }
          });

          sheetRow.outlineLevel = 1
          sheetRow.hidden = true
          sheetRow.getCell(1).alignment = {
            indent: 2,
          }
          sheetRow.font = {
            bold: true,
          }

          sheetRow.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'E5E7EB' },
          }

          if (child?.length) {
            child.forEach(({ table_name, content }, index) => {
              const sheetRow = worksheet.addRow([
                table_name,
              ])

              sheetRow.font = {
                italic: true,
              }

              sheetRow.getCell(1).alignment = {
                indent: 4,
              }

              sheetRow.outlineLevel = 1
              sheetRow.hidden = true

              sheetRow.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'E5E7EB' },
              }

                for (let col = 1; col <= 1 + displayedFiscalPeriodOrder.length; col++) {
                  sheetRow.getCell(col).border = {
                    right: col === (displayedFiscalPeriodOrder.length + 1) && { style: "thin", color: { argb: "000000" } },
                    top: index === 0 && {
                      style: "thin",
                      color: { argb: "000000" },
                    },
                    bottom: (!content?.length && index === child.length - 1) && {
                      style: "thin",
                      color: { argb: "000000" },
                    },
                  };
              }

              if (content?.length) {
                content.forEach(({ name, amount }, contentIndex) => {
                  const sheetRow = worksheet.addRow([
                    name,
                    ...displayedFiscalPeriodOrder.map(
                      (periodString) =>
                        (amount[periodString] || 0) / exchange_rate
                    ),
                  ]);

                  sheetRow.getCell(1).alignment = {
                    indent: 6,
                  };

                  sheetRow.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'E5E7EB' },
                  }

                  sheetRow.outlineLevel = 1;
                  sheetRow.hidden = true

                  sheetRow.eachCell(
                    { includeEmpty: true },
                    (cell, colNumber) => {
                      if (colNumber >= 2) {
                        let numberFormat = "#,##0";

                        if (decimalPlace > 0) {
                          numberFormat += `.${"0".repeat(decimalPlace)}`;
                        }

                        cell.numFmt = `${numberFormat};[Red](${numberFormat});"-"`;
                      }

                      if (colNumber > 1) {
                        cell.alignment = {
                          horizontal: "right",
                        };
                      }

                      cell.border = {
                        right: colNumber === (displayedFiscalPeriodOrder.length + 1) && { style: "thin", color: { argb: "000000" } },
                        bottom: (index === child.length - 1 && contentIndex === content.length - 1) && {
                          style: "thin",
                          color: { argb: "000000" },
                        },
                      };
                    }
                  );
                });
              }

            
            })
          }
        })
      }
      
      if (bold) {
        sheetRow.font = {
          bold: true,
        }

        worksheet.addRow([])
      }
    })
  })

  // Generated document types, like key ratios, are grouped
  DOCUMENT_TYPE_GENERATED.forEach(({ key, formula_type = key, text }) => {
    const worksheet = workbook.getWorksheet(text);
    const documentData = DOCUMENT_DATA_MAP[formula_type] || []
    const { fiscalPeriodOrder, additionalFiscalPeriodOrders } = fiscalPeriodOrderMap[formula_type] || {}
    const displayedFiscalPeriodOrder = [...fiscalPeriodOrder, ...additionalFiscalPeriodOrders]

    const groupedDocumentData = groupDataByDocumentType(documentData, formula_type)

    groupedDocumentData.forEach(({ groupTitle, data}, index) => {
      if (index) {
        worksheet.addRow([])
      }

      worksheet.addRow([groupTitle]).font = {
        bold: true,
      }

      data.forEach(({ tp_standard_account_name, amount, formatTypes = [], modifier}) => {
        const amountRow = worksheet.addRow([
          tp_standard_account_name,
          ...displayedFiscalPeriodOrder.map((periodString) => applyModifier(amount[periodString], modifier) || 0)
        ])

        const processedNumberFormat = mergeNumberFormat('', formatTypes)

        amountRow.eachCell({ includeEmpty: true }, (cell, colNumber) => {
          if (colNumber >= 2) {  
            const formats = processedNumberFormat.split(';')

            let numFmt = processedNumberFormat

            if (formats.length === 1) {
              numFmt = `${processedNumberFormat};-${processedNumberFormat};"-"`;
            } else if (formats.length === 2) {
              numFmt = `${processedNumberFormat};"-"`;
            }

            cell.numFmt = numFmt;
              cell.alignment = {
                horizontal: 'right',
              };
          }
        });
      })  
    })

  })

  // make all sheet's width fit to content
  ALL_DOCUMENT_TYPES.forEach(({ text }) => {
    const worksheet = workbook.getWorksheet(text);

    worksheet.columns.forEach((column, columnNo) => {
      if (columnNo > 0) {
        // set fixed width to 20 for amount columns
        column.width = 20
        return
      }

      if (columnNo === 0)  {
        column.width = 30;
        return
      }

      // otherwise, set it to fit content
      let maxLength = 0;
      column.eachCell({ includeEmpty: false }, (cell) => {
        const cellValue = cell.value ? cell.value.toString() : "";
        maxLength = Math.max(maxLength, cellValue.length);
      });
      column.width = maxLength + 2; // Add some padding to the column width
    });
  })

  return workbook

}
