import { useEffect, useMemo, useRef, useState } from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useNavigate, useParams } from "react-router-dom";
import backIcon from "../../assets/icons/icon_back.svg";
import Preview from "../../components/Preview";
import Tabs from "../../components/Tabs";
import Tooltip from "../../components/Tooltip";
import {
  DOCUMENT_TYPE_ENUM,
  DOCUMENT_TYPE_UPLOADED,
  TOAST_TYPE,
} from "../../constants";
import { DEVICE_WIDTH } from "../../constants/dimension";
import { FORMULA_GROUPING, FORMULAS_MAP } from "../../constants/formula";
import {
  API_APPLICATIONS,
  API_SAVE_APPLICATION,
  URL_APPLICATION_CREATE,
  URL_APPLICATION_RESULT,
  API_GET_APPLICATION_FILE,
  API_VALIDATE_APPLICATION,
  API_APPLICATION_REPROCESS,
  URL_DASHBOARD,
  API_GET_APPLICATION_TRANSLATED_FILE,
  API_APPLICATION_PROCESSES,
  API_APPLICATION_NOTELINKING,
} from "../../constants/url";
import { useApplicationContext } from "../../context/Application";
import { useLoader } from "../../context/Loader";
import { useToaster } from "../../context/Toaster";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import debounce from "../../utils/api/debounce";
import calculateFormulaFromData from "../../utils/formula/calculate_formula_from_data";
import extractPeriodAndDate from "../../utils/string/extractPeriodAndDate";
import generatePeriodString from "../../utils/string/generatePeriodString";
import FinancialSpreadingValidateTable from "../FinancialSpreadingUploadValidate/FinancialSpreadingValidateTable";
import { ReactComponent as ResizeIcon } from "../../assets/icons/icon-resize.svg";
import { API_APPLICATION_STATUS, PROCESS_STATUSES } from "../../constants/statuses";
import useAuthenticatedFetch from "../../hooks/useAuthenticatedFetch";
import TextInput from "../../components/TextInput";
import loadOcrIntoHighlight from "../../utils/financialSpreading/loadOcrIntoHighlight";
import translateIcon from "../../assets/icons/icon_translate.svg";
import { LANGUAGE_ENGLISH } from "../../language";
import processOcrResults from "../../utils/formula/processOcrResults";
import { APPLICATION_PROCESS_TYPES } from "../../constants/types";
import { ReactComponent as ConfirmedIcon } from "../../assets/icons/icon_confirm.svg";
import { ReactComponent as ReprocessIcon } from "../../assets/icons/icon_reprocess_document.svg";
import FinancialSpreadingValidateTableModal from "../FinancialSpreadingUploadValidate/FinancialSpreadingValidateTableModal";
import { APPLICATION_VALIDATION_TAB_STATUSES } from "../FinancialSpreadingUploadValidate/statuses";

const INITIAL_CONFIRM_STATE = DOCUMENT_TYPE_UPLOADED.reduce(
  (finalConfirmState, { key }) => {
    const confirmState = {};
    const documentTypeGroups = FORMULA_GROUPING[key];
    documentTypeGroups.forEach(({ summary = [], name }) => {
      const groupConfirmState = confirmState[name] || {};
      summary.forEach(({ displayCode }) => {
        groupConfirmState[displayCode] = false;
      });

      confirmState[name] = groupConfirmState;
    });

    return {
      ...finalConfirmState,
      [key]: confirmState,
    };
  },
  {}
);

export default function FinancialSpreadingUploadValidate() {
  const { application_id } = useParams();
  const tableRef = useRef(null);
  const reprocessStatusPollingIntervalIdRef = useRef(null)


  const [data, setData] = useState([]);
  const [metadata, setMetadata] = useState({});
  const { setLoading } = useLoader();
  const { showToast } = useToaster();
  const navigate = useNavigate();
  const [pendingSave, setPendingSave] = useState(false);
  const originalFileRef = useRef()
  const [fileUrl, setFileUrl] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [reprocessStatuses, setReprocessStatuses] = useState({});
  const [notelinkingProcessedStatus, setNotelinkingProcessedStatus] = useState({});

  const translatedPdfFileRef = useRef()
  const [translatedFileUrl, setTranslatedFileUrl] = useState(null);
  const [translatedFileType, setTranslatedFileType] = useState(null);

  const [isFileLoaded, setIsFileLoaded] = useState(false);
  const fileRef = useRef(null);
  const translatedFileRef = useRef(null);
  // const [aggregatedTextContents, setAggregatedTextContents] = useState([]);
  // const highlightsRef = useRef([]);
  const [selectedRow, setSelectedRow] = useState();
  const [fileHeight, setFileHeight] = useState();
  const [fileWidth, setFileWidth] = useState();
  const [triggerReRender, setTriggerRender] = useState(0);
  const [confirmState, setConfirmState] = useState(INITIAL_CONFIRM_STATE);
  const [tabsLabel, setTabsLabel] = useState(DOCUMENT_TYPE_UPLOADED);
  const [tabsStatus, setTabsStatus] = useState(DOCUMENT_TYPE_UPLOADED.reduce((final, { key, text }) => {
    return {
      ...final,
      [key]: {
        key,
        status: null,
        text,
      }
    }
  }, {}));

  useEffect(() => {
    if (triggerReRender) {
      setIsFileLoaded(false)
    }
  }, [triggerReRender])

  const [editingRows, setEditingRows] = useState({});
  const abortControllerRef = useRef(null);
  const authenticatedFetch = useAuthenticatedFetch()
  const [ocrData, setOcrData] = useState({})
  const [translatedOcrData, setTranslatedOcrData] = useState()
  const ocrHighlightsRef = useRef([])
  const [loadedPages, setLoadedPages] = useState({})
  const [language, setLanguage] = useState()
  const [applicationData, setApplicationData] = useState()
  const [periodStringMetadata, setPeriodStringMetadata] = useState({});

  const handleResetConfirm = (documentType, groupName) => {
    setConfirmState((currentConfirmState) => {
      const documentTypeConfirmState = confirmState[documentType] || {};

      return {
        ...currentConfirmState,
        [documentType]: {
          ...documentTypeConfirmState,
          [groupName]: {},
        },
      };
    });
  };

  const handleConfirmStateUpdate = (documentType, groupName, displayCode) => {
    const documentTypeConfirmState = confirmState[documentType] || {};
    const groupConfirmState = documentTypeConfirmState[groupName] || {};
    const updatedConfirmState = {
      ...confirmState,
      [documentType]: {
        ...documentTypeConfirmState,
        [groupName]: {
          ...groupConfirmState,
          [displayCode]: true,
        },
      },
    };

    setConfirmState(updatedConfirmState);

    const allDocumentTypeConfirmed = Object.values(
      updatedConfirmState[documentType]
    ).every((section) => Object.values(section)[0]);

    if (allDocumentTypeConfirmed) {
      setTabsStatus((prev) => {
        const updatedTabsStatus = Object.keys(prev).reduce((final, key) => {
          const item = prev[key];

          if (key === documentType) {
            return {
              ...final,
              [key]: {
                ...item, 
                status: APPLICATION_VALIDATION_TAB_STATUSES.CONFIRMED,
              }
            }
          }

          return final
        }, prev)

        return updatedTabsStatus;
      });
    }
  };

  useEffect(() => {
    const selectedRef = ocrHighlightsRef.current[selectedRow] 
    ocrHighlightsRef.current.forEach((ref) => {
      ref.style.display = "none";
    });

    if (!selectedRef) {
      return;
    }
    selectedRef.style.display = "";
  }, [selectedRow]);

  const fetchApplication = (application_id, isManualFetch) => {
    setLoading(true)
    return authenticatedFetch(`${API_APPLICATIONS}/${application_id}`)
      .then(
        ({
          data: applicationData,
        }) => {
          const { data, metadata, status, ocr_results, language, translated_ocr_results, period_string_metadata } = applicationData
          data.sort((a, b) => a.row_number - b.row_number);
          setData(data);
          setMetadata(metadata);
          setLanguage((currentLanguage) => currentLanguage || language)
          setApplicationData(applicationData)
          setPeriodStringMetadata(period_string_metadata)


          if (ocr_results) {
            const ocrData = processOcrResults(ocr_results)

            setOcrData(ocrData)
          }

          if (translated_ocr_results) {
            const translatedOcrData = processOcrResults(translated_ocr_results)
            setTranslatedOcrData(translatedOcrData)
          }

        startFetchApplicationProcessStatusPoll(application_id)

          if (status === API_APPLICATION_STATUS.VALIDATED) {
            // set all confirm state to true if already validated
            setConfirmState(
              DOCUMENT_TYPE_UPLOADED.reduce((finalConfirmState, { key }) => {
                const confirmState = {};
                const documentTypeGroups = FORMULA_GROUPING[key];
                documentTypeGroups.forEach(({ summary = [], name }) => {
                  const groupConfirmState = confirmState[name] || {};
                  summary.forEach(({ displayCode }) => {
                    groupConfirmState[displayCode] = true;
                  });

                  confirmState[name] = groupConfirmState;
                });

                return {
                  ...finalConfirmState,
                  [key]: confirmState,
                };
              }, {})
            );

            setTabsStatus((prev) => Object.keys(prev).reduce((final, documentType) => {
              return {
                ...final,
                [documentType]: {
                  ...prev[documentType],
                  status: APPLICATION_VALIDATION_TAB_STATUSES.CONFIRMED,
                }
              }
            }, {}));

            return
          }
        }
      )
      .catch((err) => {
        setLoading(false);
        showToast(err.message, TOAST_TYPE.ERROR);
        throw err
      }).finally(() => {
        if (isManualFetch) {
          setLoading(false);
        }
      })
  }

  const fetchApplicationFileApi = (application_id) => {
    setIsFileLoaded(false)
    const getApplicationFileApi = API_GET_APPLICATION_FILE.replace(
      ":id",
      application_id
    );
    return authenticatedFetch(getApplicationFileApi, {
      responseType: 'blob',
    })
      .then((fileBlob) => {
        const fileType = fileBlob.type;
        const createdFileUrl = URL.createObjectURL(fileBlob);
        originalFileRef.current =  fileBlob
        setFileUrl(createdFileUrl);
        setFileType(fileType);
      })
      .catch((err) => {
        setLoading(false);
        console.error("error fetching application file:", err);
        throw err
      });
  }

  const fetchApplicationTranslatedFileApi = (application_id) => {
    const isActivelyLoadingTranslatedFile = language !== applicationData.language
    setIsFileLoaded(false)

    if (isActivelyLoadingTranslatedFile) {
      setLoading(true)
    }

    const getApplicationTranslatedFileApi = API_GET_APPLICATION_TRANSLATED_FILE.replace(
      ":id",
      application_id
    );
    authenticatedFetch(getApplicationTranslatedFileApi, {
      responseType: 'blob',
    })
      .then((fileBlob) => {
        const fileType = fileBlob.type;
        translatedPdfFileRef.current = fileBlob
        const createdFileUrl = URL.createObjectURL(fileBlob);
        setTranslatedFileUrl(createdFileUrl);
        setTranslatedFileType(fileType);
      })
      .catch((err) => {
        setLoading(false);
        console.error("error fetching application file:", err);
      }).finally(() => {
        if (isActivelyLoadingTranslatedFile) {
          setLoading(false)
        }
      });
  }

  const fetchApplicationProcesses = (application_id) => {
    const applicationProcessesApi = API_APPLICATION_PROCESSES.replace(
      ":id",
      application_id
    );

    return authenticatedFetch(applicationProcessesApi)
      .then(
        ({
          data: { processes = {}, ongoing_processes = {}, active_processes },
        }) => {
          const { [APPLICATION_PROCESS_TYPES.REPROCESS]: reprocessedProcessStatus = {} } = processes
          const { [APPLICATION_PROCESS_TYPES.NOTELINKING]: notelinkingProcessedStatus = [] } = ongoing_processes

          setReprocessStatuses(reprocessedProcessStatus) 
          setNotelinkingProcessedStatus((currentNoteLinkingProcessedStatus) => {
            const noteProcessedSuccessfully = []
            const noteProcessedFailed = []
            const noteProcessedDone = []

            const updatedNotelinkingProcessedStatusMap = notelinkingProcessedStatus.reduce((finalMap, currentNoteLinkingProcessedData) => {
              const { metadata = {}, status, process_id } = currentNoteLinkingProcessedData
  
              const { client_account_name, account_code } = metadata
  
              const key = `${client_account_name}_${account_code}`

              const prevGroupedNoteLinkingProcessedData = currentNoteLinkingProcessedStatus[key] || [] 

              const prevGroupedNoteLinkingProcessedDataIdMap = prevGroupedNoteLinkingProcessedData.reduce((final, prev) => {
                const { process_id } = prev

                return {
                  ...final,
                  [process_id]: prev
                }
              }, {})
  
              const currentGroupedNoteLinkingProcessedData = finalMap[key] || []

              if (status === PROCESS_STATUSES.STARTED) {
                currentGroupedNoteLinkingProcessedData.push(currentNoteLinkingProcessedData)
              }

              const { status: currentStatus } = prevGroupedNoteLinkingProcessedDataIdMap[process_id] || {}

              if (currentStatus === PROCESS_STATUSES.STARTED && status !== PROCESS_STATUSES.STARTED) {
                noteProcessedDone.push(currentNoteLinkingProcessedData)

                if (status === PROCESS_STATUSES.PROCESSED) {
                  noteProcessedSuccessfully.push(currentNoteLinkingProcessedData)
                }

                if (status === PROCESS_STATUSES.FAILED) {
                  noteProcessedFailed.push(currentNoteLinkingProcessedData)
                }
              }
  
              return {
                ...finalMap,
                [key]: currentGroupedNoteLinkingProcessedData,
              }
            }, {})


            if (noteProcessedDone.length) {
              fetchApplication(application_id, true);
            }

            if (noteProcessedSuccessfully.length) {
              showToast(
                `${noteProcessedSuccessfully.map(({ metadata: { client_account_name } = {} }) => client_account_name).filter(Boolean).join(", ")} notes added successfully!`,
                TOAST_TYPE.SUCCESS
              );
            }

            if (noteProcessedFailed.length) {
              showToast(
                `${noteProcessedFailed.map(({ metadata: { client_account_name } = {} }) => client_account_name).filter(Boolean).join(", ")} notes retrieval failed. Please check the pages submitted.`,
                TOAST_TYPE.ERROR,
                '', 
                0,
              );
            }

            return {
              ...currentNoteLinkingProcessedStatus,
              ...updatedNotelinkingProcessedStatusMap,
            }
          }) 

          return active_processes
        }
      )
      .catch((err) => {
        if (!err.message || ["AbortError", "CanceledError"].includes(err.name)) {
          return
        }
        showToast(err.message, TOAST_TYPE.ERROR);
        throw err
      })
  }

  const debouncedFetchApplicationProcesses = debounce(fetchApplicationProcesses, 500)


  const cancelFetchApplicationProcessStatusPoll = () => {
    clearInterval(reprocessStatusPollingIntervalIdRef.current);
    reprocessStatusPollingIntervalIdRef.current = null;
    debouncedFetchApplicationProcesses.cancel()
  }

  const startFetchApplicationProcessStatusPoll = (application_id, callback) => {
    if (!reprocessStatusPollingIntervalIdRef.current) {
      fetchApplicationProcesses(application_id).then((activeProcesses) => {
        callback?.()
        if (!activeProcesses.some(({ status }) => status === PROCESS_STATUSES.STARTED)) {
            return
        } 

        reprocessStatusPollingIntervalIdRef.current = setInterval(() => fetchApplicationProcesses(application_id).then((activeProcesses) => {
          if (!activeProcesses.some(({ status }) => status === PROCESS_STATUSES.STARTED)) {
            cancelFetchApplicationProcessStatusPoll()
          }
        }), 5000);
      })
     
    }
  }

  useEffect(() => {
    if (applicationData) {
      const { has_translation } = applicationData

      if (has_translation) {
        fetchApplicationTranslatedFileApi(application_id)
      }
    }
   
  }, [applicationData])

  useEffect(() => {
    setLoading(true);
    cancelFetchApplicationProcessStatusPoll()
    Promise.all(
      [
        fetchApplication(application_id), 
        fetchApplicationFileApi(application_id),
      ]
    ).catch(() => {
      navigate(URL_DASHBOARD)
    })
  }, [application_id]);

  useEffect(() => {
    setTabsStatus((prev) => {
      const documentReprocessSuccess = [];
      const documentReprocessDone = [];
      const documentReprocessFailed = [];

      const combinedProcesses = Object.keys({ ...reprocessStatuses, ...prev });
      const updatedStatus = combinedProcesses.reduce((final, documentType) => {
        const latestDocumentReprocessStatus = reprocessStatuses[documentType];

        const item = prev[documentType];
        const { status, text } = item || {};

        if (status === APPLICATION_VALIDATION_TAB_STATUSES.CONFIRMED) {
          return final;
        }

        if (status === APPLICATION_VALIDATION_TAB_STATUSES.APPLYING_REPROCESSING) {
          return final
        }

        if (
          latestDocumentReprocessStatus === PROCESS_STATUSES.STARTED
        ) {
          return {
            ...final,
            [documentType]: {
              ...item,
              status: APPLICATION_VALIDATION_TAB_STATUSES.LOADING,
            },
          };
        }

        const hasNoData = data.filter(({ document_type }) => document_type === documentType)
        .length === 0

        if (status !== APPLICATION_VALIDATION_TAB_STATUSES.FAILED && latestDocumentReprocessStatus === PROCESS_STATUSES.FAILED) {
          documentReprocessFailed.push(text);

          let failedStatus = APPLICATION_VALIDATION_TAB_STATUSES.FAILED

          if (hasNoData) {
            failedStatus = APPLICATION_VALIDATION_TAB_STATUSES.WARNING
          }

          return {
            ...final,
            [documentType]: {
              ...item,
              status: failedStatus,
            },
          };
        }

        // we collect all document types that are done processing
        if (
          status === APPLICATION_VALIDATION_TAB_STATUSES.LOADING &&
          latestDocumentReprocessStatus !== PROCESS_STATUSES.STARTED
        ) {
          documentReprocessDone.push(text);
          if (
            latestDocumentReprocessStatus ===
            PROCESS_STATUSES.PROCESSED
          ) {
            documentReprocessSuccess.push(text);
          }

          return {
            ...final,
            [documentType]: {
              ...item,
              status: null,
            },
          };
        }

        if (
          hasNoData
        ) {
          return {
            ...final,
            [documentType]: {
              ...item,
              status: APPLICATION_VALIDATION_TAB_STATUSES.WARNING,
            },
          };
        }

        return {
          ...final,
          [documentType]: {
            ...item,
            status: null,
          },
        };
      }, prev);

      if (documentReprocessDone.length) {
        fetchApplication(application_id, true);
      }

      if (documentReprocessSuccess.length) {
        showToast(
          `${documentReprocessSuccess.join(", ")} reprocessed successfully!`,
          TOAST_TYPE.SUCCESS
        );
      }

      if (documentReprocessFailed.length) {
        showToast(
          `${documentReprocessFailed.join(", ")} reprocessing failed. Please check the pages submitted.`,
          TOAST_TYPE.ERROR,
          '', 
          0,
        );
      }

      return updatedStatus;
    });
  }, [reprocessStatuses, application_id, data]);

  useEffect(() => {
    setTabsLabel((prev) => {
      const updatedLabel = prev.map((item) => {
        const { status } = tabsStatus[item.key] || {}
        let icon

        if (status === APPLICATION_VALIDATION_TAB_STATUSES.CONFIRMED) {
          icon = <ConfirmedIcon />
        }

        if (status === APPLICATION_VALIDATION_TAB_STATUSES.LOADING) {
          icon = <div className="animate-spin rounded-full h-[21.6px] w-[21.6px] border-[3px] border-primary-1 border-t-white" />
        }

        const warningIcon = <span className="text-red-500 text-xl font-bold top-0 mt-[-2px]">!</span>

        if (status === APPLICATION_VALIDATION_TAB_STATUSES.WARNING) {
          icon = warningIcon
        }

        if (status === APPLICATION_VALIDATION_TAB_STATUSES.APPLYING_REPROCESSING && data.filter(({ document_type }) => document_type === item.key).length === 0) {
          icon = warningIcon
        }

        return {
          ...item,
          icon,
        }
      });

      return updatedLabel
    })
  }, [tabsStatus])

  const handleSaveData = async (signal) => {
    const saveApplicationApi = API_SAVE_APPLICATION.replace(
      ":id",
      application_id
    );

    const savedData = data.filter((_, index) => !editingRows[index]).map(({child, ...datum}) => ({
      ...datum,
      child: child ? child.filter(({ table_name }) => table_name) : child
    }));

    authenticatedFetch(saveApplicationApi, {
      data: { data: savedData, new_metadata: metadata, period_string_metadata: periodStringMetadata },
      signal,
      method: 'PUT',
    })
      .catch((err) => {
        if (!err.message || ["AbortError", "CanceledError"].includes(err.name)) {
          return
        }

        showToast(err.message, TOAST_TYPE.ERROR);
      });
  };
  const debouncedSave = debounce(handleSaveData, 500);

  useEffect(() => {
    if (pendingSave) {
      // debouncedSave();
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      // Create a new abort controller for the new request
      const controller = new AbortController();
      abortControllerRef.current = controller;

      debouncedSave(controller.signal);
      setPendingSave(false);
    }
  }, [pendingSave]);

  const [activeTab, setActiveTab] = useState(
    DOCUMENT_TYPE_ENUM.INCOME_STATEMENT
  );

  const [isScroll, setIsScroll] = useState(false);

  useEffect(() => {
    if (tableRef.current && isScroll) {
      tableRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [activeTab, isScroll]);

  const { width } = useWindowDimensions();

  const handleScroll = () => {
    if (window?.scrollY > 290) {
      setIsScroll(true);
    } else {
      setIsScroll(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  
  const handleValidateApplication = () => {
    setLoading(true);
    const validateApplicationApi = API_VALIDATE_APPLICATION.replace(
      ":id",
      application_id
    );

    authenticatedFetch(validateApplicationApi, {
      method: 'PUT',
    })
      .then(() => {
        navigate(`${URL_APPLICATION_RESULT}/${application_id}`);
      })
      .catch((err) => {
        if (!err.message) {
          return
        }
        showToast(err.message, TOAST_TYPE.ERROR);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderButton = useMemo(() => {
    const hasStartedReprocesses = Object.values(reprocessStatuses).some((status) => status === PROCESS_STATUSES.STARTED)

    const existingDocumentTypes = data.reduce((finalMap, { document_type }) => ({
      ...finalMap,
      [document_type]: true,
    }), {})

    const isNextButtonDisabled = DOCUMENT_TYPE_UPLOADED.some(({ key }) => {
      // if data doesnt exist, skip disabled check for that document type
      if (!existingDocumentTypes[key]) {
        return false
      }

      const documentTypeGroups = FORMULA_GROUPING[key];
      const documentTypeConfirmState = confirmState[key] || {};

      return documentTypeGroups.some(({ summary = [], name }) => {
        const groupConfirmState = documentTypeConfirmState[name] || {};
        return summary.some(({ displayCode }) => {
          return !groupConfirmState[displayCode];
        });
      });
    }) || hasStartedReprocesses;

    return (
      <div className="flex gap-[1.25rem]">
        <button
          onClick={() => navigate(URL_APPLICATION_CREATE)}
          className="w-[4.5rem] !h-[2rem] default border-[1px] border-neutral-medium !py-[0.25rem] !px-[0.5rem] font-[600] leading-[1.5rem] tracking-[-0.48px] text-[0.875rem] flex justify-center items-center"
        >
          Previous
        </button>
        <button
          onClick={() => handleValidateApplication()}
          disabled={isNextButtonDisabled}
          className="w-[4.5rem] !h-[2rem] !py-[0.25rem] !px-[0.5rem] primary font-[600] leading-[1.5rem] tracking-[-0.48px] text-[0.875rem] text-white flex justify-center items-center"
        >
          Next
        </button>
      </div>
    );
  }, [confirmState, application_id, navigate, reprocessStatuses]);

  const currentDocumentType = activeTab;
  const documentMetadata = metadata[currentDocumentType] || {};
  const { page_number, fiscal_period_order = [] } = documentMetadata;

  const [currentScrolledPage, setCurrentScrolledPage] = useState()

  const isCurrentPageLoaded = loadedPages[currentScrolledPage]

  const handleSetActiveTab = (newActiveTab) => {
    setActiveTab(newActiveTab)

    const documentMetadata = metadata[newActiveTab] || {};
    const { page_number } = documentMetadata;

    if (page_number) {
      goToPage(page_number)
    }
  }

  useEffect(() => {
    if (!isFileLoaded || !isCurrentPageLoaded) { 
      return
    }

    // let usedOcrData = ocrData

    // if (translatedOcrData && language !== applicationData?.language) {
    //   usedOcrData = translatedOcrData
    // }

    // TODO: adjust code to use usedOcrData after original text is provided
    // const currentPageOcrRowData = usedOcrData[currentScrolledPage];
    const currentPageOcrRowData = ocrData[currentScrolledPage] || [];

    if (!data.length) {
      return;
    }

    const highlightedData = data.map(({ client_account_name, original_client_account_name, ...others }) => {
      return {
        ...others,
        client_account_name: original_client_account_name || client_account_name,
      }
    })

    ocrHighlightsRef.current.forEach((ref) => ref.remove());
    ocrHighlightsRef.current = [];
    setSelectedRow(null);

    let currentFileRef = translatedFileRef.current || fileRef.current;
    if (language === applicationData?.language) {
      currentFileRef = fileRef.current
    }

    const currentPageDiv = currentFileRef.getPages(currentScrolledPage - 1);

    if (!currentPageDiv) {
      return
    }

    const parentElement = currentPageDiv.querySelector(
      ".rpv-core__text-layer"
    );

    if (parentElement) {
      loadOcrIntoHighlight(parentElement, currentPageOcrRowData, highlightedData, currentDocumentType, ocrHighlightsRef, currentScrolledPage, fileHeight, fileWidth)
      return
    }

    const observer = new MutationObserver(() => {
      const parentElement = currentPageDiv.querySelector(
        ".rpv-core__text-layer"
      );

      if (!parentElement) {
        return
      }
        loadOcrIntoHighlight(parentElement, currentPageOcrRowData, highlightedData, currentDocumentType, ocrHighlightsRef, currentScrolledPage, fileHeight, fileWidth)
        observer.disconnect(); // Disconnect once the element is found
      });

    observer.observe(currentPageDiv, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, [ocrData, data, currentScrolledPage, currentDocumentType, isCurrentPageLoaded, language, isFileLoaded]);

  const handleSetLanguage = (newLanguage) => {
    setLanguage(newLanguage);
    setIsFileLoaded(false);
  }

  const loadDataIntoPdf = (
    textContent,
    [, , width, height],
    currentPageIndex,
  ) => {
    setFileHeight(height);
    setFileWidth(width);
    setCurrentScrolledPage(currentPageIndex + 1)

    setTimeout(() => {
      setLoadedPages(currentLoadedPages => {
        const newLoadedPages = ({
          ...currentLoadedPages,
          [currentPageIndex+1]: true, 
        })

        return newLoadedPages
      })
    }, 100)
  };

  const documentTypeGroups = FORMULA_GROUPING[currentDocumentType];

  const goToPage = (pageNumber) => {
    const currentFileRef = fileRef.current || translatedFileRef.current;
    currentFileRef.scrollToPage?.(pageNumber)
  }

  useEffect(() => {
    if (isFileLoaded) {
      setTimeout(() => {
        goToPage(currentScrolledPage || page_number)
      }, 200);
    }
  }, [triggerReRender, isFileLoaded]);

  const handleSetEditingRows = (index, value) => {
    setEditingRows((prev) => ({
      ...prev,
      [index]: value,
    }));
  };

  const addNewDataRow = (index, groupName) => {
    const createTime = Date.now();

    setData((currentData) => {
      const newData = [...currentData];
      const emptyRow = {
        client_account_name: "",
        tp_standard_account_name: "",
        account_code: "",
        document_type: currentDocumentType,
        amount: fiscal_period_order.reduce(
          (acc, period) => ({ ...acc, [period]: 0 }),
          {}
        ),
        add_time: createTime,
        groupName,
      };
      newData.splice(index + 1, 0, emptyRow);

      return newData;
    });

    setEditingRows((prev) => {
      const newEditingRows = Object.keys(prev).reduce(
        (newEditingRows, currentIndex) => {
          const currentEditRow = prev[currentIndex];

          const newIndex =
            currentIndex > index ? parseInt(currentIndex) + 1 : currentIndex;

          return {
            ...newEditingRows,
            [newIndex]: currentEditRow,
          };
        },
        {}
      );

      return {
        ...newEditingRows,
        [index + 1]: true,
      };
    });
  };

  const onChangeAuditorName = (e, fiscalPeriod) => {
    const newAuditorName = e.target.value;
    
    setPeriodStringMetadata((prev) => ({
        ...prev,
        [fiscalPeriod]: {
            ...prev[fiscalPeriod],
            auditor_name: newAuditorName,
        },
    }));

    setPendingSave(Date.now());
  };
  
  const setFootnoteStatusToStarted = (childIndex, index) => {
    setData((currentData) => {
      if (!currentData[index]) {
        return currentData;
      }

      const newData = [...currentData];

      const { child = [] } = newData[index];

      const clientAccountChilds = (child || [])

      clientAccountChilds[childIndex].status = PROCESS_STATUSES.STARTED

      newData[index] = {
        ...newData[index],
        child: clientAccountChilds,
      };

      return newData;
    });

    setPendingSave(Date.now());
  }

  const filterChildContentByIndex = (childIndex, index) => {
    setData((currentData) => {
      if (!currentData[index]) {
        return currentData;
      }

      const newData = [...currentData];

      const { child = [] } = newData[index];

      const clientAccountChilds = (child || []).filter((_, i) => i !== childIndex)

      newData[index] = {
        ...newData[index],
        child: clientAccountChilds,
      };

      return newData;
    });

    setPendingSave(Date.now());
  }

  const addNewFootNote = (index) => {
    const createTime = Date.now();
    setData((currentData) => {
      if (!currentData[index]) {
        return currentData;
      }

      const newData = [...currentData];

      const { child } = newData[index];

      const clientAccountChilds = child || []

      const newChild = [...clientAccountChilds, {
        add_time: createTime,
      }];

      newData[index] = {
        ...newData[index],
        child: newChild,
      };

      return newData;
    });

    setPendingSave(Date.now());
  };

  const onCancelFootnote = (childIndex, index) => {
    setData((currentData) => {
      if (!currentData[index]) {
        return currentData;
      }

      const newData = [...currentData];

      const { child } = newData[index];

      const clientAccountChilds = child || []

      const newChild = clientAccountChilds.filter((_, i) => i !== childIndex);

      newData[index] = {
        ...newData[index],
        child: newChild,
      };

      return newData;
    });

    setPendingSave(Date.now());
  }


  const deleteRow = (index) => {
    setData((currentData) => currentData.filter((_, i) => i !== index));

    setEditingRows((prev) => {
      const newEditingRows = Object.keys(prev).reduce(
        (newEditingRows, currentIndex) => {
          if (parseInt(currentIndex) === index) {
            return newEditingRows
          }

          const currentEditRow = prev[currentIndex];

          const newIndex =
            currentIndex > index ? parseInt(currentIndex) - 1 : currentIndex;

          return {
            ...newEditingRows,
            [newIndex]: currentEditRow,
          };
        },
        {
          [index]: prev[index + 1]
        }
      );

      return newEditingRows;
    });
    setPendingSave(Date.now());
  };

  const handleSaveRow = (index, newRowData, ...additionalNewRowData) => {
    setData((currentData) => {
      const newData = [...currentData];
      newData.splice(index, 1, newRowData, ...additionalNewRowData)

      return newData;
    });

    handleSetEditingRows(index, undefined);

    setPendingSave(Date.now());
  };

  const onChildChange = (index, childIndex, newChildData) => {
    setData((currentData) => {
      const newData = [...currentData];

      const currentRowData = newData[index]

      if (!currentRowData.child.length) {
        return newData
      }

      currentRowData.child[childIndex] = newChildData

      return newData;
    });

    setPendingSave(Date.now());
  }

  const onInverseRow = (index) => {
    setData((currentData) => {
      if (!currentData[index]) {
        return currentData;
      }

      const newData = [...currentData];

      const { amount = {} } = newData[index];

      const newAmount = { ...amount };

      for (let periodString in newAmount) {
        const value = newAmount[periodString] || 0;

        newAmount[periodString] = value * -1;
      }

      newData[index] = {
        ...newData[index],
        amount: newAmount,
      };

      return newData;
    });

    setPendingSave(Date.now());
  };

  const handleSave = () => {
    setPendingSave(Date.now());
  };

  const { getStandardAccountOptions } = useApplicationContext();

  const standardAccountOptions = (
    getStandardAccountOptions(currentDocumentType) || []
  )?.map(({ account_code, tp_standard_account_name, groupName }) => ({
    value: account_code,
    label: tp_standard_account_name,
    groupName,
  }));

  const handlePeriodStringKeyChange = (
    previousFiscalPeriod,
    newFiscalPeriod,
  ) => {
    setPeriodStringMetadata((prev) => {
      const newFiscalPeriodMetadata = {...prev}
      newFiscalPeriodMetadata[newFiscalPeriod] = newFiscalPeriodMetadata[previousFiscalPeriod];
      return newFiscalPeriodMetadata;
    })
  }

  const handleAmountKeyChange = (
    documentType,
    previousFiscalPeriod,
    newFiscalPeriod
  ) => {
    if (previousFiscalPeriod === newFiscalPeriod) {
      return;
    }

    let newFiscalPeriodExists = false;

    setData((currentData) => {
      const newData = [];
      for (let i = 0; i < currentData.length; i++) {
        const datum = currentData[i] || {};
        const { amount, document_type, child } = datum;

        if (document_type !== documentType) {
          newData.push(datum);
          continue;
        }

        if (newFiscalPeriod in amount) {
          newFiscalPeriodExists = true;
          break;
        }

        const newAmount = { ...amount };

        if (previousFiscalPeriod in newAmount) {
          newAmount[newFiscalPeriod] = newAmount[previousFiscalPeriod];
          delete newAmount[previousFiscalPeriod]; // Remove the old key
        }

        datum.amount = newAmount;

        const datumChild = child || []

        if (datumChild.length) {
          const newDatumChild = datumChild.map(({ content, ...otherData }) => {
            const newContent = content.map(({ amount, ...otherContentData }) => {
              if (newFiscalPeriod in amount) {
                return {
                  ...otherContentData,
                  amount,
                }                
              }

              const newAmount = { ...amount }

              if (previousFiscalPeriod in newAmount) {
                newAmount[newFiscalPeriod] = newAmount[previousFiscalPeriod];
                delete newAmount[previousFiscalPeriod]; // Remove the old key
              }

              return {
                ...otherContentData,
                amount: newAmount
              }

            })

            return {
              ...otherData,
              content: newContent,
            }
          })

          datum.child = newDatumChild
        }

        newData.push(datum);
      }

      if (newFiscalPeriodExists) {
        return currentData;
      }

      return newData;
    });

    if (newFiscalPeriodExists) {
      showToast("Period already exists", TOAST_TYPE.ERROR);
      return;
    }

    setMetadata((currentMetadata) => {
      const { [documentType]: currentDocumentTypeMetadata = {} } =
        currentMetadata;
      const { fiscal_period_order = [] } = currentDocumentTypeMetadata;
      const newFiscalPeriodOrder = [...fiscal_period_order];

      for (let i = 0; i < fiscal_period_order.length; i++) {
        const currentFiscalPeriod = fiscal_period_order[i];
        if (currentFiscalPeriod === previousFiscalPeriod) {
          newFiscalPeriodOrder[i] = newFiscalPeriod;
          break;
        }
      }

      return {
        ...currentMetadata,
        [documentType]: {
          ...currentDocumentTypeMetadata,
          fiscal_period_order: newFiscalPeriodOrder,
        },
      };
    });

    setPendingSave(Date.now());
  };

  const onDateChange = (documentType, previousFiscalPeriod, month, year) => {
    const { period, audited } = extractPeriodAndDate(previousFiscalPeriod);
    const newPeriodString = generatePeriodString(month, year, period, audited);

    handleAmountKeyChange(documentType, previousFiscalPeriod, newPeriodString);
    handlePeriodStringKeyChange(previousFiscalPeriod, newPeriodString);
  };

  const onPeriodChange = (documentType, previousFiscalPeriod, period) => {
    const { monthInt, year, audited } =
      extractPeriodAndDate(previousFiscalPeriod);
    const newPeriodString = generatePeriodString(
      monthInt,
      year,
      period,
      audited
    );

    handleAmountKeyChange(documentType, previousFiscalPeriod, newPeriodString);
    handlePeriodStringKeyChange(previousFiscalPeriod, newPeriodString);
  };

  const onAuditedChange = (documentType, previousFiscalPeriod, audited) => {
    const { monthInt, year, period } =
      extractPeriodAndDate(previousFiscalPeriod);
    const newPeriodString = generatePeriodString(
      monthInt,
      year,
      period,
      audited
    );

    handleAmountKeyChange(documentType, previousFiscalPeriod, newPeriodString);
    handlePeriodStringKeyChange(previousFiscalPeriod, newPeriodString);
  };

  const calculatedUploadedDocuments = useMemo(() => {
    const calculatedDocumentTypesArr = DOCUMENT_TYPE_UPLOADED.filter(
      ({ key }) => metadata[key]
    );
    const calculatedFormulas = calculatedDocumentTypesArr.reduce(
      (fin, { key, formula_type = key }) => {
        const currFormula = FORMULAS_MAP[formula_type].map((data) => ({
          ...data,
          document_type: formula_type,
        }));
        return [...fin, ...currFormula];
      },
      []
    );

    const baseCalculateMetadata = calculatedDocumentTypesArr.reduce(
      (existingMetadata, { key, formula_type = key }) => {
        return {
          ...existingMetadata,
          [formula_type]: metadata[key],
        };
      },
      {
        isOldestYear: false,

        // TODO: replace this value's usage to use extract from period string instead
        numberOfMonths: 12,
      }
    );

    const result = calculateFormulaFromData(
      data,
      calculatedFormulas,
      baseCalculateMetadata
    );

    result.sort((a, b) =>
      a.account_code.localeCompare(b.account_code, undefined, { numeric: true })
    );
    return result;
  }, [data, metadata]);

  const onBack = () => navigate(URL_APPLICATION_CREATE);

  const onOriginalPdfLoadError = (error) => {
    URL.revokeObjectURL(fileUrl)
    setLoading(true);
    fetchApplicationFileApi(application_id)
  }
  const onTranslatePdfLoadError = (error) => {
    URL.revokeObjectURL(translatedFileUrl)
    setLoading(true);
    fetchApplicationTranslatedFileApi(application_id)
  }

  const onPageLoad = () => {
    setIsFileLoaded(true);
    setLoading(false);
  }

  // width for client account name / tp standard account name
  const namesWidth = width >= DEVICE_WIDTH.DESKTOP ? 200 : 120;
  const nonNamesWidth = 100;
  const minWidth =
    namesWidth * 2 + (fiscal_period_order.length + 1) * nonNamesWidth;

  const handleSubmitReprocessApplication = (formValue) => {
    const { page_numbers } = formValue
    const data = {
      page_numbers,
      document_type: currentDocumentType,
    };

    const applicationReprocessApi = API_APPLICATION_REPROCESS.replace(
      ":id",
      application_id
    );
    setLoading(true);
    authenticatedFetch(applicationReprocessApi, {
      data,
      method: "POST",
    }).then(({ data: { data, metadata, status } }) => {
      data.sort((a, b) => a.row_number - b.row_number);
      setReprocessStatuses((currentReprocessStatuses) => ({
        ...currentReprocessStatuses,
        [currentDocumentType]: PROCESS_STATUSES.STARTED,
      }))
      setTabsStatus((currentTabsStatus) => ({
        ...currentTabsStatus,
        [currentDocumentType]: {
          ...(currentTabsStatus[currentDocumentType] || {}),
          status: APPLICATION_VALIDATION_TAB_STATUSES.LOADING,
        },
      })) 
      setData(data);
      setMetadata(metadata);
      startFetchApplicationProcessStatusPoll(application_id)
    }).catch(err => { 
      if (!err.message) { 
        return
      }
      showToast('Error when reprocessing document. Please try again later', TOAST_TYPE.ERROR)
    }).finally(() => {
      setLoading(false)
    });
  };

  const handleSubmitNotelinking = (clientAccountName, accountCode, pageNumbers, childIndex, index) => {
    setFootnoteStatusToStarted(childIndex, index)
    const data = {
      client_account_name: clientAccountName,
      account_code: accountCode,
      page_numbers: pageNumbers,
    };

    const applicationReprocessApi = API_APPLICATION_NOTELINKING.replace(
      ":id",
      application_id
    );
    setLoading(true);
    authenticatedFetch(applicationReprocessApi, {
      data,
      method: "POST",
    }).then(({ data: { data, metadata, status } }) => {
      data.sort((a, b) => a.row_number - b.row_number);
      // setNoteLinkingStatuses((currentNotelinkingStatuses) => ({
      //   ...currentNotelinkingStatuses,
      //   [currentDocumentType]: APPLICATION_PROCESS_STATUS.STARTED,
      // }))
      // setTabsStatus((currentTabsStatus) => ({
      //   ...currentTabsStatus,
      //   [currentDocumentType]: {
      //     ...(currentTabsStatus[currentDocumentType] || {}),
      //     status: APPLICATION_VALIDATION_TAB_STATUSES.LOADING,
      //   },
      // })) 
      // setData(data);
      // setMetadata(metadata);
      startFetchApplicationProcessStatusPoll(application_id, () => filterChildContentByIndex(childIndex, index))
    }).catch(err => { 
      if (!err.message) { 
        return
      }
      showToast('Error when reprocessing document. Please try again later', TOAST_TYPE.ERROR)
    }).finally(() => {
      setLoading(false)
    });
  };

  const onOpenReprocessForm = () => {
    setTabsStatus((currentTabsStatus) => ({
      ...currentTabsStatus,
      [currentDocumentType]: {
        ...(currentTabsStatus[currentDocumentType] || {}),
        status: APPLICATION_VALIDATION_TAB_STATUSES.APPLYING_REPROCESSING,
      }
    }))
  }

  const onCancelReprocessForm = () => {
    const currentDocumentTypeHasData = data.filter(({ document_type }) => document_type === currentDocumentType).length > 0
    setTabsStatus((currentTabsStatus) => ({
      ...currentTabsStatus,
      [currentDocumentType]: {
        ...(currentTabsStatus[currentDocumentType] || {}),
        status: currentDocumentTypeHasData ? null : APPLICATION_VALIDATION_TAB_STATUSES.WARNING,
      }
    }))
  }

  return (
    <div
      className={`w-full max-w-default sm:px-[48px] 2xl:px-[64px] relative min-h-[calc(100vh-70px)] flex flex-col`}
    >
      <div className="py-[2.5rem] flex-col gap-[1rem]">
        <div className="pb-[1rem] flex justify-between">
          <div className="flex gap-[1rem] flex-1 items-center">
            <button onClick={onBack}>
              <img
                src={backIcon}
                alt="back-button"
                className="cursor-pointer"
              />
            </button>
            <span className="text-gradient-aurora-blue text-[2rem] font-[700] leading-[2.5rem] tracking-[-0.96px]">
              Financial Spreading Confirmation
            </span>
          </div>
          {!isScroll && renderButton}
        </div>
        <ul className="list-disc pl-[1.625rem] text-[1rem] leading-[1.75rem] tracking-[-0.54px] font-[500] w-full">
          <li>
            The Income Statement, Balance Sheet and Cash Flow Statement are
            split into three tabs. Compare the original financial statements on
            the left to the mapped accounts and figures on the right.
          </li>
          <li>
            Verify the accuracy of the mapped accounts, figures and fiscal
            periods; or click on them to make corrections. Please disregard rows
            that are subtotals or totals.
          </li>
          <li>
            Every section has a calculated total that requires your
            confirmation. After confirming the calculated totals on all
            available tabs, click "Next" to proceed.
          </li>
        </ul>
      </div>

      <div className="flex flex-col items-center gap-[2rem] w-full">
        <div className="max-w-[1792px] w-full flex flex-col gap-[1.5rem]">
          <div
            className={`flex justify-between align-center ${
              isScroll
                ? "sticky w-full bg-[#fff] top-0 z-[20] py-[0.75rem] px-[0.5rem] pb-0 mb-[24px]"
                : ""
            }`}
          >
            <Tabs
              tabsLabel={tabsLabel}
              activeTab={activeTab}
              setActiveTab={handleSetActiveTab}
              wrapperClassname={
                isScroll ? "py-[0.75rem] px-[0.5rem] !border-none" : ""
              }
            />
            {isScroll && renderButton}
          </div>
          <PanelGroup
            direction="horizontal"
            className={`gap-[2px] !overflow-visible h-full`}
          >
            <Panel
              defaultSizePercentage={40}
              maxSize={50}
              className="flex flex-col gap-[1.5rem] min-w-[430px] sticky top-0 self-start"
            >
              <div className="flex gap-[1.5rem] items-center">
                <span className="text-[1.125rem] font-[700] leading-[2rem] tracking-[-0.72px]">
                  Original Document
                </span>
              </div>
              {language === applicationData?.language && (
                <Preview
                  defaultHeight={650}
                  url={fileUrl}
                  type={fileType}
                  onPageLoadSuccess={loadDataIntoPdf}
                  onPageLoadError={onOriginalPdfLoadError}
                  onPageLoad={onPageLoad}
                  ref={fileRef}
                  initialPage={page_number}
                  containerClassName="bg-white p-[1.5rem] !pr-0 border-[1px] border-solid rounded-[20px] flex flex-col w-full aspect-[4/3]"
                  key={triggerReRender}
                  buttons={[
                    applicationData?.has_translation && (
                      <button
                        onClick={() => handleSetLanguage(LANGUAGE_ENGLISH)}
                      >
                        <img
                          alt="translate"
                          className="w-[2.75rem] h-[2.75rem] p-[0.75rem] rounded-[50%] border-[1px] border-primary-200 bg-handle-pdf-bg cursor-pointer"
                          src={translateIcon}
                        />
                      </button>
                    ),
                  ].filter(Boolean)}
                  onZoom={() => setIsFileLoaded(false)}
                />
              )}
              {applicationData && language !== applicationData?.language && (
                <Preview
                  defaultHeight={650}
                  url={translatedFileUrl}
                  type={translatedFileType}
                  onPageLoadSuccess={loadDataIntoPdf}
                  onPageLoadError={onTranslatePdfLoadError}
                  onPageLoad={onPageLoad}
                  ref={translatedFileRef}
                  initialPage={page_number}
                  containerClassName="bg-white p-[1.5rem] !pr-0 border-[1px] border-solid rounded-[20px] flex flex-col w-full aspect-[4/3]"
                  key={triggerReRender}
                  buttons={[
                    <button
                      onClick={() =>
                        handleSetLanguage(applicationData?.language)
                      }
                    >
                      <img
                        alt="translate"
                        className="w-[2.75rem] h-[2.75rem] p-[0.75rem] rounded-[50%] border-[1px] border-primary-200 bg-handle-pdf-bg cursor-pointer"
                        src={translateIcon}
                      />
                    </button>,
                  ]}
                />
              )}
            </Panel>
            <PanelResizeHandle
              className="relative w-[24px] h-[24px] flex items-center justify-center"
              onDragging={(isDragging) => {
                if (!isDragging) {
                  setTriggerRender((prev) => prev + 1);
                }
              }}
              style={{
                top: (fileHeight ?? 700 - 50) / 2,
              }}
            >
              <ResizeIcon color="black" />
            </PanelResizeHandle>
            <Panel
              defaultSizePercentage={50}
              minSize={50}
              className="flex flex-col  !overflow-visible hideScrollbar"
              style={{
                width: minWidth,
                // overflow: "auto",
              }}
            >
              <div ref={tableRef} />
              <div
                className={`flex items-center mb-6 gap-[1.5rem] text-[1.125rem] font-[700] leading-[2rem] tracking-[-0.72px]`}
              >
                <span className="text-[1.125rem] font-[700] leading-[2rem] tracking-[-0.72px]">
                  Financial Mapping
                </span>
                {tabsStatus[currentDocumentType]?.status !==
                  APPLICATION_VALIDATION_TAB_STATUSES.LOADING && (
                    <div onClick={onOpenReprocessForm}>
                      <Tooltip
                        tooltip="Reprocess Financial Mapping"
                        topTooltip
                        tooltipClassname="flex justify-center items-centerm whitespace-nowrap max-w-[240px] p-1.5 px-2.5 py-1 bg-white bg-opacity-80 rounded !max-w-[240px] !w-[127px] !right-[-24px] top-[-24px] !my-0 !h-[20px]"
                      >
                        <ReprocessIcon className="ml-[-4px] cursor-pointer text-primary-2 h-6" />
                      </Tooltip>
                    </div>
                )}
              </div>
              <div
                className={`flex-col gap-[1.5rem] flex overflow-visible relative rounded-[20px]`}
              >
                {documentTypeGroups.map(
                  ({ name, summary, content, canInverseValue }, index) => {
                    const groupConfirmState =
                      confirmState[currentDocumentType][name];

                    const isLastTable = index === documentTypeGroups.length - 1;

                    return (
                      <FinancialSpreadingValidateTable
                        minWidth={minWidth}
                        name={name}
                        fiscalPeriods={fiscal_period_order}
                        data={data}
                        documentType={currentDocumentType}
                        standardAccountOptions={standardAccountOptions}
                        onAddRow={addNewDataRow}
                        onDeleteRow={deleteRow}
                        onSaveRow={handleSaveRow}
                        canChangeDate={index === 0}
                        onDateChange={onDateChange.bind(
                          null,
                          currentDocumentType
                        )}
                        onPeriodChange={onPeriodChange.bind(
                          null,
                          currentDocumentType
                        )}
                        onAuditedChange={onAuditedChange.bind(
                          null,
                          currentDocumentType
                        )}
                        onSave={handleSave}
                        summary={summary}
                        content={content}
                        calculatedData={calculatedUploadedDocuments}
                        onInvertClick={canInverseValue && onInverseRow}
                        onRowHover={setSelectedRow}
                        confirmState={groupConfirmState}
                        onConfirm={handleConfirmStateUpdate}
                        onResetConfirm={handleResetConfirm}
                        showMonth={[
                          DOCUMENT_TYPE_ENUM.INCOME_STATEMENT,
                          DOCUMENT_TYPE_ENUM.CASH_FLOW_STATEMENT,
                        ].includes(activeTab)}
                        editingRows={editingRows}
                        setEditingRows={handleSetEditingRows}
                        isLastTable={isLastTable}
                        showEmptyAccountCode={isLastTable}
                        periodStringMetadata={periodStringMetadata}
                        onChangeAuditorName={onChangeAuditorName}
                        onAddFootNote={addNewFootNote}
                        onNotesSearch={handleSubmitNotelinking}
                        onCancelFootnote={onCancelFootnote}
                        notesProcesses={notelinkingProcessedStatus}
                        goToPage={goToPage}
                        onChildChange={onChildChange}
                      />
                    );
                  }
                )}
                <FinancialSpreadingValidateTableModal
                  tabStatus={tabsStatus[currentDocumentType]}
                  onReprocess={handleSubmitReprocessApplication}
                  onOpenReprocessForm={onOpenReprocessForm}
                  onCancelReprocessForm={onCancelReprocessForm}
                />
              </div>
            </Panel>
          </PanelGroup>
        </div>
      </div>
      <div className="min-h-[2rem]"></div>
    </div>
  );
}
