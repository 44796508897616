export const PERFORMANCE_CLASSIFICATION_TYPES = {
  PERFORMING: 'PERFORMING',
  WATCHLIST: 'WATCHLIST',
  CRITICAL: 'CRITICAL',
  EXITING: 'EXITING',
}

export const APPLICATION_PROCESS_TYPES = {
  REPROCESS: 'REPROCESS',
  NOTELINKING: 'NOTELINKING',
}