import { CHART_DURATION } from "../constants";
import { LANGUAGE_ENGLISH } from "../language";
import { PERFORMANCE_CLASSIFICATION_TYPES } from "./types";

export const SORT_TYPES = {
  ASC: "ASC",
  DESC: "DESC",
};

export const SORT_TIME_OPTIONS = [
  {
    value: SORT_TYPES.DESC,
    label: "Latest to Oldest (left to right)",
  },
  {
    value: SORT_TYPES.ASC,
    label: "Oldest to Latest (left to right)",
  },
];

export const PERFORMANCE_CLASSIFICATION_OPTIONS = [
  {
    value: PERFORMANCE_CLASSIFICATION_TYPES.PERFORMING,
    label: 'Performing',
  },
  {
    value: PERFORMANCE_CLASSIFICATION_TYPES.WATCHLIST,
    label: 'Watchlist',
  },
  {
    value: PERFORMANCE_CLASSIFICATION_TYPES.CRITICAL,
    label: 'Critical',
  },
  {
    value: PERFORMANCE_CLASSIFICATION_TYPES.EXITING,
    label: 'Exiting',
  },
]

export const CHART_DURATION_OPTIONS = [
  {
    value: CHART_DURATION.MONTH,
    label: 'Month',
  },
  // {
  //   value: CHART_DURATION.WEEK,
  //   label: 'Week',
  // },
] 

export const MONTH_CHART_DURATION_PERIOD_OPTIONS = [
  {
    label: "Last 12 months",
    value: 12,
  },
  {
    label: "Last 6 months",
    value: 6,
  },
] 

export const WEEK_CHART_DURATION_PERIOD_OPTIONS = [
  {
    label: "Last 4 Weeks",
    value: 4,
  },
  {
    label: "Last 8 Weeks",
    value: 8,
  },
  {
    label: "Last 12 Weeks",
    value: 12,
  },
] 

export const CHART_DURATION_PERIOD_OPTIONS = {
  [CHART_DURATION.MONTH]: MONTH_CHART_DURATION_PERIOD_OPTIONS,
  [CHART_DURATION.WEEK]: WEEK_CHART_DURATION_PERIOD_OPTIONS,
}

export const LANGUAGE_OPTIONS = [
  {
    label: "English",
    value: LANGUAGE_ENGLISH,
  },
  {
    label: "French",
    value: "FRENCH",
  },
  {
    label: "German",
    value: "GERMAN",
  },
  {
    label: "Italian",
    value: "ITALIAN",
  },
  {
    label: "Spanish",
    value: "SPANISH",
  },
  {
    label: "Chinese",
    value: "CHINESE_TRADITIONAL",
  },
]

