import { useEffect, useMemo, useRef, useState } from "react";
import extractPeriodAndDate from "../../utils/string/extractPeriodAndDate";
import iconPlusGreen from "../../assets/icons/icon_table_green_plus.svg";
import iconTrash from "../../assets/icons/icon_table_trash.svg";
import iconAdditionOrSubtraction from "../../assets/icons/icon_table_addition_or_subtraction.svg";
import iconSave from "../../assets/icons/icon_table_save.svg";
import iconCancel from "../../assets/icons/icon_table_cancel.svg";
import tableDateDropDown from "../../assets/icons/table_date_dropdown.png";
import DatePicker from "../../components/DatePicker";
import { AUDITED_OPTIONS, PERIOD_MONTHS_OPTIONS, UNAUDITED_VALUE } from "../../constants";
import Dropdowns from "../../components/Dropdowns";
import TextInput from "../../components/TextInput";
import TableInput from "../../components/TableElements/Input";
import TableSelect from "../../components/TableElements/Select";
import Tooltip from "../../components/Tooltip";
import calculateFormulaFromData from "../../utils/formula/calculate_formula_from_data";
import formatNumber from "../../utils/number/formatNumber";
import { Popover } from "react-tiny-popover";
import FinancialSpreadingValidateTableRowChild from "./FinancialSpreadingValidateTableRowChild";
import FinancialSpreadingValidateTableEmptySection from "./FinancialSpreadingValidateTableEmptySection";

const FIELDS = {
  client_account_name: "client_account_name",
  tp_standard_account_name: "tp_standard_account_name",
  amount: "amount",
  account_code: "account_code",
};

export default function FinancialSpreadingValidateTable({
  name,
  minWidth,
  fiscalPeriods = [],
  data,
  documentType,
  standardAccountOptions,
  onInvertClick,
  onAddRow,
  onAddFootNote,
  onDeleteRow,
  onSaveRow,
  canChangeDate = false,
  onDateChange,
  onPeriodChange,
  onAuditedChange,
  onSave,
  summary,
  content,
  calculatedData,
  onRowHover,
  confirmState,
  onConfirm,
  onResetConfirm,
  showMonth,
  isLastTable = false,
  editingRows,
  setEditingRows,
  showEmptyAccountCode,
  periodStringMetadata,
  onChangeAuditorName,
  onNotesSearch,
  onCancelFootnote,
  notesProcesses,
  goToPage,
  onChildChange,
}) {
  const dynamicColRef = useRef(null);
  const tableRef = useRef(null);
  const clientAccountNameRef = useRef(null);

  const summaryData = calculateFormulaFromData(
    calculatedData,
    summary,
  );

  const shownContentRanges = content.split(",");
  const [rowData, setRowData] = useState([]);
  const [showPopovers, setShowPopovers] = useState("");
  const popoverRef = useRef(null);
  const [isOpenTableSelect, setIsOpenTableSelect] = useState(false);
  const [isAddingRowIndex, setIsAddingRowIndex] = useState();

  useEffect(() => {
    setRowData((currentRowData) => {
      return data.map((datum, index) => {
        // if not editing, then use the updated data
        if (typeof editingRows[index] === "undefined") {
          return datum;
        }

        return currentRowData[index] || datum;
      });
    });
  }, [data, editingRows]);

  useEffect(() => {
    if (notesProcesses) {
      setRowData((currentRowData) => {
        return currentRowData.map((currentRowDatum) => {
          const { client_account_name, account_code, child } = currentRowDatum

          const clientAccountChilds = child || []
          const notesProcessesKey = `${client_account_name}_${account_code}`
          const rowNotesProcess = notesProcesses[notesProcessesKey]

          if (!rowNotesProcess || !clientAccountChilds.length) {
            return currentRowDatum
          }

          // filter all the ones without status, to be replaced with the actual notes processes data
          // this is to handle immediate loading feedback after user inputted field
          const newProcessedFilteredChild = clientAccountChilds.filter(({ status }) => !status)

          return {
            ...currentRowDatum,
            child: newProcessedFilteredChild
          }
        })
       
        });
    }
  }, [notesProcesses]);

  const handleOpenPopover = (fiscalPeriod) => {
    setShowPopovers(currentFiscalPeriod => {
      if (currentFiscalPeriod) {
        return ""
      }

      return fiscalPeriod
    });
  };

  const handleAddFootNote = (index) => {
    onAddFootNote?.(index);
  };

  const handleCancelFootnote = (childIndex, index) => {
    onCancelFootnote?.(childIndex, index)
  }

  const handleAddRow = (index) => {
    onAddRow?.(index, name);
    setRowData((currentData) => {
      const newData = [...currentData];
      const emptyRow = {
        client_account_name: "",
        tp_standard_account_name: "",
        account_code: "",
        document_type: documentType,
        amount: fiscalPeriods.reduce(
          (acc, period) => ({ ...acc, [period]: 0 }),
          {}
        ),
        add_time: Date.now(),
        groupName: name,
      };
      newData.splice(index + 1, 0, emptyRow);

      return newData;
    });
  };

  const handleAddClick = (index) => {
    setIsAddingRowIndex(index)
  }

  const handleNotesSearch = (childIndex, index, pageNumbers) => {
    const savedRow = rowData[index];

    const { client_account_name, account_code } = savedRow

    onNotesSearch?.(client_account_name, account_code, pageNumbers, childIndex, index)
  }

  const addRowOptions = [
    {
      label: "Add Row",
      onClick: handleAddRow,
    },
    onAddFootNote && {
      label: "Add Note",
      onClick: handleAddFootNote,
    }
  ].filter(Boolean)

  const handleInvertRow = (index) => {
    onInvertClick?.(index);
    onResetConfirm(documentType, name);
  };

  const handleRemoveRow = (index) => {
    onDeleteRow?.(index);
    onResetConfirm(documentType, name);
  };

  const handleSaveRow = (index) => {
    const savedRow = rowData[index];

    onSaveRow(index, savedRow);

    onResetConfirm(documentType, name);
  };

  const handleChange = (index, key, value) => {
    setRowData((currentData) => {
      const currentRowData = currentData[index] || {};

      const newRowData = {
        ...currentRowData,
        [key]: value,
      };

      const newData = [...currentData];
      newData[index] = newRowData;
      return newData;
    });
  };
  const onChange = (index, event) => {
    handleChange(index, event.target.name, event.target.value);
  };

  const onDropdownChange = (index, value, key) => {
    handleChange(index, key, value);
  };

  const onAmountChange = (index, period, event) => {
    setRowData((currentData) => {
      const currentRowData = currentData[index] || {};
      const currentAmountData = currentRowData[FIELDS.amount] || {};

      const newRowData = {
        ...currentRowData,
        [FIELDS.amount]: {
          ...currentAmountData,
          [period]: parseFloat(event.target.value),
        },
      };

      const newData = [...currentData];
      newData[index] = newRowData;
      return newData;
    });
  };

  const actions = [
    onInvertClick && {
      tooltip: "Invert number sign (+/-)",
      className:
        "flex justify-center items-center max-w-[240px] p-1.5 px-2.5 py-1 bg-white bg-opacity-80 rounded !max-w-[240px] !w-[6.25rem] !right-0 !-translate-x-[80%]",
      Component: ({ index }) => (
        <button onClick={handleInvertRow.bind(null, index)}>
          <img
            src={iconAdditionOrSubtraction}
            className="w-[20px] cursor-pointer"
            alt="Invert value"
          />
        </button>
      ),
    },
    onAddRow &&
      onSaveRow && {
        tooltip: "Add row below",
        className:
          "flex justify-center items-center max-w-[240px] p-1.5 px-2.5 py-1 bg-white bg-opacity-80 rounded !max-w-[240px] !w-[83px] !right-0 !-translate-x-[80%]",
        Component: ({ index }) => (
          <Popover
            isOpen={index === isAddingRowIndex}
            positions={["bottom", "top", "left", "right"]} // preferred positions by priority
            onClickOutside={(event) => {
              setIsAddingRowIndex()
            }}
            content={
              <div
                className={`absolute z-[22] left-[-38px] rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none min-w-[40px]`}
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
                tabIndex="-1"
              >
                <div
                  className={`py-[8px] max-h-[200px] min-w-[80px] overflow-y-auto`}
                  role="none"
                >
                  {
                    addRowOptions.map(({ label, onClick }) => (
                      <span
                        className={`block px-[0.75rem] py-[0.5rem] text-[0.8125rem] font-[500] leading-[1rem] tracking-[-0.28px] hover:bg-neutral-flat-hover cursor-pointer`}
                        role="menuitem"
                        tabIndex="-1"
                        id="menu-item-0"
                        onClick={(event) => {
                          event.stopPropagation();
                          onClick(index);
                          setIsAddingRowIndex();
                        }}
                      >
                        {label}
                      </span>
                    ))
                  }
                </div>
              </div>
            }
          >
            <button onClick={(event) => {
              event.stopPropagation()
              handleAddClick(index)
            }}>
              <img
                src={iconPlusGreen}
                className="w-[20px] cursor-pointer"
                alt="Add New Row"
              />
            </button>
          </Popover>
        ),
      },
    onDeleteRow && {
      tooltip: "Delete this row",
      className:
        "flex justify-center items-center max-w-[240px] p-1.5 px-2.5 py-1 bg-white bg-opacity-80 rounded !max-w-[240px] !w-[85px] !right-0 !-translate-x-[80%]",
      Component: ({ index }) => (
        <button onClick={handleRemoveRow.bind(null, index)}>
          <img
            src={iconTrash}
            className="w-[20px] cursor-pointer"
            alt="Delete row"
          />
        </button>
      ),
    },
  ].filter(Boolean);

  const onPopoverButtonClick = (callback, ...params) => {
    setShowPopovers("");
    callback(...params)
  }

  const dataContent = rowData.map((rowDatum, index) => {
    if (!data?.[index]) {
      return null;
    }
    const {
      account_code,
      client_account_name,
      tp_standard_account_name,
      amount,
      document_type,
      row_number,
      add_time,
      groupName,
      child,
    } = data[index];

    if (document_type !== documentType) {
      return null;
    }

    // if account code not selected yet, then grouped under the group who creates it
    if (!account_code && groupName && groupName !== name) {
      return null;
    }

    // if no account code, no group name and not showing empty account code, then don't show
    if (!groupName && !showEmptyAccountCode && !account_code) {
      return null;
    }

    for (let i = 0; i < shownContentRanges.length; i++) {
      const range = shownContentRanges[i];
      const [startAccountCode, endAccountCode = startAccountCode] =
        range.split("-");

      if (
        account_code &&
        (account_code < startAccountCode || account_code > endAccountCode)
      ) {
        return null;
      }
    }
    const isEditing = editingRows[index] !== undefined;

    const clientAccountChilds = child || [];

    const childInProgress =
      (notesProcesses || {})[`${client_account_name}_${account_code}`] || [];

    const combinedChild = [
      ...childInProgress.map(({ status, metadata, process_id }) => ({
        status,
        metadata,
        process_id,
      })),
      ...clientAccountChilds,
    ];

    const renderedChild =
      combinedChild.length > 0 &&
      combinedChild.map((childData, childIndex) => (
        <FinancialSpreadingValidateTableRowChild
          key={
            childData.add_time ||
            childData.process_id ||
            `${client_account_name}_${account_code}_${childIndex}`
          }
          fiscalPeriods={fiscalPeriods}
          data={childData}
          onSearch={handleNotesSearch.bind(
            null,
            childIndex - childInProgress.length,
            index
          )}
          onCancel={() =>
            handleCancelFootnote(childIndex - childInProgress.length, index)
          }
          goToPage={goToPage}
          onSave={onChildChange?.bind(
            null,
            index,
            childIndex - childInProgress.length
          )}
        />
      ));

    if (isEditing && onSaveRow) {
      return (
        <>
          <tr
            key={`${add_time || row_number}_${index}`}
            onMouseEnter={() => onRowHover(index)}
          >
            <td className="bg-primary-50 sticky left-0 !h-[35px] !p-0 !px-[0.75rem] !text-[0.8125rem] !leading-[1rem] ">
              <div className="max-w-[200px]">
                <TableInput
                  id={FIELDS.client_account_name}
                  className="w-full"
                  defaultValue={client_account_name}
                  onChange={onChange.bind(null, index)}
                />
              </div>
            </td>
            <td className="sticky bg-primary-50 xl:left-[120px] 2xl:left-[200px] !h-[35px] !p-0">
              <div className="w-full !px-[0.75rem] !text-[0.8125rem]">
                <TableSelect
                  id={FIELDS.account_code}
                  defaultValue={account_code}
                  data={standardAccountOptions}
                  className="w-full"
                  onChange={(newAccountCode, key) => {
                    onDropdownChange(index, newAccountCode, key);

                    const newTpStandardAccountName =
                      standardAccountOptions.find(
                        ({ value }) => value === newAccountCode
                      )?.label;
                    onDropdownChange(
                      index,
                      newTpStandardAccountName,
                      FIELDS.tp_standard_account_name
                    );
                  }}
                  readOnly
                  setIsOpenTableSelect={setIsOpenTableSelect}
                  popoverClassname={`!min-w-[230px] ${
                    isLastTable ? "bottom-[18.5px]" : ""
                  }`}
                  dropdownDataClassname="!px-[0.25rem] !py-[0.15625rem] !leading-[0.75rem] !text-[0.75rem]"
                  inputClassName="cursor-pointer"
                />
              </div>
            </td>
            {fiscalPeriods.map((fiscalPeriod) => (
              <td
                className="bg-primary-50 !h-[35px] !p-0 w-[6.25rem]"
                key={fiscalPeriod}
              >
                <div className="!pl-[0.75rem] !pr-[26px] !text-[0.8125rem]">
                  <TableInput
                    defaultValue={amount[fiscalPeriod] || ""}
                    className="w-full"
                    onChange={onAmountChange.bind(null, index, fiscalPeriod)}
                  />
                </div>
              </td>
            ))}
            <td className="bg-primary-50 sticky right-0 w-[6.25rem] !h-[35px] !py-0 !px-[0.75rem]">
              <div className="flex justify-center gap-[0.625rem] items-center">
                <button onClick={handleSaveRow.bind(null, index)}>
                  <img
                    src={iconSave}
                    className="w-[20px] cursor-pointer"
                    title="Save"
                    alt="Save"
                  />
                </button>
                <img
                  onClick={() => {
                    if (editingRows[index]) {
                      handleRemoveRow(index);
                      return;
                    }
                    setEditingRows(index, undefined);
                  }}
                  src={iconCancel}
                  className="w-[20px] cursor-pointer"
                  alt="Cancel"
                  title="Cancel"
                />
              </div>
            </td>
          </tr>
          {renderedChild}
        </>
      );
    }

    return (
      <>
        <tr
          key={`${add_time || client_account_name}_${index}`}
          onMouseEnter={() => onRowHover(index)}
          className="transition-all duration-200 ease-in-out"
        >
          <td
            className="bg-white sticky left-0 !h-[35px] !py-0 !px-[0.75rem] !text-[0.8125rem] !leading-[1rem] xl:w-[120px] 2xl:w-[200px]"
            onClick={() => setEditingRows(index, false)}
          >
            <div className="flex justify-start text-neutral-strong leading-[1.25rem] tracking-[-0.28px] font-[400] w-full">
              {client_account_name}
            </div>
          </td>
          <td
            className="bg-white sticky xl:left-[120px] 2xl:left-[200px] !h-[35px] !p-0 xl:w-[120px] 2xl:w-[200px]"
            style={{
              left: clientAccountNameRef?.current?.offsetWidth,
            }}
            onClick={() => setEditingRows(index, false)}
          >
            <div className="flex justify-start text-neutral-strong !px-[0.75rem] !text-[0.8125rem] leading-[1.25rem] tracking-[-0.28px] font-[400] w-full">
              {tp_standard_account_name}
            </div>
          </td>
          {fiscalPeriods.map((fiscalPeriod) => (
            <td
              className="bg-white !h-[35px] !p-0 w-[6.25rem] max-w-[6.25rem]"
              onClick={() => setEditingRows(index, false)}
              key={fiscalPeriod}
            >
              <div
                className={`!pl-[0.75rem] !pr-[26px] !text-[0.8125rem] flex justify-end text-neutral-strong leading-[1.25rem] tracking-[-0.28px] font-[400]`}
              >
                {formatNumber(amount[fiscalPeriod]) || "-"}
              </div>
            </td>
          ))}
          {actions.length > 0 && (
            <td
              id="action"
              className="bg-white sticky right-0 w-[6.25rem] !h-[35px] !py-0 !px-[0.75rem]"
              key={index}
            >
              <div className="flex justify-center gap-[0.625rem] items-center">
                {actions.map(({ tooltip, className, Component }) => (
                  <Tooltip
                    tooltip={tooltip}
                    tooltipClassname={className}
                    topTooltip
                  >
                    <Component index={index} />{" "}
                  </Tooltip>
                ))}
              </div>
            </td>
          )}
        </tr>
        {renderedChild}
      </>
    );
  }).filter(Boolean);

  let tableBodyContent = dataContent

  if (!tableBodyContent.length) {
    const handleSaveEmptySection = (data) => {
      const filledData = data.map((datum) => ( {
        document_type: documentType,
        add_time: Date.now(),
        groupName: name,
        ...datum
      }))

      onSaveRow(rowData.length, ...filledData);

      onResetConfirm(documentType, name);
    }

    tableBodyContent = 
     <FinancialSpreadingValidateTableEmptySection 
      standardAccountOptions={standardAccountOptions}
      isLastTable={isLastTable}
      fiscalPeriods={fiscalPeriods}
      onSaveRow={handleSaveEmptySection}
      baseRowData={{
        document_type: documentType,
        groupName: name,
      }}
     />
  }

  return (
    <>
      <div
        className="bg-white p-[1.5rem] border-[1px] border-solid rounded-[20px] flex flex-col"
        ref={tableRef}
      >
        <span className="leading-[1.75rem] tracking-[-0.6px] font-[700] text-[1rem] text-black">
          {name}
        </span>
        <div
          style={{
            overflow: "auto",
          }}
        >
          <table
            className="table-fixed"
            style={{
              minWidth: minWidth,
            }}
          >
            <thead className="bg-primary-50">
              <tr className="border-b-[1px] border-b-card-on-surface">
                <th
                  className="!p-[0] !px-[0.75rem] !h-[56px] text-Gray-500 text-[0.8125rem] font-[600] leading-[1rem] bg-primary-50 rounded-tl-[8px] xl:w-[120px] 2xl:w-[200px] sticky left-0 z-10"
                  ref={clientAccountNameRef}
                >
                  <div className="flex items-center gap-[2px] w-full">
                    Client Account Name
                  </div>
                </th>
                <th
                  className="!p-[0] !h-[56px] text-Gray-500 text-[0.8125rem] font-[600] leading-[1rem] bg-primary-50 xl:w-[120px] 2xl:w-[200px] sticky xl:left-[120px] 2xl:left-[200px] z-5"
                  style={{
                    left: clientAccountNameRef?.current?.offsetWidth,
                  }}
                >
                  <div className="flex justify-start float-right w-full !px-[0.75rem]">
                    <span>Standard Account Name</span>
                  </div>
                </th>
                {fiscalPeriods.map((fiscalPeriod) => {
                  const {
                    month,
                    year,
                    period = 12,
                    monthInt,
                    audited,
                    auditorName,
                  } = extractPeriodAndDate(fiscalPeriod);

                  let displayedPeriod = year;

                  if (month) {
                    displayedPeriod = `${month} ${displayedPeriod}`;
                  }

                  if (canChangeDate) {
                    return (
                      <th className="!h-[56px] !p-[0] w-[6.25rem] max-w-[6.25rem]">
                        <Popover
                          isOpen={showPopovers === fiscalPeriod}
                          positions={["bottom", "top", "left", "right"]}
                          content={
                            <div
                              className="p-[1rem] rounded-[16px] bg-white max-w-[220px]"
                              ref={popoverRef}
                            >
                              <DatePicker
                                startingYear={year}
                                startingMonth={monthInt}
                                onDateChange={onPopoverButtonClick.bind(null, onDateChange.bind(
                                  null,
                                  fiscalPeriod
                                ))}
                              />
                              {showMonth && (
                                <Dropdowns
                                  label=" Select number of months"
                                  defaultValue={period || 12}
                                  inputClassName="!text-[0.75rem] !leading-[1.25rem] !py-[0.5rem] !px-[1rem]"
                                  data={PERIOD_MONTHS_OPTIONS}
                                  onChange={onPopoverButtonClick.bind(null, onPeriodChange.bind(
                                    null,
                                    fiscalPeriod
                                  ))}
                                  popoverClassname="bottom-[100%]"
                                  popoverDataClassname="max-h-[180px]"
                                  className="mt-[1.25rem]"
                                  readOnly
                                />
                              )}
                              <Dropdowns
                                label="Type of accounts"
                                defaultValue={audited}
                                inputClassName="!text-[0.75rem] !leading-[1.25rem] !py-[0.5rem] !px-[1rem]"
                                data={AUDITED_OPTIONS}
                                onChange={onPopoverButtonClick.bind(null, onAuditedChange.bind(
                                  null,
                                  fiscalPeriod
                                ))}
                                className="mt-[1.25rem]"
                                readOnly
                              />

                              { audited !== UNAUDITED_VALUE && <TextInput
                                label="Auditor"
                                defaultValue={auditorName}
                                inputClassName="!text-[0.75rem] !leading-[1.25rem] !py-[0.5rem] !px-[1rem]"
                                className="mt-[1.25rem]"
                                placeholder="Enter audit firm"
                                value={periodStringMetadata?.[fiscalPeriod]?.auditor_name || ""}
                                onChange={e => onChangeAuditorName(e, fiscalPeriod)}
                              /> }
                            </div>
                          }
                          onClickOutside={() => setShowPopovers("")}
                        >
                          <button
                            className="bg-primary-50 w-full"
                            onClick={handleOpenPopover.bind(null, fiscalPeriod)}
                          >
                            <div className="!px-[0.5rem] text-end flex justify-end">
                              <div className="flex items-start gap-[2px]">
                                <div className="flex flex-col items-center justify-end">
                                  <span className="font-[600] text-[0.8125rem] leading-[1.25rem] text-primary-2 self-end">
                                    {displayedPeriod}
                                  </span>
                                  {showMonth && (
                                    <span className="font-[500] text-[0.75rem] leading-[1rem] text-primary-2 text-end w-full">
                                      {period} months
                                    </span>
                                  )}
                                  <span className="font-[500] text-[0.7rem] leading-[1rem] text-primary-2 text-end w-full">
                                    {audited}
                                  </span>
                                  <span className="font-[500] text-[0.7rem] leading-[1rem] text-primary-2 text-end w-full break-all">
                                    {periodStringMetadata?.[fiscalPeriod]?.auditor_name}
                                  </span>
                                </div>
                                <div className="w-[16px] h-[16px] flex items-center justify-center">
                                  <img
                                    src={tableDateDropDown}
                                    alt=""
                                    className="w-[10px] h-[5px]"
                                  />
                                </div>
                              </div>
                            </div>
                          </button>
                        </Popover>
                      </th>
                    );
                  }

                  return (
                    <th className="!p-[0] !h-[56px] bg-primary-50 text-Gray-500 w-[6.25rem]">
                      <div className="!pr-[26px] text-end flex justify-end">
                        <div className="flex flex-col items-center justify-end">
                          <span className="font-[600] text-[0.8125rem] leading-[1.25rem] self-end">
                            {displayedPeriod}
                          </span>
                          {period && (
                            <span className="font-[500] text-[0.75rem] leading-[1rem] text-end w-full">
                              {period} months
                            </span>
                          )}
                          <span className="font-[500] text-[0.7rem] leading-[1rem] text-end w-full">
                            {audited}
                          </span> 
                          <span className="font-[500] text-[0.7rem] leading-[1rem] text-end w-full break-all">
                            {periodStringMetadata?.[fiscalPeriod]?.auditor_name}
                          </span> 
                        </div>
                      </div>
                      <div className="min-w-[20px]"></div>
                    </th>
                  );
                })}
                {actions.length > 0 && (
                  <th
                    className="!p-[0] !h-[56px] max-w-[6.25rem] text-Gray-500 text-[0.75rem] font-[600] leading-[1rem] bg-primary-50 rounded-tr-[8px] !w-[6.25rem] min-w-[6.25rem] sticky right-0"
                    ref={dynamicColRef}
                  >
                    <div className="flex justify-center">Actions</div>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {tableBodyContent}
            </tbody>
          </table>
          {summaryData.length > 0 && (
            <table
              className="border-separate table-fixed border-card-on-surface border-spacing-0"
              style={{
                minWidth: minWidth,
              }}
            >
              <thead className="">
                <tr>
                  <th className="text-Gray-500 text-[0.8125rem] font-[600] leading-[1rem] bg-primary-50 xl:w-[240px] 2xl:w-[400px] sticky left-0 border-[1px] rounded-tl-[8px] border-r-0 border-b-0">
                    <div className="">
                      Please Confirm the Accuracy of the Calculated Figures
                    </div>
                  </th>
                  {fiscalPeriods.map(() => (
                    <th className="text-Gray-500 text-[0.75rem] font-[600] leading-[1rem] bg-primary-50 w-[6.25rem] border-[1px] border-l-0 border-r-0 border-b-0"></th>
                  ))}
                  <th className="max-w-[6.25rem] text-Gray-500 text-[0.75rem] font-[600] leading-[1rem] bg-primary-50 w-[6.25rem] sticky right-0 border-[1px] rounded-tr-[8px] border-l-0 border-b-0"></th>
                </tr>
              </thead>
              <tbody className="">
                {summaryData.map(
                  (
                    { tp_standard_account_name, amount, account_code },
                    index
                  ) => {
                    const isConfirmed = confirmState[account_code];

                    return (
                      <tr>
                        <td className="border-t-[1px] border-card-on-surface sticky left-0 !h-[35px] !py-0 !px-[0.75rem] !text-[0.8125rem] 2xl:w-[400px] xl:w-[240px] bg-white border-[1px] rounded-bl-[8px] border-r-0">
                          <div className="flex justify-start text-neutral-strong leading-[1.25rem] tracking-[-0.28px] font-[400] w-full bg-white z-2">
                            {tp_standard_account_name}
                          </div>
                        </td>
                        {fiscalPeriods.map((periodString) => {
                          return (
                            <td className="w-[6.25rem] border-t-[1px] border-card-on-surface !h-[35px] !text-[0.8125rem] !py-0 !px-0 border-[1px] border-r-0 border-l-0">
                              <div
                                className={`!pl-[0.75rem]
                                  !pr-[26px] !text-[0.8125rem] flex justify-end text-neutral-strong leading-[1.25rem] tracking-[-0.28px] font-[400]`}
                              >
                                {formatNumber(amount[periodString])}
                              </div>
                            </td>
                          );
                        })}
                        <td className=" border-t-[1px] border-card-on-surface sticky right-0 !h-[35px] !py-0 !px-0 w-[112px] hover:bg-transparent border-[1px] border-l-0 rounded-br-[8px]">
                          <div className="!px-[0.75rem] flex justify-end items-center bg-white">
                            <button
                              onClick={() => {
                                onConfirm(documentType, name, account_code);
                              }}
                              className={`${
                                isConfirmed
                                  ? "confirm text-white border-0"
                                  : "border-[1px]"
                              } !p-0 !w-[74px] h-[22px] default border-border-neutral-medium hover:border-neutral-light font-[600] hover:bg-transparent leading-[1rem] text-[0.75rem] tracking-[-0.24px]`}
                            >
                              {isConfirmed ? "Confirmed" : "Confirm"}
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
}
