import backIcon from "../../assets/icons/icon_back.svg";
import BarChart from "../../components/BarChart";

import { useNavigate } from "react-router-dom";
import CommonTable from "../../components/CommonTable/cop";
import { API_APPLICATIONS, API_GET_APPLICATION_SUMMARY, API_TEAM_MEMBERS, URL_DASHBOARD } from "../../constants/url";
import {
  RECENT_ACTIVITY_COLS,
  recentActivityCols,
} from "./recentActivityCols";
import { useEffect, useState } from "react";
import { isSortedByKey, sortByKey } from "../../utils/object/sortByKey";
import { CHART_DURATION, ROLES, SORT_ORDER } from "../../constants";
import { CHART_DURATION_OPTIONS, CHART_DURATION_PERIOD_OPTIONS } from "../../constants/options";
import SearchInput from "../../components/SearchInput";
import GroupedAccordion from "../../components/GroupedAccordion";
import useAuthenticatedFetch from "../../hooks/useAuthenticatedFetch";
import { useLoader } from "../../context/Loader";
import defaultAvatarImage from "../../assets/images/default_avatar.png";
import { useAuthContext } from "../../context/Auth";
import { API_APPLICATION_STATUS } from "../../constants/statuses";
import dayjs from "dayjs";

export default function TeamActivity() {
  const navigate = useNavigate();
  const [activities, setActivities] = useState([]);
  const [periodType, setPeriodType] = useState(CHART_DURATION.MONTH)
  const [groupedUsers, setGroupedUsers] = useState([])
  const [filteredGroupedUsers, setFilteredGroupedUsers] = useState([])
  const [searchKeyword, setSearchKeyword] = useState('')
  const [expandedGroup, setExpandedTeam] = useState()
  const [datasets, setDatasets] = useState([])
  const [labels, setLabels] = useState([])
  const authenticatedFetch = useAuthenticatedFetch()
  const { user } = useAuthContext()
  const { setLoading } = useLoader()

  const chartDurationPeriodOption = CHART_DURATION_PERIOD_OPTIONS[periodType]

  const [selectedPeriodOption, setSelectedPeriodOption] = useState(chartDurationPeriodOption[0].value)

  const setChartDurationType = (periodType) => {
    setPeriodType(periodType)
    const chartDurationPeriodOption = CHART_DURATION_PERIOD_OPTIONS[periodType]
    setSelectedPeriodOption(chartDurationPeriodOption[0].value)
  }


  const [isAscCols, setIsAscCols] = useState({
    [RECENT_ACTIVITY_COLS.GROUP_NAME]: true,
    [RECENT_ACTIVITY_COLS.COMPANY_NAME]: true,
    [RECENT_ACTIVITY_COLS.FISCAL_PERIOD]: true,
    [RECENT_ACTIVITY_COLS.UPDATED_AT]: true,
    [RECENT_ACTIVITY_COLS.CREATED_BY]: true,
  });

  const fetchTotalApplicationsByPeriod = async (periodType, selectedPeriodOption) => {
    const startDate = new Date();

    switch (periodType) {
      case CHART_DURATION.MONTH: {
        startDate.setMonth(
          startDate.getMonth() - selectedPeriodOption,
          0
        );
        break;
      }
      default: {
        // weeks
        startDate.setDate(
          startDate.getDate() - 7 * selectedPeriodOption
        );
      }
    }

    const params = {
      start_date: startDate.toISOString().split("T")[0],
    };

    if (user.role === ROLES.MANAGER) {
      params.manager_id = user.id
    }

    if (user.role === ROLES.UNDERWRITER) {
      params.created_bys = user.id
    }

    const res = await authenticatedFetch(API_GET_APPLICATION_SUMMARY, {
      params,
    });

    const { labels, totalApplicationData } = res.data.reduce(
      ({ labels, totalApplicationData }, { key, application_total }) => {
        const newLabel = [...labels, key];
        const newTotalApplicationData = [
          ...totalApplicationData,
          application_total,
        ];

        return {
          labels: newLabel,
          totalApplicationData: newTotalApplicationData,
        };
      },
      {
        labels: [],
        totalApplicationData: [],
      }
    );
    const datasets = [
      {
        label: "",
        data: totalApplicationData,
        borderColor: "rgba(0, 0, 0, 0.11)",
        backgroundColor: "rgba(0, 0, 0, 0.11)",
        fill: true,
        tension: 0.4,
        hoverBackgroundColor: "#023972",
        borderRadius: 4,
      },
    ];

    setLabels(labels);
    setDatasets(datasets);
  };

  const handleSort = (colName) => {
    const currentAscCols = isAscCols;
    setIsAscCols((prev) => ({
      ...prev,
      [colName]: !prev[colName],
    }));
    if (
      isSortedByKey(
        activities,
        colName,
        !currentAscCols[colName] ? SORT_ORDER.ASC : SORT_ORDER.DESC
      )
    ) {
      return;
    }
    setActivities(
      sortByKey(
        activities,
        colName,
        !currentAscCols[colName] ? SORT_ORDER.ASC : SORT_ORDER.DESC
      )
    );
  };

  const toggleTeam = (groupName) => {
    setExpandedTeam(expandedGroup === groupName ? null : groupName);
  };


  const fetchAllTeamMembers = async () => {
    if (user.role === ROLES.UNDERWRITER) {
      const groupedTeamMembers = [
        {
          id: 0,
          group_name: user.team_names[0].name,
          companies: [
            {
              name: `${user.first_name} ${user.last_name}`,
              image: defaultAvatarImage,
            }
          ]
        }
      ]

      setGroupedUsers(groupedTeamMembers)
      setFilteredGroupedUsers(groupedTeamMembers)

      return
    }

    const params = {}

    const res = await authenticatedFetch(API_TEAM_MEMBERS, {
      params,
    });

    const groupedTeamMembers = res.data.map(({ team_name, members, ...otherData }) => {
      return {
        ...otherData,
        group_name: team_name,
        companies: members.map(({ first_name, last_name }) => ({
          name: `${first_name} ${last_name}`,
          image: defaultAvatarImage,
        }))
      }
    })

    setGroupedUsers(groupedTeamMembers)
    setFilteredGroupedUsers(groupedTeamMembers)
  }

  const fetchPastApplications = async () => {
    const params = {
      limit: 15,
      statuses: API_APPLICATION_STATUS.CONFIRMED,
    }

    if (user.role === ROLES.MANAGER) {
      params.manager_id = user.id
    }

    if (user.role === ROLES.UNDERWRITER) {
      params.created_bys = user.id
    }
    const res = await authenticatedFetch(API_APPLICATIONS, {
      params,
    });

    setActivities(
      res.data.map(
        ({ id, group_name, company_name, updated_at, created_by, created_by_name, latest_period }) => ({
          id,
          group_name,
          company_name,
          updated_at: dayjs(updated_at).format('DD MMM YYYY'),
          created_by: created_by_name || created_by,
          fiscal_period: latest_period,
        })
      )
    );
  }


  useEffect(() => {
    const filtered = groupedUsers.map(({ group_name, companies }) => {
      if (group_name?.toLowerCase().includes(searchKeyword.toLowerCase())) {
        return {
          group_name, companies
        }
      }

      const filteredCompanies = companies.filter(({ name }) => name?.toLowerCase().includes(searchKeyword.toLowerCase()))

      return {
        group_name,
        companies: filteredCompanies,
      }
    })

    setFilteredGroupedUsers(filtered)
  }, [searchKeyword])


  useEffect(() => {
    setLoading(true);
    Promise.all([
      fetchAllTeamMembers(),
      fetchPastApplications(),
    ]).finally(() => {
      setLoading(false);
    })
  }, [])


  useEffect(() => {
    fetchTotalApplicationsByPeriod(periodType, selectedPeriodOption)
  }, [periodType, selectedPeriodOption])

  return (
    <div className=" 2xl:px-16 sm:px-[48px] relative min-h-[calc(100vh-70px-16px)] flex flex-col overflow-hidden">
      <div className="py-[2.5rem] flex gap-[1rem]">
        <img
          src={backIcon}
          alt="back-button"
          className="cursor-pointer"
          onClick={() => navigate(URL_DASHBOARD)}
        />
        <span className="text-gradient-aurora-blue text-[2rem] font-[700] leading-[2.5rem] tracking-[-0.96px]">
          Activity Overview
        </span>
      </div>
      <div className="flex 2xl:gap-[1.5rem] sm:gap-[1.25rem] w-full">
        <div className="flex flex-col 2xl:gap-[1.5rem] sm:gap-[1.25rem] w-full">
          <BarChart
            datasets={datasets}
            labels={labels}
            displayLegend={false}
            title="Completed Financial Spreads"
            duration={periodType}
            period={selectedPeriodOption}
            durationOptions={CHART_DURATION_OPTIONS}
            periodOptions={chartDurationPeriodOption}
            onDurationChange={setChartDurationType}
            onPeriodChange={(period) => {
              setSelectedPeriodOption(period);
            }}
          />

          <CommonTable
            minWidth={recentActivityCols.minWidth}
            columns={recentActivityCols.columns({ handleSort, isAscCols })}
            rowsData={activities}
            label="Recent Activity"
            titleClassname={"!text-[1.25rem]"}
          />
        </div>
        <div className="w-full 2xl:max-w-[581px] sm:max-w-[381px] max-w-default transition-all mx-auto overflow-hidden flex h-full 2xl:p-[1.5rem] sm:p-[20px] flex-col gap-[0.5rem] rounded-[20px] bg-[#fff]">
          <div className="flex justify-between items-center mt-[1rem] w-full min-w-[300px]">
            <h2 className="text-[#121212] font-bold text-[1.25rem] leading-[1.75rem] tracking-[-0.6px]">
              Team Member
            </h2>
            <SearchInput
              containerClassname={"!mx-0 2xl:w-[200px] sm:w-[160px]"}
              onChange={(event) => setSearchKeyword(event.target.value)}
            />
          </div>

          {filteredGroupedUsers.map(({ group_name, companies, id, ...otherData }, index) => (
            <GroupedAccordion
              key={index}
              groupName={group_name}
              members={companies}
              isExpanded={expandedGroup === id}
              toggleTeam={() => toggleTeam(id)}
            />
          ))}
        </div>
      </div>
      <div className="min-h-[16px]"></div>
    </div>
  );
}
