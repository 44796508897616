export const API_APPLICATION_STATUS = {
  CREATED: "CREATED",
  VALIDATING: "VALIDATING",
  VALIDATED: "VALIDATED",
  CONFIRMED: "CONFIRMED",
  INACTIVE: "INACTIVE",
  FAILED: "FAILED",
};

export const PENDING_APPLICATION_STATUS = {
  IN_PROGRESS: "IN_PROGRESS",
  DONE: "DONE",
  FAILED: "FAILED",
};

export const APPLICATION_STATUS_PENDING_APPLICATIONS_MAP = {
  [API_APPLICATION_STATUS.CREATED]: PENDING_APPLICATION_STATUS.IN_PROGRESS,
  [API_APPLICATION_STATUS.VALIDATING]: PENDING_APPLICATION_STATUS.DONE,
  [API_APPLICATION_STATUS.VALIDATED]: PENDING_APPLICATION_STATUS.DONE,
  [API_APPLICATION_STATUS.FAILED]: PENDING_APPLICATION_STATUS.FAILED,
};

export const PROCESS_STATUSES = {
  STARTED: "STARTED",
  PROCESSED: "PROCESSED",
  FAILED: "FAILED",
  INACTIVE: "INACTIVE",
}
