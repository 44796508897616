import { DOCUMENT_TYPE_PROPERTY_GENERATED } from "../../constants";
import { SORT_TIME_OPTIONS } from "../../constants/options";
import groupDataByDocumentType from "../../utils/formula/groupDataByDocumentType";
import calculateUnitModifier from "../../utils/number/calculateUnitModifier";
import Checkbox from "../Checkbox";
import Collapse from "../Collapse";
import CommonTable from "../CommonTable/cop";
import GroupTable from "../CommonTable/TableGroup";
import Dropdowns from "../Dropdowns";
import { FORM_FIELDS } from "./ViewFinancialResult";
import { ReactComponent as DecreaseDecimal } from "../../assets/icons/icon_one_decimal.svg";
import { ReactComponent as IncreaseDecimal } from "../../assets/icons/icon_two_decimal.svg";
import Tooltip from "../Tooltip";
import { useState } from "react";
import SortFilterCollapse from "./SortFilterCollapse";
import extractPeriodAndDate from "../../utils/string/extractPeriodAndDate";
import generatePeriodString from "../../utils/string/generatePeriodString";

const DECIMAL_SIGN = {
  INCREASE: "increase",
  DECREASE: "decrease",
};

const FinancialReportTableView = ({
  data = [],
  currentDocumentAttribute,
  client,
  formData,
  handleDropdownChange,
  hiddenPeriods,
  setHiddenPeriods,
  fiscal_period_order,
  setHiddenPeriodByIndex,
  resultTable,
  currentDocumentType,
  generatedTable,
  increaseDecimalPlace,
  decreaseDecimalPlace,
  decimalPlace,
  periodStringMetadata,
  canExpand,
  isAllExpanded,
  onExpandAll,
  additionalFiscalPeriodOrders,
}) => {
  const [isMouseOver, setIsMouseOver] = useState({
    [DECIMAL_SIGN.DECREASE]: false,
    [DECIMAL_SIGN.INCREASE]: false,
  });
  const toggleMouseOver = (key, isHover) => {
    setIsMouseOver((prev) => ({
      ...prev,
      [key]: isHover,
    }));
  };

  if (currentDocumentAttribute?.type !== DOCUMENT_TYPE_PROPERTY_GENERATED) {
    const modifier = calculateUnitModifier(client.unit, formData.unit);

    const { hidePeriod } = currentDocumentAttribute || {}

    return (
      <>
        <SortFilterCollapse
          formData={formData}
          handleDropdownChange={handleDropdownChange}
          setHiddenPeriods={setHiddenPeriods}
          fiscal_period_order={fiscal_period_order.map((periodString) => {
            if (hidePeriod) {
              const { monthInt, year, audited } = extractPeriodAndDate(periodString)
              return generatePeriodString(monthInt, year, '', audited)
            }

            return periodString
          })}
          setHiddenPeriodByIndex={setHiddenPeriodByIndex}
          hiddenPeriods={hiddenPeriods}
        />
        <CommonTable
          minWidth={resultTable.minWidth}
          columns={resultTable.columns}
          renderChild={resultTable.renderChild}
          rowsData={data}
          label="Financial Mapping"
          unit={formData.unit}
          exchange_rate={formData.exchange_rate}
          currency={formData.out_currency}
          modifier={modifier}
          decimalPlace={decimalPlace}
          periodStringMetadata={periodStringMetadata}
          decimalSigns={
            <div className="flex items-center gap-[6px]">
              <Tooltip
                tooltip={"Decrease decimal places"}
                tooltipClassname="flex p-[8px] justify-center items-center rounded-[2px] !bg-[rgba(255, 255, 255, 0.80)]"
                customStyle={{
                  width: "131px",
                }}
                boxShadow="0px 4px 24px 0px rgba(0, 0, 0, 0.12)"
              >
                <button
                  onClick={decreaseDecimalPlace}
                  className={`flex p-[5px] justify-center items-center gap-[10px] rounded-[6px] border-[1px] ${
                    isMouseOver.decrease ? "border-[#02478E]" : "border-black"
                  } border-opacity-[0.37] cursor-pointer`}
                  onMouseOver={() =>
                    toggleMouseOver(DECIMAL_SIGN.DECREASE, true)
                  }
                  onMouseLeave={() =>
                    toggleMouseOver(DECIMAL_SIGN.DECREASE, false)
                  }
                >
                  <DecreaseDecimal
                    color={isMouseOver.decrease ? "#02478E" : "#737373"}
                  />
                </button>
              </Tooltip>
              <Tooltip
                tooltip={"Increase decimal places"}
                tooltipClassname="flex p-[8px] justify-center items-center rounded-[2px] bg-[rgba(255, 255, 255, 0.80)]"
                customStyle={{
                  width: "131px",
                }}
                boxShadow="0px 4px 24px 0px rgba(0, 0, 0, 0.12)"
              >
                <button
                  onClick={increaseDecimalPlace}
                  className={`flex p-[5px] justify-center items-center gap-[10px] rounded-[6px] border-[1px] ${
                    isMouseOver.increase ? "border-[#02478E]" : "border-black"
                  } border-opacity-[0.37] cursor-pointer`}
                  onMouseOver={() =>
                    toggleMouseOver(DECIMAL_SIGN.INCREASE, true)
                  }
                  onMouseLeave={() =>
                    toggleMouseOver(DECIMAL_SIGN.INCREASE, false)
                  }
                >
                  <IncreaseDecimal
                    color={isMouseOver.increase ? "#02478E" : "#737373"}
                  />
                </button>
              </Tooltip>
            </div>
          }
          canExpand={canExpand}
          isAllExpanded={isAllExpanded}
          onExpandAll={onExpandAll}
        />
      </>
    );
  }

  const groupedData = groupDataByDocumentType(data, currentDocumentType);

  return (
    <>
      <SortFilterCollapse
        formData={formData}
        handleDropdownChange={handleDropdownChange}
        setHiddenPeriods={setHiddenPeriods}
        fiscal_period_order={fiscal_period_order}
        setHiddenPeriodByIndex={setHiddenPeriodByIndex}
        hiddenPeriods={hiddenPeriods}
        additionalFiscalPeriodOrders={additionalFiscalPeriodOrders}
      />
      <GroupTable
        label={currentDocumentAttribute.text}
        config={generatedTable}
        groups={groupedData}
      />
      <div className="min-h-2"></div>
    </>
  );
};

export default FinancialReportTableView;
