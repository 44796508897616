import { UNAUDITED_VALUE } from "../../constants"
import extractPeriodAndDate from "../string/extractPeriodAndDate"

export default function generateFiscalPeriods(incomeStatementFiscalPeriodOrder, balanceSheetFiscalPeriodOrder) {
  const incomeStatementFiscalPeriodOrderMap = incomeStatementFiscalPeriodOrder.reduce((finalMap, periodString) => {
    const { year, monthInt, audited } = extractPeriodAndDate(periodString)

    let isAudited = true

    if (!audited || audited === UNAUDITED_VALUE) {
      isAudited = false
    }

    const key = `${year}_${monthInt}_${isAudited}`

    return {
      ...finalMap,
      [key]: true
    }

  }, {})


  const filteredbalanceSheetFiscalPeriodOrder = balanceSheetFiscalPeriodOrder.filter((periodString) => {
    const { year, monthInt, audited } = extractPeriodAndDate(periodString)
    
    let isAudited = true

    if (!audited || audited === UNAUDITED_VALUE) {
      isAudited = false
    }
    const key = `${year}_${monthInt}_${isAudited}`

    return !incomeStatementFiscalPeriodOrderMap[key]
  })

  return {
    fiscalPeriods: incomeStatementFiscalPeriodOrder,
    balanceSheetFiscalPeriods: filteredbalanceSheetFiscalPeriodOrder,
  }
}