import { AUDITED_VALUE, DOCUMENT_TYPE_ENUM, UNAUDITED_VALUE } from "../../constants";
import {
  ACCOUNT_CODE_CONSTANT,
} from "../../constants/formula";
import extractPeriodAndDate from "../string/extractPeriodAndDate";
import generatePeriodString from "../string/generatePeriodString";
import aggregateData from "./aggregateData";
import calculateTotalByOperation from "./calculate_total_by_operation";
import defaultCalculateFormula from "./default_calculate_formula";
 
export default function calculateFormulaFromData(
  data,
  formula,
  baseCalculateMetadata = {},
  baseData = {},
  baseMetadata = {},
) {
  const aggregatedData = aggregateData(data)

  const { modifier = 1, fiscalPeriods = [] } = baseMetadata
 
  return formula.map(
    ({ tp_standard_account_name, displayCode, operations, document_type, applyModifier = false, ...otherMetaData}) => {
      const totals = {};

      operations.forEach((currentOperationData) => {
        const {
          accountCode,
          operation,
          year: usedYear = 0,
          calculate = defaultCalculateFormula,
        } = currentOperationData;

        if (accountCode === ACCOUNT_CODE_CONSTANT) {
          const usedFiscalPeriods = [...new Set([...fiscalPeriods, ...Object.keys(totals)])] 

          usedFiscalPeriods.forEach((periodString) => {
            const { period, monthInt, year, audited, month } = extractPeriodAndDate(periodString)
            const calculateMetadata = {
              ...baseCalculateMetadata,
            };
            const calculatedYear = Number(year) + usedYear;
            const calculatedPeriodString = generatePeriodString(monthInt, calculatedYear, period, audited, month)

            const previousYear = Number(year) - 1;
            let previousPeriodString = generatePeriodString(
              monthInt,
              previousYear,
              period,
              audited,
              month
            );

            if (audited === UNAUDITED_VALUE || !totals[previousPeriodString]) {
              previousPeriodString = Object.keys(totals).filter(
                (currentPeriodString) => {
                  const {
                    period: currentPeriod,
                    monthInt: currentMonthInt,
                    year: currentYear,
                  } = extractPeriodAndDate(currentPeriodString);

                  if (
                    currentPeriod === period &&
                    currentMonthInt === monthInt &&
                    Number(currentYear) === Number(year) - 1
                  ) {
                    return true;
                  }

                  return false;
                }
              ).sort()[0];
            }

            calculateMetadata.isOldestYear =
              typeof totals[previousPeriodString] === "undefined";


            const value = calculate(calculateMetadata, totals, calculatedPeriodString);
            const currentTotal = totals[periodString];

            const newTotal = calculateTotalByOperation(
              currentTotal,
              value,
              operation
            );

            totals[periodString] = newTotal;
          })

          return 
        }

        const accountCodeList = accountCode.split(",");

        const accountCodeItemMap = {};
 
        const aggregatedFinancialData = Object.values(aggregatedData)

        aggregatedFinancialData.forEach((item) => {
          accountCodeList.forEach((accountCode) => {
            const [startAccountCode, endAccountCode = startAccountCode] =
              accountCode.split("-");
            if (
              item.account_code >= startAccountCode &&
              item.account_code <= endAccountCode
            ) {
              accountCodeItemMap[item.account_code] = item;
            }
          });
        });

        const periodStringAccountCodeAmountMap = {};
        const periodStringAccountCodeItemMap = {}


        for (const accountCode in accountCodeItemMap) {
          const item = accountCodeItemMap[accountCode];
          const { amount: periodStringAmounts, document_type } = item

          const usedFiscalPeriods = [...new Set([...fiscalPeriods, ...Object.keys(periodStringAmounts)])] 

          usedFiscalPeriods.forEach((periodString) => {
            let amountPeriodString = periodString
            if (!periodStringAccountCodeAmountMap[periodString]) {
              periodStringAccountCodeAmountMap[periodString] = {};
            }
            
            if (!periodStringAccountCodeItemMap[periodString]) {
              periodStringAccountCodeItemMap[periodString] = {};
            }

            // if data doesn't exist and is balance sheet, we try to adjust the period string to ignore the period
            if (typeof periodStringAmounts[periodString] === 'undefined' && document_type === DOCUMENT_TYPE_ENUM.BALANCE_SHEET) { 
              const { monthInt: currentMonthInt, year } = extractPeriodAndDate(periodString)

              const calculatedYear = Number(year) + usedYear;
              const sameDateAmounts = Object.keys(periodStringAmounts).filter((comparedPeriodString) => {
                const { monthInt, year } = extractPeriodAndDate(comparedPeriodString)
                return Number(year) === calculatedYear && monthInt === currentMonthInt
              })

              if (sameDateAmounts.length > 0) {
                amountPeriodString = sameDateAmounts[0]
              }
            } 

            const currentAmount = periodStringAmounts[amountPeriodString]

            if (typeof currentAmount !== 'undefined') {
              periodStringAccountCodeAmountMap[periodString][accountCode] = periodStringAmounts[amountPeriodString];
            }

            periodStringAccountCodeItemMap[periodString][accountCode] = item;
          })
        }

        const accountCodeSum = {};

        for (const periodString in periodStringAccountCodeAmountMap) {
            const { period, monthInt, year, audited, month } = extractPeriodAndDate(periodString)
            const calculateMetadata = {
            ...baseCalculateMetadata,
          };

          const previousYear = Number(year) - 1
          let previousPeriodString = generatePeriodString(monthInt, previousYear, period, audited, month)

          if (audited === UNAUDITED_VALUE || !periodStringAccountCodeAmountMap[previousPeriodString]) {
            previousPeriodString = Object.keys(periodStringAccountCodeAmountMap).filter((currentPeriodString) => {
            const { period: currentPeriod, monthInt: currentMonthInt, year: currentYear } = extractPeriodAndDate(currentPeriodString)

            if (currentPeriod === period && currentMonthInt === monthInt && Number(currentYear) === (Number(year) - 1)) {
              return true
            }

            return false

            }).sort()[0]
          }

          calculateMetadata.isOldestYear = typeof periodStringAccountCodeAmountMap[previousPeriodString] === 'undefined';

          const calculatedYear = Number(year) + usedYear;
          let calculatedPeriodString = generatePeriodString(monthInt, calculatedYear, period, audited, month)
          let amount = periodStringAccountCodeAmountMap[calculatedPeriodString];

          if (!amount && audited === UNAUDITED_VALUE) {
            calculatedPeriodString = generatePeriodString(monthInt, calculatedYear, period, month)
            amount = periodStringAccountCodeAmountMap[calculatedPeriodString];
          }
                    

          const result = calculate(
            amount,
            calculateMetadata,
            periodStringAccountCodeAmountMap,
            calculatedPeriodString
          );

          accountCodeSum[periodString] = result;
        }

        const combinedPeriodStrings = new Set([...Object.keys(totals), ...Object.keys(accountCodeSum)])

        combinedPeriodStrings.forEach((periodString) => {
          const value = accountCodeSum[periodString];
          const currentTotal = totals[periodString];

          const newTotal = calculateTotalByOperation(
            currentTotal,
            value,
            operation
          );

          totals[periodString] = newTotal;
        })
      });
 
        const newData = {
        ...otherMetaData,
        tp_standard_account_name: tp_standard_account_name,
        account_code: displayCode,
        amount: totals,
        document_type,
        ...(applyModifier ? { modifier } : {}),
        ...baseData,
      };
    
      aggregatedData[newData.account_code] = newData;
      return newData;
    }
  );
}
