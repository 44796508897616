import { isNumber } from "chart.js/helpers";

export const roundDecimal = (decimalPlaces, amount) => {
  if (!isNumber(amount)){
    return amount
  }

  return amount.toFixed(decimalPlaces);
};

export const negativeBrackets = (amount) => {
  if (!isNumber(amount)) {
    return amount
  }

  return amount >= 0 ? amount : `(${amount * -1})`;
}

export const formatNumber = (amount) => {
  const formatter = new Intl.NumberFormat();
  return formatter.format(amount);
};

export const addPercentage = (amount) => isNumber(amount) ?  amount + "%" : amount;

export const formatMultiple = (formatFunctions = [], amount) =>
  formatFunctions.reduce((finalAmount, currentFormatFunction) => {
    return currentFormatFunction(finalAmount);
  }, amount);

export const convertAmountToPercentage = (amount) => {
  return amount * 100
}

export const applyModifier = (periodAmount, modifier) => {
  if (modifier) {
    return periodAmount * modifier;
  }
  return periodAmount;
};
