import { useEffect, useState } from "react";

export default function DefaultPdfPreviewErrorView({ onPageLoadError, error, onPageLoad }) {
  const [countdown, setCountdown] = useState(3);

  useEffect(() => {
    onPageLoad()
  }, [])

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    } else {
        onPageLoadError?.(error);
    }
  }, [countdown]);

  return (
    <div className="flex flex-col items-center h-screen">
      <div className="bg-red-600 p-6 rounded-2xl shadow-lg text-center">
        <p className="text-white text-lg font-semibold">An error occurred!</p>
        {onPageLoadError && (
          <>
            <p className="text-white mt-2">
              Will automatically retry in {countdown}s...
            </p>
            <button
              className="mt-4 bg-white text-red-600 px-4 py-2 rounded-lg font-semibold"
              onClick={() => onPageLoadError?.(error)}
            >
              Retry Now
            </button>
          </>
        )}
      </div>
    </div>
  );
}
