import PropTypes from "prop-types";
import { Fragment } from "react";
import { useDropzone } from "react-dropzone";
import closeIcon from "../../assets/icons/icon_close.svg";
import uploadIcon from "../../assets/icons/icon_upload.svg";
import { FILE_TYPE_ICON_MAP, FILE_TYPE_MAP, FILE_TYPE_TEXT_MAP } from "../../constants/file";

Upload.propTypes = {
  onFileSelect: PropTypes.func.isRequired,
  file: PropTypes.arrayOf(PropTypes.instanceOf(File)),
  accept: PropTypes.arrayOf(PropTypes.string),
  showPreview: PropTypes.bool,
  containerClassname: PropTypes.string,
  uploadIconClassname: PropTypes.string,
};

export default function Upload({
  onFileSelect,
  onFileDelete,
  files = [],
  accept = [],
  multiple,
  maxSize,
  acceptLabel = "",
  showPreview = true,
  containerClassname = "",
  uploadIconClassname = "",
  onDropRejected, 
  onDropAccepted,
}) {
  const { dropzoneAccept, acceptedFilesTexts = [] } = accept.reduce(
    ({ dropzoneAccept = {}, acceptedFilesTexts = [] }, currentAcceptedFile) => {
      const fileTypeGroup = FILE_TYPE_MAP[currentAcceptedFile];

      const newAcceptedFilesTexts = [...acceptedFilesTexts];
      const currentFileTypeText = FILE_TYPE_TEXT_MAP[fileTypeGroup];
      if (currentFileTypeText) {
        newAcceptedFilesTexts.push(currentFileTypeText);
      }

      return {
        dropzoneAccept: {
          ...dropzoneAccept,
          [currentAcceptedFile]: [],
        },
        acceptedFilesTexts: [...new Set(newAcceptedFilesTexts)],
      };
    },
    {}
  );

  const fileTypeIcon = files?.length && FILE_TYPE_ICON_MAP[files[0]?.type];

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      onFileSelect(acceptedFiles);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: dropzoneAccept,
    multiple,
    maxSize,
    onDropRejected,
    onDropAccepted,
  });

  return (
    <Fragment>
      <div
        {...getRootProps()}
        className={`dropzone p-4 border-2 border-dashed rounded-lg ${
          isDragActive ? "border-blue-500" : "border-gray-300"
        } ${containerClassname}`}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <div className="text-center text-blue-500 min-h-[128px] flex justify-center items-center">
            Drop the file here...
          </div>
        ) : (
          <Fragment>
            <img
              src={uploadIcon}
              alt="Upload"
              className={`mx-auto pb-4 ${uploadIconClassname}`}
            />
            <p className="text-center text-neutral-strong font-[600] leading-[1.25rem] tracking-[-0.28px] text-[0.875rem]">
              Drag & Drop or{" "}
              <span className="text-primary-6 cursor-pointer">Choose File</span>{" "}
              to upload
            </p>
            <p className="text-center leading-[1rem] font-[450] text-[0.75rem] text-neutral-light mt-[0.5rem]">
              {!!(acceptLabel || (acceptedFilesTexts && acceptedFilesTexts.length)) && (
                <span>{acceptLabel ?? acceptedFilesTexts.join(",")}</span>
              )}
              {!acceptLabel && maxSize && <span>Max size of {maxSize / (1024 * 1024)}MB</span>}
            </p>
          </Fragment>
        )}
      </div>
      {showPreview && files.length > 0 && (
        <div className="mt-6">
          {files.map((file, index) => (
            <div className="mt-2 py-3 px-4 bg-black bg-opacity-5 rounded-lg flex flex-row justify-between">
              <div className="flex flex-row items-center">
                <div className="pr-4">
                  <img src={fileTypeIcon} alt={file.type} />
                </div>
                <div>
                  <p>{file.name}</p>
                  <p>{(file.size / 1024).toFixed(2)} KB</p>
                </div>
              </div>
              <div className="flex items-start">
                <button
                  type="button"
                  onClick={() =>
                    onFileDelete(index)
                  }
                >
                  <img src={closeIcon} alt="Close" />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </Fragment>
  );
}
